var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"webSwiperRef"},[_c('div',{staticClass:"row q-col-gutter-md q-slider-title"},[_c('div',{staticClass:"col"},[_c('h2',{staticClass:"text-h5 q-mt-auto"},[_vm._v("\n        "+_vm._s(_vm.title)+"\n        "),_c('router-link',{staticClass:"link-primaryOnBody text-underline",attrs:{"to":{
            name: 'Collection',
            params: { seoName: _vm.seoName },
          }}},[_vm._v("\n          See all\n        ")])],1)]),_c('div',{staticClass:"col-4 relative-position collection-slider-btn"},[_c('q-btn',{staticClass:"slider-prev",attrs:{"disabled":!_vm.hasPrevious,"round":"","label":"prev"},on:{"click":function($event){return _vm.$refs[_vm.name].previous()}}}),_c('q-btn',{staticClass:"slider-next",attrs:{"disabled":!_vm.hasNext,"round":"","label":"next"},on:{"click":function($event){return _vm.$refs[_vm.name].next()}}})],1)]),(_vm.count && _vm.noOfPages > 0 && _vm.productsInRow > 0)?_c('q-carousel',{ref:_vm.name,attrs:{"transition-prev":"slide-right","transition-next":"slide-left","swipeable":_vm.isMobile,"animated":"","control-color":"primary","infinite":_vm.isMobile},model:{value:(_vm.slide),callback:function ($$v) {_vm.slide=$$v},expression:"slide"}},_vm._l((_vm.noOfPages),function(page){return _c('q-carousel-slide',{key:page,staticClass:"column no-wrap",attrs:{"name":page}},[_c('div',{staticClass:"row justify-start items-start no-wrap"},_vm._l((_vm.productsInRow),function(element){return _c('div',{key:element,class:[
            'col',
            _vm.products[_vm.productIndex(page, element)] ? 'col-has-prod' : '',
          ]},[(_vm.products[_vm.productIndex(page, element)])?[_c('ProductCard',{attrs:{"product":_vm.products[_vm.productIndex(page, element)]}})]:_vm._e()],2)}),0)])}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }