<template>
  <div v-if="!(slide.imageLinkType && slide.imageSeoName)">
    <slot />
  </div>
  <router-link
    v-else-if="isCordova || !slide.imageOpenInNewTab"
    :to="`/${slide.imageLinkType}/${slide.imageSeoName}`"
  >
    <slot />
  </router-link>
  <a
    v-else
    :target="slide.imageOpenInNewTab ? '_blank' : '_self'"
    :href="`/${slide.imageLinkType}/${slide.imageSeoName}`"
  >
    <slot />
  </a>
</template>

<script>
export default {
  name: 'SlideLink',
  props: {
    slide: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
}
</script>
