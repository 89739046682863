import { render, staticRenderFns } from "./WebSwiper.vue?vue&type=template&id=4ed30a30"
import script from "./WebSwiper.vue?vue&type=script&lang=js"
export * from "./WebSwiper.vue?vue&type=script&lang=js"
import style0 from "./WebSwiper.vue?vue&type=style&index=0&id=4ed30a30&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QSlider from 'quasar/src/components/slider/QSlider.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCarousel from 'quasar/src/components/carousel/QCarousel.js';
import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js';
import QCarouselControl from 'quasar/src/components/carousel/QCarouselControl.js';
import QResizeObserver from 'quasar/src/components/resize-observer/QResizeObserver.js';
import QColor from 'quasar/src/components/color/QColor.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QSlider,QBtn,QCarousel,QCarouselSlide,QCarouselControl,QResizeObserver,QColor});
