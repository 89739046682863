export function getProductById(state) {
  return (productId) => state.details[productId]
}

export function getProductBySeoName(state) {
  return (seoName) => {
    let productId = state.seoMapping[seoName]
    return state.details[productId] || {}
  }
}

export function getCompanionProductsById(state) {
  return (ProductSeoName) => {
    let companionProduct = state.companionProducts[ProductSeoName]
    let inStockProduct = {}
    for (let productId in companionProduct) {
      if (companionProduct && companionProduct[productId].isinstock == true) {
        inStockProduct[productId] = companionProduct[productId]
      }
    }
    // return state.companionProducts[ProductSeoName]
    return inStockProduct
  }
}

export const loading = (state) => state.loading

export const sortByOptions = (state) => state.sortByOptions

export const pagination = (state) => state.pagination

export const getCurrentSelectedProductVariant = (state) =>
  state.currentSelectedProductVariant
