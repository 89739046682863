<template>
  <q-inner-loading :showing="showing">
    <q-spinner
      color="primary"
      :size="size ? size : isMobile ? '2em' : '3em'"
      :thickness="isMobile ? 2 : 3"
    />
  </q-inner-loading>
</template>
<script>
export default {
  name: 'InnerLoadingSpinner',
  props: {
    showing: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '',
    },
  },
}
</script>
<style lang="scss">
.q-inner-loading {
  z-index: 999;
}
</style>
