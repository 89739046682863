export function SET_TOTAL_REDEEMABLE_DOLLARS(state, totalRedeemedDollars) {
  state.totalRedeemedDollars = totalRedeemedDollars
}

export function SET_CUSTOMER(state, customer) {
  state.customer = customer
}

export function SET_COUPONS(state, coupons) {
  state.coupons = coupons
}
