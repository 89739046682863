export function isPermissionKnown(state) {
  return state.permission !== null
}

export function isPermissionGranted(state) {
  return state.permission === 'granted'
}

export function isPermissionPrompt(state) {
  return state.permission === 'prompt'
}

export function isPermissionDenied(state) {
  return state.permission === 'denied'
}

export function permission(state) {
  return state.permission
}
export function hasPosition(state) {
  return state.position && state.position.timestamp != null
}

export function timestamp(state) {
  return state.position && state.position.timestamp
}

export function coords(state) {
  return state.position && state.position.coords
}

export function latitude(state, getters) {
  return getters.coords && getters.coords.latitude
}

export function longitude(state, getters) {
  return getters.coords && getters.coords.longitude
}

export function altitude(state, getters) {
  return getters.coords && getters.coords.altitude
}

export function accuracy(state, getters) {
  return getters.coords && getters.coords.accuracy
}
export function altitudeAccuracy(state, getters) {
  return getters.coords && getters.coords.altitudeAccuracy
}

export function heading(state, getters) {
  return getters.coords && getters.coords.heading
}

export function speed(state, getters) {
  return getters.coords && getters.coords.speed
}
