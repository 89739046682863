import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import { Cookies, LocalStorage } from 'quasar'

import { isSsr } from '../constants'

const { LOG_ROCKET_APP_ID } = process.env

import homepage from './homepage'
import auth from './auth'
import customer from './customer'
import showcase from './showcase'
import common from './common'
import theme from './theme'
import persisted from './persisted'
import product from './product'
import reserveSlot from './reserve-slot'
import address from './address'
import category from './category'
import cart from './cart'
import location from './location'
import favorites from './favorites'
import geolocation from './geolocation'
import locationDetail from './location-detail'
import collection from './collection'
import payment from './payment'
import order from './order'
import orderReturn from './order-return'
import customerReview from './customer-review'
import cmsPages from './cms-pages'
import discounts from './discounts'
import notification from './notification'
import wishlist from './wishlist'
import ageVerification from './age-verification'
import lnsProgram from './lns-program'
import guest from './guest'
// import persistedSession from './persisted-session'
import persistedLocal from './persisted-local'
import createPlugin from 'logrocket-vuex'
import LogRocket from 'logrocket'

Vue.use(Vuex)

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */
let logRocketPlugin = null

if (LOG_ROCKET_APP_ID) logRocketPlugin = createPlugin(LogRocket)

export default function ({ ssrContext }) {
  const cookies = process.env.SERVER ? Cookies.parseSSR(ssrContext) : Cookies,
    options = { expires: 3 }

  let plugins = [
    new VuexPersistence({
      key: 'eFTXPersistedCookies',
      restoreState: (
        key
        // storage
      ) => {
        if (isSsr) return cookies.get(key) || {}
        return LocalStorage.getItem(key)
      },
      saveState: (
        key,
        state
        // storage
      ) => {
        if (isSsr) return cookies.set(key, state, options)
        return LocalStorage.set(key, state)
      },
      modules: ['persisted'],
      filter: (mutation) => !!mutation?.type?.includes('persisted/'),
    }).plugin,
  ]

  logRocketPlugin && plugins.push(logRocketPlugin)

  const Store = new Vuex.Store({
    modules: {
      homepage,
      auth,
      showcase,
      common,
      theme,
      product,
      category,
      reserveSlot,
      address,
      cart,
      location,
      favorites,
      geolocation,
      locationDetail,
      collection,
      payment,
      order,
      orderReturn,
      customerReview,
      cmsPages,
      customer,
      discounts,
      notification,
      wishlist,
      ageVerification,
      lnsProgram,
      guest,
      persisted,
      persistedLocal,
    },
    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: process.env.DEV,
    plugins,
  })

  return Store
}
