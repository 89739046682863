export const getDefaultState = () => {
  return {
    appLoaded: false,
    isFirstPage: false,
    businessConfigurations: null,
    // shippingConfigurations: null,
    businessFulfillments: {},
    allFulfillments: {},
    fulFillmentLocations: {},
    backHistories: [],
    trendingProducts: {},
    frequentSearches: [],
    defaultHeaderParams: {
      categoryButton: true,
      logo: true,
      changeLocationButton: true,
      searchBar: true,
      accountButton: true,
      favoriteButton: true,
      cartButton: true,
      reserveTimeSlotButton: true,
      offerDealsButton: false,
      backButton: false,
      homeButtom: false,
      searchButton: false,
      title: '',
    },
    header: {},
    alertData: {
      variant: 'danger',
      showCountDown: 0,
      message: '',
    },
    NetworkConnectionStatus: 1,
    footerData: null,
    socialPlatforms: null,
    // storeActive: false,
    tenantFound: false,
    storeConfigurationLoaded: false,
    clientStoreConfigurationLoaded: false,
    storeIsActive: false,
    fulFillments: null,
    mobileAppData: null,
    askFullAddressForDelivery: false,
    isFulfillmentInactive: false,
    // homePageSwiperWidth: 1200,
    showPWAInstallPopup: false,
    showPWAInstallPopupSidebar: false,
    paymentServiceLogoLoaded: false,
    paymentServiceLogos: [],
    showDateTimeBehindError: false,
    showDateTimeAheadError: false,
    lnsLoaded: false,
    disclaimers: [],
    announcements: [],
  }
}

export default getDefaultState()
