<template>
  <router-link v-if="link" :to="link" class="box-img">
    <q-img
      :src="imageSrc | defaultProductImage"
      :ratio="1"
      :width="width"
      :height="height"
      contain
    >
      <template v-slot:loading>
        <q-spinner color="primary" size="1em" :thickness="2" />
      </template>
    </q-img>
  </router-link>
  <div class="box-img" v-else>
    <q-img
      :src="imageSrc | defaultProductImage"
      :ratio="1"
      :width="width"
      :height="height"
    >
      <template v-slot:loading>
        <q-spinner color="primary" size="1em" :thickness="2" />
      </template>
    </q-img>
  </div>
</template>

<script>
export default {
  name: 'BoxImage',
  props: {
    imageSrc: {
      type: String,
      default: '',
    },
    link: {
      type: [Object, String],
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
}
</script>
