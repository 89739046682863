import { mapGetters } from 'vuex'

export default async ({ Vue, store }) => {
  Vue.mixin({
    data: function () {
      return {
        newCommonMeta: {
          link: {
            canonical: {
              rel: 'canonical',
              template(canonical) {
                let baseURL = process.env.SERVER
                  ? process.env.fullSsrUrl
                  : window.location.origin
                return canonical ? `${baseURL}${canonical}` : baseURL
              },
            },
          },
          meta: {
            title: {
              name: 'title',
              template(title) {
                return title || ''
              },
            },
            description: {
              name: 'description',
              template(description) {
                return description || ''
              },
            },
            ogType: {
              property: 'og:type',
              content: 'website',
            },
            ogUrl: {
              property: 'og:url',
              template(ogUrl) {
                let baseURL = process.env.SERVER
                  ? process.env.fullSsrUrl
                  : window.location.origin
                return ogUrl ? `${baseURL}${ogUrl}` : baseURL
              },
            },
            ogTitle: {
              property: 'og:title',
              template(ogTitle) {
                let businessName =
                  store.getters['common/businessConfigurations']
                    ?.businessName || ''
                if (businessName)
                  return ogTitle ? `${businessName} - ${ogTitle}` : businessName

                return ''
              },
            },
            ogDescription: {
              property: 'og:description',
              template(ogDescription) {
                let businessName =
                  store.getters['common/businessConfigurations']
                    ?.businessName || ''
                if (businessName)
                  return ogDescription
                    ? `${businessName} - ${ogDescription}`
                    : businessName

                return ''
              },
            },
            ogImage: {
              property: 'og:image',
              template(ogImg) {
                let defaultImg = `${process.env.API_ROOT_URI}/v1/Configuration/Theme/Favicon?Tenant=${process.env.Tenant}`
                return ogImg ? ogImg : defaultImg
              },
            },
          },
        },
      }
    },
    computed: {
      ...mapGetters('common', ['businessConfigurations']),
      businessName() {
        return this.businessConfigurations?.businessName || ''
      },
      currentRoutePath() {
        return this.$route?.path || ''
      },
    },
  })
}
