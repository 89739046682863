export function SET_APP_LOADED(state, appLoaded) {
  state.appLoaded = appLoaded
}

export function SET_FIRST_LOADED(state, isFirstPage) {
  state.isFirstPage = isFirstPage
}

export function SET_BUSINESS_CONFIGURATIONS(state, businessConfigurations) {
  state.businessConfigurations = businessConfigurations
}

// export function SET_SHIPPING_CONFIGURATIONS(state, shippingConfigurations) {
//   state.shippingConfigurations = shippingConfigurations
// }

export function SET_FREQUENT_SEARCHES(state, arrSearches) {
  arrSearches.map((key) => state.frequentSearches.push(key))
}

export function SET_TRENDING_PRODUCTS(state, trendingProducts) {
  state.trendingProducts = Object.assign({}, trendingProducts)
}

export function SET_HEADER(state, properties) {
  state.header = Object.assign({}, properties)
}

export function RESET_HEADER(state) {
  state.header = Object.assign({})
}

export function SET_HEADER_TITLE(state, { title, params = {} }) {
  let properties = {
    accountButton: true,
    searchButton: true,
    cartButton: true,
    backButton: true,
    homeButtom: true,
    logo: false,
    categoryButton: false,
    ...params,
  }

  if (title) properties.title = title

  state.header = Object.assign({}, { ...state.header }, properties)
}

// ToDo: Un used Code
// export function COUNT_DOWN_CHANGE(state, dismissCountDown = 0) {
//   state.alertData = Object.assign({}, state.alertData, {
//     showCountDown: dismissCountDown,
//   })
// }

// export function SET_ALERT(state, alertData) {
//   state.alertData = Object.assign({}, state.alertData, alertData)
// }

export function ADD_IN_HISTORY(state, history) {
  state.backHistories.push(history)
}

export function CLEAR_HISTORY(state) {
  state.backHistories.splice(0)
}

export function POP_FROM_HISTORY(state) {
  state.backHistories.pop()
}

export function SET_NETWORK_STATUS(state, status) {
  state.NetworkConnectionStatus = status
}

export function SET_FOOTER_DATA(state, footerData) {
  state.footerData = footerData
}

export function SET_SOCIAL_PLATFORMS(state, socialPlatforms) {
  state.socialPlatforms = socialPlatforms || null
}

export const SET_STORE_CONFIGURATION_LOADED = (state, val) => {
  state.storeConfigurationLoaded = !!val
}

export const SET_TENANT_FOUND = (state, val) => {
  state.tenantFound = !!val
}

export const SET_STORE_IS_ACTIVE = (state, val) => {
  state.storeIsActive = !!val
}

export const SET_LOCATION_FULFILLMENTS = (state, { location, fulfillment }) => {
  if (fulfillment?.codeName && location) {
    state.fulFillmentLocations = {
      ...state.fulFillmentLocations,
      [fulfillment?.codeName]: {
        ...fulfillment,
        location,
      },
    }
  }
}

export const SET_FULFILLMENTS = (
  state,
  { location, persistedFulfillments }
) => {
  let fulFillments = {}

  if (location && location.fulFillments)
    location.fulFillments.forEach(function (fulFillment) {
      fulFillments[fulFillment.codeName] = fulFillment

      let previousCheckedValue = true

      if (persistedFulfillments && persistedFulfillments[fulFillment.codeName])
        previousCheckedValue =
          persistedFulfillments[fulFillment.codeName].checked

      fulFillments[fulFillment.codeName].checked =
        previousCheckedValue &&
        fulFillments[fulFillment.codeName].available &&
        !(
          fulFillments[fulFillment.codeName].configuration &&
          fulFillments[fulFillment.codeName].configuration.isComingSoon
        )
    })

  state.fulFillments = fulFillments
}

export function SET_MOBILE_APP_DATA(state, mobileAppData) {
  state.mobileAppData = mobileAppData
}

export function SET_BUSINESS_FULFILLMENTS(state, businessFulfillments) {
  state.businessFulfillments = businessFulfillments
}
export function SET_ALL_FULFILLMENT(state, allFulfillments) {
  state.allFulfillments = allFulfillments
}
export function SET_FULFILLMENT_INACTIVE(state, isFulfillmentInactive) {
  state.isFulfillmentInactive = isFulfillmentInactive
}

export function SET_ASK_ADDRESS_FOR_DELIVERY(state, val) {
  state.askFullAddressForDelivery = val
}

// export function SET_HOME_PAGE_SWIPER_WIDTH(state, homePageSwiperWidth) {
//   state.homePageSwiperWidth = homePageSwiperWidth
// }

export function UPDATE_STATE(state, { key, val }) {
  state[key] = val
}

export function SET_PAYMENT_SERVICES_LOGO_LOADED(state, loading) {
  state.paymentServiceLogoLoaded = loading
}

export function SET_PAYMENT_SERVICES_LOGO(state, logosData) {
  state.paymentServiceLogos = logosData
}

export function SET_STATE(state, { key, value }) {
  if (key) state[key] = value
}
