import { render, staticRenderFns } from "./LegalDialog.vue?vue&type=template&id=ac92f1c4&scoped=true"
import script from "./LegalDialog.vue?vue&type=script&lang=js"
export * from "./LegalDialog.vue?vue&type=script&lang=js"
import style0 from "./LegalDialog.vue?vue&type=style&index=0&id=ac92f1c4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac92f1c4",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QBtn,QScrollArea});
