import SplitSection from 'components/home-page-components/SplitSection'
import SectionColumn from 'components/home-page-components/SectionColumn'
import ColumnComponent from 'components/home-page-components/ColumnComponent'
import Collection from 'components/home-page-components/Collection'
import CollectionPreview from 'components/home-page-components/CollectionPreview'
import ImageSlider from 'components/home-page-components/ImageSlider'
import SingleImage from 'components/home-page-components/SingleImage'
import AgeRestrictedBlur from 'components/home-page-components/AgeRestrictedBlur'

export default async ({ Vue }) => {
  Vue.component('SplitSection', SplitSection)
  Vue.component('SectionColumn', SectionColumn)
  Vue.component('ColumnComponent', ColumnComponent)
  Vue.component('Collection', Collection)
  Vue.component('CollectionPreview', CollectionPreview)
  Vue.component('ImageSlider', ImageSlider)
  Vue.component('SingleImage', SingleImage)
  Vue.component('AgeRestrictedBlur', AgeRestrictedBlur)
}
