export const currentLocationDetail = (state) => state.currentLocationDetail

export const longitude = (state) => state.longitude

export const latitude = (state) => state.latitude

export const milesOptions = (state) => state.milesOptions

export const changeLocationCartDetail = (state) =>
  state.changeLocationCartDetail

export const toLocationNFulfillment = (state) => state.toLocationNFulfillment

export const changeLocationAndAddToCart = (state) =>
  state.changeLocationAndAddToCart
