import {
  required,
  email,
  // alpha
  // minLength
} from 'vuelidate/lib/validators'

export default ({ Vue }) => {
  Vue.prototype.validateRequired = (val, message = 'Field is Required') => {
    return required(val) || message
  }

  Vue.prototype.validateEmail = (
    val,
    message = 'Please Enter a valid email'
  ) => {
    return email(val) || message
  }

  Vue.prototype.validateMonth = (val, message = 'Please Enter Valid Month') => {
    return (val && !(val < 1 || val > 12)) || message
  }

  Vue.prototype.validatePhone = (
    message = 'Please enter valid phone Number.'
  ) => {
    return (val) =>
      val
        ? /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
            val
          ) || message
        : true
  }

  // Vue.prototype.validateCharOnly = (
  //   val,
  //   message = 'Please enter characters only'
  // ) => {
  //   return alpha(val) ? true : message
  // }

  Vue.prototype.validateZip = (
    val,
    message = 'Please enter a valid zip code'
  ) => {
    return /^\d{5}(-\d{4})?$/.test(val) || message
  }

  // Vue.prototype.minLength = (length, message) => {
  //   message = message !== undefined ? message : false
  //   return (val) => minLength(length)(val) || message
  // }

  // Vue.prototype.sameAs = (locator, message = false) => {
  //   message = message !== undefined ? message : false
  //   return (val) => val == locator || message
  // }

  // Vue.prototype.validateDOB = (val, message = 'Please Enter Valid DOB') => {
  //   var selectedDate = val
  //   if (selectedDate == '') return message

  //   // var regExp = /^(\d{1,2})(\/|-)(\d{1,2})(\/|-)(\d{4})$/;
  //   var regExp = /^(\d{1,2})(\/|-)(\d{1,2})$/
  //   var dateArray = selectedDate.match(regExp)
  //   if (dateArray == null) {
  //     return message
  //   }

  //   let month = parseInt(dateArray[1])
  //   let day = parseInt(dateArray[3])
  //   let year = new Date().getFullYear()

  //   if (month < 1 || month > 12) {
  //     return message
  //   } else if (day < 1 || day > 31) {
  //     return message
  //   } else if (
  //     (month == 4 || month == 6 || month == 9 || month == 11) &&
  //     day == 31
  //   ) {
  //     return message
  //   } else if (month == 2) {
  //     var isLeapYear = year % 4 == 0 && (year % 100 != 0 || year % 400 == 0)
  //     if (day > 29 || (day == 29 && !isLeapYear)) {
  //       return message
  //     }
  //   }
  //   return true
  // }

  Vue.prototype.validatePersonName = (
    val,
    message = 'Please enter a valid name'
  ) => {
    return (val) =>
      val
        ? /^(?=.{1,40}$)[a-zA-Z ]+(?:[-' ][a-zA-Z]+)*$/gm.test(val) || message
        : true
  }

  Vue.prototype.validateFullDOB = (val, message = 'Please Enter Valid DOB') => {
    var selectedDate = val
    if (selectedDate == '') return message

    var regExp = /^(\d{1,2})(\/|-)(\d{1,2})(\/|-)(\d{4})$/
    // var regExp = /^(\d{1,2})(\/|-)(\d{1,2})$/
    var dateArray = selectedDate.match(regExp)
    if (dateArray == null) {
      return message
    }

    let month = parseInt(dateArray[1])
    let day = parseInt(dateArray[3])
    let year = parseInt(dateArray[5]),
      minYear = 1900,
      currentYear = new Date().getFullYear()

    if (month < 1 || month > 12) {
      return message
    } else if (day < 1 || day > 31) {
      return message
    } else if (
      (month == 4 || month == 6 || month == 9 || month == 11) &&
      day == 31
    ) {
      return message
    } else if (month == 2) {
      var isLeapYear = year % 4 == 0 && (year % 100 != 0 || year % 400 == 0)
      if (day > 29 || (day == 29 && !isLeapYear)) {
        return message
      }
    } else if (year < minYear || year > currentYear) {
      return message
    }
    return true
  }
}
