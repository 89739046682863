import Vue from 'vue'

export function SET_PAYMENT_METHODS(state, paymentMethods) {
  state.availableMethods = Object.assign({}, paymentMethods)
}

export function SET_LOADING(state, loading) {
  state.loading = loading
}
export function REMOVE_PAYMENT_METHODS(state, paymentMethodId) {
  Vue.delete(state.availableMethods, paymentMethodId)
}

export function ADD_OR_UPDATE_PAYMENT_METHODS(state, paymentMethod) {
  if (paymentMethod.isDefault) {
    for (let key in state.availableMethods) {
      state.availableMethods[key].isDefault = false
    }
  }

  state.availableMethods = Object.assign({}, state.availableMethods, {
    [paymentMethod.paymentMethodId]: paymentMethod,
  })
}
