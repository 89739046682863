import { formatPhoneNumber } from 'src/utils'

export const isLoggedIn = (state) => state.user && state.user.access_token
// && !state.user.expired

export const ftxCoreUser = (state) => state.user || null

export const currentUser = (state) => {
  return state.currentUser
}

export const currentUserIdNumber = (state, getters) => {
  return getters.currentUser?.userIdNumber || ''
}

export const currentUserName = (state, getters) => {
  if (getters.currentUser)
    return `${getters.currentUser.firstName} ${getters.currentUser.lastName}`
  return ''
}

export const currentUserImage = (state, getters) => {
  if (getters.currentUser) return getters.currentUser.profileImageUrl
  return ''
}

export function currentUserIdentity(state, getters) {
  if (getters.currentUser)
    if (getters.currentUser.email) return getters.currentUser.email
    else if (getters.currentUser.phoneNumber) {
      let phone = getters.currentUser?.countryCode
        ? `${getters.currentUser.countryCode}${getters.currentUser.phoneNumber}`
        : getters.currentUser.phoneNumber
      return formatPhoneNumber(phone)
    } else if (getters.currentUser.userIdNumber)
      return getters.currentUser.userIdNumber

  return ''
}

export const accessToken = (state) => state.user && state.user.access_token

export const loading = (state) => state.loading

export const loadingUser = (state) => state.loadingUser

export const customerExists = (state, getters) =>
  getters.isLoggedIn && !!state.exists

export const passwordPolicy = (state) => state.passwordPolicy
