<template>
  <iframe
    v-if="lnsAppUrl"
    ref="lnsIFrame"
    class="fullscreen"
    :src="lnsAppUrl"
    :class="[iframeClass, 'lns-iframe']"
    allow="clipboard-read; clipboard-write; geolocation *; camera; microphone; web-share;"
  />
</template>

<script>
import { reloadPage } from 'src/utils'
import { IFrameActions } from './IFrameActions'
import { mapGetters } from 'vuex'
import { handleTokenExpire } from 'src/boot/axios'
import firebase from 'firebase/app'
import { getMessaging, onMessage } from 'firebase/messaging'
// import 'firebase/analytics'

const unRegisterAllSw = () => {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister()
    }
  })
}

export default {
  name: 'LNSIFrame',
  data() {
    return {
      popStateDetected: false,
      navigatedFromChild: false,
      embeddedLnsUrl: process?.env?.EMBEDDED_LNS_URL,
      // embeddedLnsUrl: 'http://localhost:8085/',
      cMounted: false,
      firebaseInitialize: false,
    }
  },
  watch: {
    ftxCoreUser: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.postMessageToLns({
          action: IFrameActions.MAP_USER,
          value: newVal,
        })
      }
    },
    $route: function (route) {
      if (
        route.name === 'LoyaltyProgram' &&
        this.lnsLoaded &&
        !this.isCordova &&
        !this.navigatedFromChild &&
        !this.popStateDetected
      ) {
        this.postMessageToLns({
          action: IFrameActions.NAVIGATION,
          value: route?.hash?.replace('#', ''),
        })
      }

      if (this.navigatedFromChild) this.navigatedFromChild = false
    },
  },
  computed: {
    ...mapGetters('auth', ['ftxCoreUser']),
    ...mapGetters('common', ['storeHasLnsSubscription', 'commonStateByKey']),
    ...mapGetters('customer', ['customer']),
    ...mapGetters('lnsProgram', ['showMobileLoyaltyProgram']),
    iframeClass() {
      if (this.isCordova && this.showMobileLoyaltyProgram) return 'visible'

      if (!this.isCordova && this.$route.path === '/loyalty-program')
        return 'visible'

      return 'invisible'
    },
    lnsAppUrl() {
      if (
        this.cMounted &&
        this.storeHasLnsSubscription &&
        this.isLoggedIn &&
        this.businessConfigurations?.accountID &&
        this.ftxCoreUser
      )
        return this.embeddedLnsUrl

      return ''
    },
  },
  methods: {
    postMessageToLns(message) {
      message &&
        this.$refs?.lnsIFrame?.contentWindow?.postMessage(
          message,
          this.lnsAppUrl
        )
    },
    async onMessage(event) {
      // Accept only specific origins messages.
      // if (event.origin !==  "https://..") {
      //   console.log('Incorrect origin',event.origin);
      //   return;
      // }

      const message = event?.data,
        action = message?.action,
        value = message?.value

      switch (action) {
        case IFrameActions.REFRESH:
          window?.location?.reload()

        case 'LNS_LOADED':
          this.postMessageToLns({
            action: 'INITIAL_LNS_DATA',
            value: {
              startUrl: window.location.hash.trim()
                ? window.location.hash.replace('#', '')
                : null,
              ftxCoreUser: this.ftxCoreUser,
              accountID: this.businessConfigurations?.accountID,
            },
          })
          // this.postMessageToLns({
          //   action: IFrameActions.MAP_USER,
          //   value: this.ftxCoreUser,
          // })
          // if (!this.isCordova && this.$route.hash) {
          //   this.postMessageToLns({
          //     action: IFrameActions.NAVIGATION,
          //     value: this.$route.hash.replace('#', ''),
          //   })
          // }

          this.lnsLoaded = true

          break

        case 'CLOSE_LNS_APP':
          if (this.isCordova) {
            this.$store.commit(
              'lnsProgram/SET_SHOW_MOBILE_LOYALTY_PROGRAM',
              false
            )
            setTimeout(() => {
              this.postMessageToLns({
                action: IFrameActions.NAVIGATION,
                value: '/',
              })
            }, 400)
          } else {
            this.$router.push({ name: 'Home' })
            this.popStateDetected = true
            this.postMessageToLns({
              action: IFrameActions.NAVIGATION,
              value: '/',
            })
          }
          break

        case 'ROUTE_REPLACE':
          if (
            !this.isCordova &&
            this.$route.name == 'LoyaltyProgram' &&
            !this.popStateDetected &&
            value
          ) {
            let updateHasValue = `#${value}`

            this.navigatedFromChild = true

            if (!this.$route.hash && updateHasValue !== this.$route.hash)
              this.$router.replace(`/loyalty-program${updateHasValue}`)
          }
          break
        case IFrameActions.NAVIGATION:
          if (
            !this.isCordova &&
            this.$route.name == 'LoyaltyProgram' &&
            !this.popStateDetected &&
            value
          ) {
            let updateHasValue = `#${value}`

            this.navigatedFromChild = true

            if (updateHasValue !== this.$route.hash)
              this.$router.push({ hash: updateHasValue })
          }
          this.popStateDetected = false
          break

        case 'AUTH_TOKEN_ABSENT':
          this.$router.push({ name: 'Home' })
          this.performLogout()
          break
        case 'AUTH_TOKEN_EXPIRED':
          handleTokenExpire &&
            handleTokenExpire()?.finally(() => {
              reloadPage()
            })
          break

        case 'REMOVE_FIRE_BASE_TOKEN_SUCCESS':
          this.performLogout(false)
          break

        case 'FIREBASE_CONFIG':
          if (
            !firebase.apps.length &&
            !this.firebaseInitialize &&
            !this.isCordova &&
            'serviceWorker' in navigator &&
            value
          ) {
            this.firebaseInitialize = true

            // unRegisterAllSw()

            await firebase.initializeApp(value)

            await navigator.serviceWorker
              .register('/firebase-messaging-sw.js')
              .then(() => {
                const messaging = firebase.messaging()
                messaging
                  .requestPermission()
                  .then(() => {
                    // You can now subscribe to the messaging service
                    messaging
                      .getToken()
                      .then((token) => {
                        // console.log('firebase token', token)
                        this.postMessageToLns({
                          action: 'FIREBASE_TOKEN',
                          value: token,
                        })
                      })
                      .catch((error) => {
                        // console.log(
                        //   'Unable to get registration firebase token.',
                        //   error
                        // )
                      })
                  })
                  .catch((error) => {
                    // console.log(
                    //   'Unable to get permission for notifications.',
                    //   error
                    // )
                  })
                // registration.active.postMessage({
                //   type: 'firebaseConfig',
                //   config: value,
                // })
              })
              .catch((error) => {
                // console.error(
                //   'Service Worker registration failed Commerce:',
                //   error
                // )
              })

            navigator.serviceWorker.ready.then((registration) => {
              registration.active.postMessage({
                type: 'firebaseConfig',
                config: value,
              })
            })
          }
          break
      }
    },
  },
  mounted() {
    window.addEventListener('popstate', () => {
      this.popStateDetected = true

      this.postMessageToLns({
        action: IFrameActions.NAVIGATION,
        value: window.location?.hash?.replace('#', ''),
      })
    })

    window.addEventListener('message', this.onMessage)

    this.$root.$on('CORDOVA_NAVIGATE_BACK', () => {
      // your code goes here
      this.postMessageToLns({
        action: 'CORDOVA_NAVIGATE_BACK',
      })
    })

    this.$root.$on('remove-lns-firebase-token', () => {
      this.postMessageToLns({
        action: 'REMOVE_FIRE_BASE_TOKEN',
      })
    })

    this.cMounted = true
  },
}
</script>
<style lang="scss" scoped>
iframe {
  background: white;
  border: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  &.visible {
    opacity: 1;
    z-index: 2147483647;
  }
  &.invisible {
    opacity: 0;
    z-index: -100;
  }
}

.cordova.platform-android {
  .iframe-container {
    iframe {
      // opacity: 0;
      transform: translateY(100%);
      transition: all 0.4s ease-in-out;

      &.visible {
        transform: translateY(0);
        // opacity: 1;
      }
      // &.invisible {
      //   opacity: 0;
      // }
    }
  }
}
</style>
