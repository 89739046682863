import { convertArrayToObject } from 'src/utils'

export function SET_DISCOUNTS(state, discountsData) {
  state.discounts = Object.assign(
    {
      ...state.discounts,
    },
    convertArrayToObject(discountsData.items, 'discountID')
  )
  state.hasMoreDiscount = discountsData.hasNext
  state.page += 1
}

export function UPDATE_DISCOUNT(state, discount) {
  if (discount?.discountID)
    state.discounts = {
      ...state.discounts,
      [discount.discountID]: discount,
    }
}

export function SET_DISCOUNT(state, discount) {
  state.currentDiscount = discount
}

export function SET_LOADING(state, loading) {
  state.loading = loading
}

export function SET_LOADING_MORE(state, loadingMore) {
  state.loadingMore = loadingMore
}

export function SET_LOADING_CURRENT_DISCOUNT(state, loading) {
  state.loadingCurrentDiscount = !!loading
}
