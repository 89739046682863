<template>
  <div class="eaiv-loader" v-show="loading">
    <q-card flat class="r-8">
      <q-card-section class="q-px-md q-py-xl text-center">
        <!-- <q-spinner-hourglass size="36px" color="primary" /> -->
        <img src="/images/Ftx_Identity_Loader.gif" />
        <div class="text-primary text-subtitle1 q-mt-md">
          Wait while we verify your age!
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'EAIVSpinner',
  computed: {
    ...mapGetters('ageVerification', ['loading']),
  },
}
</script>

<style lang="scss" scoped>
/* Full Screen Loader */
.eaiv-loader {
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  .q-card {
    width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    @media (max-width: 767px) {
      width: 200px;
      .q-card__section {
        padding-top: 20px;
        padding-bottom: 20px;
        .text-subtitle1 {
          line-height: normal;
        }
      }
    }
  }
}
/* Full Screen Loader */
</style>
