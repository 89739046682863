import Oidc, { UserManager, WebStorageStateStore } from 'oidc-client'
import CookieStorage, { Cookies } from 'src/utils/CookieStorage'
import { Platform } from 'quasar'
import { isSsr } from 'src/constants'

let path = '',
  acrValues = ''

if (process.env.SERVER) {
  path = process.env.fullSsrUrl
  acrValues = `tenant:${process.env.fullSsrUrl}` // signUpUrl:${process.env.fullSsrUrl}/signup
} else if (Platform?.is?.cordova) {
  // path = process.env.ANDROID_DEEP_LINK
  path = `${location.protocol}//${location.hostname}${
    location.port ? ':' + location.port : ''
  }`
  acrValues = `${process.env.Tenant_ACR}` // signUpUrl:${process.env.ANDROID_DEEP_LINK}/signup
} else {
  path = `${location.protocol}//${location.hostname}${
    location.port ? ':' + location.port : ''
  }`

  if (process.env.DEV)
    acrValues = `${process.env.Tenant_ACR}` // signUpUrl:${path}/signup
  else acrValues = `tenant:${location.hostname} ` // signUpUrl:${path}/signup
}

export default ({ ssrContext, Vue, store }) => {
  const cookies = process.env.SERVER ? Cookies.parseSSR(ssrContext) : Cookies,
    settings = {
      userStore: new WebStorageStateStore({
        store: isSsr ? new CookieStorage(cookies) : window.localStorage,
      }),
      authority: process.env.OIDC_AUTHORITY,
      client_id: process.env.OIDC_CLIENTID,
      redirect_uri: `${path}${process.env.OIDC_REDIRECT_URI}`,
      automaticSilentRenew: process.env.OIDC_AUTOMATIC_SILENT_RENEW,
      silent_redirect_uri: `${path}${process.env.OIDC_SILENT_REDIRECT_URI}`,
      response_type: process.env.OIDC_RESPONSE_TYPE,
      scope: process.env.OIDC_SCOPE,
      post_logout_redirect_uri: `${path}${process.env.OIDC_POST_LOGOUT_URI}`,
      acr_values: acrValues,
      original_acr_values: acrValues,
      response_mode: process.env.OIDC_RESPONSE_MODE,
      loadUserInfo: false,
      clockSkew: 300,
      // prompt: 'login',
      popup_redirect_uri: `${path}/${process.env.OIDC_REDIRECT_URI}`,
      popupWindowFeatures:
        'location=no,clearsessioncache=no,clearcache=no,cleardata=no',
      checkSessionInterval: 1000,
    }

  if (Platform?.is?.cordova) {
    settings.prompt = 'login'
    settings.popupNavigator = new Oidc.CordovaPopupNavigator()
    settings.iframeNavigator = new Oidc.CordovaIFrameNavigator()
  }

  const oidc = new UserManager(settings)

  // oidc.signoutPopupCallback(false)
  // Oidc.Log.logger = console
  // Oidc.Log.level = Oidc.Log.DEBUG

  Vue.$oidc = Vue.prototype.$oidc = oidc
  store.$oidc = oidc

  // Defining vuex store to vue class, to get it in agent.js
  // oidc.signinPopupCallback(`${path}/`)
}
