import localForage from 'localforage'

let storeData = {}

export default class PersistedCacheStorage {
  constructor(storeName) {
    this.storeName = storeName || `eFTXPersistedCacheStorage`

    this.createStore()
  }

  createStore() {
    if (!process.env.SERVER) {
      localForage.getItem(this.storeName).then((data) => {
        if (data) storeData = JSON.parse(data)
      })
    }
  }

  updateStore() {
    !process.env.SERVER &&
      localForage.setItem(this.storeName, JSON.stringify(storeData))
  }

  getItem(key) {
    const now = new Date()

    const item = storeData[key]

    if (!item) return null

    if (now.getTime() > item.expiry) {
      this.removeItem(key)
      return null
    }

    return item.value
  }

  setItem(key, value, ttl = 0) {
    if (!key || !value) return false

    let now = new Date()

    storeData = {
      ...storeData,
      [key]: {
        value,
        expiry: now.getTime() + ttl * 60000,
      },
    }

    this.updateStore()

    return true
  }

  removeItem(key) {
    delete storeData[key]

    this.updateStore()
  }

  clear() {
    storeData = {}
    // this.updateStore()
  }
}
