export async function getCMSPageDetails({ commit }, slugId) {
  try {
    commit('SET_LOADING', true)
    // hideShowFullSpinner(true)

    let { data } = await this.$axiosInstance.get(
      '/FooterDetails/PageDetails/Slug',
      {
        params: {
          slugData: slugId,
        },
      }
    )

    if (data && data.data)
      commit('SET_CMS_PAGE_DETAIL', {
        slugId: slugId,
        pageDetail: data.data,
      })
    return data.data
  } catch (error) {
  } finally {
    commit('SET_LOADING', false)
  }
}
