export function SET_CLIENT_CRED(state, clientCred) {
  state.clientCred = clientCred
}

export function SELECT_LOCATION_AND_FULFILLMENTS(state, data = {}) {
  const { location, fulfillment, selectedZipCode } = data

  if (location && fulfillment) {
    // state.selectedLocationId = location.locationId

    // ToDo: To be removed
    state.selectedLocation = {
      locationId: location.locationId,
      name: location.name,
      tokenKey: location.tokenKey,
    }

    // state.fulfillmentId =
    //   fulfillment.fulfillmentID ||
    //   fulfillment.fulfillmentId ||
    //   fulfillment.fulfillmentMethodID

    // ToDo: To be removed
    state.selectedFulfillment = {
      fulfillmentId:
        fulfillment.fulfillmentID ||
        fulfillment.fulfillmentId ||
        fulfillment.fulfillmentMethodID,
      codeName: fulfillment.codeName,
      displayName: fulfillment.fulfillmentName || fulfillment.displayName || '',
    }

    if (fulfillment.codeName === 'sd') {
      if (location.customerZip)
        state.locationOrShippingZip = location.customerZip
    } else state.locationOrShippingZip = location.address?.zip
  } else {
    state.selectedLocationId = null
    state.fulfillmentId = null

    state.selectedLocation = null
    state.selectedFulfillment = null
    state.locationOrShippingZip = null
  }

  if (selectedZipCode) state.selectedZipCode = selectedZipCode
  // if (deliveryAddress) state.deliveryAddress = deliveryAddress
}

// export function SET_SERVICE_LOCATION(state) {
//   state.isProceedLocation = true
// }

export function SET_AUTH_REDIRECT_URI(state, uri) {
  state.authRedirectUri = uri
}

export function SET_ACTIVE_MODE(state, activeMode) {
  state.modes.activeMode = activeMode
}

export function VALIDATE_ACCESS_CODE(state, val) {
  state.modes.accessCodeValidated = val
}

export function SET_EAIV_SESSION_ID(state, sessionId) {
  state.eaivSessionId = sessionId
}

export const SET_EAIV_VERIFICATION_STATUS = (state, val) => {
  state.eaivVerificationSuccess = val
}

export function SET_SHOW_EAIV_PAGE(state, showEAIVPage) {
  state.showEAIVPage = showEAIVPage
}

export function SET_STATE(state, { key, value }) {
  if (key) state[key] = value
}
