<template>
  <span
    v-if="!currentUserIdNumber"
    @click="navigateToProfilePage"
    :class="className"
  >
    My Profile
    <q-icon size="18px" name="launch" class="q-ml-sm" />
  </span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ProfileLink',
  data() {
    return {
      myProfileUrl: process.env.MY_PROFILE_URL,
    }
  },
  props: {
    className: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('auth', ['currentUserIdNumber']),
  },
  methods: {
    navigateToProfilePage() {
      const _this = this,
        options = this.isCordova
          ? 'location=no,clearsessioncache=no,clearcache=no,cleardata=no'
          : this.isPWA
          ? 'toolbar=no'
          : ''
      const popupWindow = window.open(this.myProfileUrl, '_blank', options)
      if (this.isCordova)
        popupWindow.addEventListener('exit', function (event) {
          _this.$store.dispatch('auth/getUserInfo')
        })
    },
  },
}
</script>
