import formatDate from 'src/filters/formatDate'

export async function fetchAvailableSlot({ commit, rootGetters }, params = {}) {
  let fromDate = new Date(),
    toDate = new Date()

  toDate.setDate(fromDate.getDate() + 6)

  try {
    let { data } = await this.$axiosInstance.get('/AvailableSlot', {
      params: {
        locationID: rootGetters['persisted/currentLocationId'],
        fromDate: formatDate(fromDate, 'YYYY-MM-DD'),
        toDate: formatDate(toDate, 'YYYY-MM-DD'),
        ...params,
      },
    })

    return data
  } catch (error) {}
}

export async function getCustomerBlockedSlots({ commit, rootGetters }) {
  try {
    let guestCartNumber = rootGetters['guest/guestCartNumber'],
      isLoggedIn = rootGetters['auth/isLoggedIn']
    let params = {
      locationID: rootGetters['persisted/currentLocationId'],
      global: true,
    }

    if (!isLoggedIn) {
      let pickupSlotBookingID = rootGetters['guest/slotBookingId']('sp'),
        deliverySlotBookingID = rootGetters['guest/slotBookingId']('scd')
      if (guestCartNumber) params.orderNumber = guestCartNumber
      if (pickupSlotBookingID) params.pickupSlotBookingID = pickupSlotBookingID
      if (deliverySlotBookingID)
        params.deliverySlotBookingID = deliverySlotBookingID
    }

    let { data } = await this.$axiosInstance.get(
      '/AvailableSlot/CustomerBlockedSlots',
      { params }
    )

    let { storePickupSlot, scheduledDeliverySlot } = data.data

    commit('SET_SELECTED_SLOT', {
      key: 'storePickupSlot',
      val: storePickupSlot,
    })

    commit('SET_SELECTED_SLOT', {
      key: 'scheduledDeliverySlot',
      val: scheduledDeliverySlot,
    })

    // store slotBookingID for guest checkout
    if (!isLoggedIn) {
      commit(
        'guest/SET_SELECTED_SLOT_ID',
        {
          key: 'storePickupSlotId',
          val: storePickupSlot?.slotBookingID,
        },
        { root: true }
      )

      commit(
        'guest/SET_SELECTED_SLOT_ID',
        {
          key: 'scheduledDeliverySlotId',
          val: scheduledDeliverySlot?.slotBookingID,
        },
        { root: true }
      )
    }
  } catch (error) {}
}

export async function bookOrUpdateSlot(
  { commit, dispatch, rootGetters },
  body
) {
  try {
    let response,
      isLoggedIn = rootGetters['auth/isLoggedIn'],
      selectedFulfillmentCodeName =
        rootGetters['persisted/selectedFulfillmentCodeName'],
      guestSlotBookingId = rootGetters['guest/slotBookingId'](
        selectedFulfillmentCodeName
      )

    if (isLoggedIn)
      response = await this.$axiosInstance.post('/AvailableSlot/Book', body)
    else {
      if (guestSlotBookingId) {
        response = await this.$axiosInstance.put(
          `/AvailableSlot/Book/${guestSlotBookingId}`,
          body
        )
      } else {
        response = await this.$axiosInstance.post('/AvailableSlot/Book', body)
      }
    }

    let { storePickupSlot, scheduledDeliverySlot } = response.data.data

    if (storePickupSlot) {
      commit('SET_SELECTED_SLOT', {
        key: 'storePickupSlot',
        val: storePickupSlot,
      })
      if (!isLoggedIn) {
        commit(
          'guest/SET_SELECTED_SLOT_ID',
          {
            key: 'storePickupSlotId',
            val: storePickupSlot?.slotBookingID,
          },
          { root: true }
        )
      }
    }

    if (scheduledDeliverySlot) {
      commit('SET_SELECTED_SLOT', {
        key: 'scheduledDeliverySlot',
        val: scheduledDeliverySlot,
      })
      if (!isLoggedIn) {
        commit(
          'guest/SET_SELECTED_SLOT_ID',
          {
            key: 'scheduledDeliverySlotId',
            val: scheduledDeliverySlot?.slotBookingID,
          },
          { root: true }
        )
      }
    }

    await dispatch('cart/getCartDetail', {}, { root: true })

    return response.data
  } catch (error) {
    return error
  }
}

export async function resetSlots({ commit, rootGetters }) {
  let isLoggedIn = rootGetters['auth/isLoggedIn']
  commit('RESET_SLOTS')
  if (!isLoggedIn) commit('guest/RESET_SLOTS', null, { root: true })
}
