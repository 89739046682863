<template>
  <div
    ref="addToCartButton"
    :class="[
      'relative-position',
      showFullFillment ? 'product-cart' : '',
      showLoader ? 'product-cart-loading' : '',
    ]"
    v-if="isinstock"
  >
    <transition
      appear
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <span
        :class="['error text-center', showMaxErrorOnTop ? 'top' : '']"
        v-if="selectedFulFillment"
        v-show="
          cartQty >= selectedFulFillment.qtyLimit && (showMaxError || focus)
        "
      >
        Max {{ selectedFulFillment.qtyLimit }}
      </span>
    </transition>

    <div v-if="isItemInCart" class="added-cart">
      <!-- Loader -->
      <q-inner-loading
        :showing="showLoader"
        style="background: rgb(0 0 0 / 32%); border-radius: 4px"
      >
        <q-spinner color="white" size="20px" :thickness="3" />
      </q-inner-loading>
      <!-- Loader -->
      <div
        v-if="showFullFillment"
        class="icon hover"
        @click="isMobile && updateDialogState(true)"
      >
        <SvgIcon size="1rem" class="delivery-mode" :icon="deliveryMode" />
      </div>
      <div class="btn">
        <q-btn
          @click="addItemInCart(-1)"
          round
          dense
          flat
          :icon="cartQty <= 1 ? 'delete' : 'remove'"
          size="0.6rem"
        />
        <q-input
          @blur="
            () => {
              this.focus = false
              if (this.cartQty != this.cartItemInfo.cartQty)
                this.addItemInCart(0)
            }
          "
          @focus="focus = true"
          class="text-center col-6 qtyGroup"
          rounded
          outlined
          v-mask="'####'"
          v-model="cartQty"
          dense
          @keyup.enter="$event.target.blur()"
        />
        <q-btn
          :class="[cartQty >= selectedFulFillment.qtyLimit ? 'disable' : '']"
          @click="addItemInCart(1)"
          round
          dense
          flat
          icon="add"
          size="0.6rem"
        />
      </div>
    </div>

    <div
      v-else-if="showFullFillment"
      :class="[
        'add-cart ',
        isItemHasSelectedFulFillmentOption ? 'addDelivery' : 'hover',
      ]"
      @click="
        !isItemHasSelectedFulFillmentOption &&
          isMobile &&
          updateDialogState(true)
      "
    >
      <!-- Loader -->
      <q-inner-loading
        :showing="showLoader"
        style="background: rgb(0 0 0 / 32%); border-radius: 4px"
      >
        <q-spinner color="white" size="20px" :thickness="3" />
      </q-inner-loading>
      <!-- Loader -->
      <!-- Need to add class when user added this item: addDelivery  -->
      <div
        class="full-width flex items-center justify-center"
        @click="isItemHasSelectedFulFillmentOption && addItemInCart(1)"
      >
        <div class="icon" v-if="deliveryMode">
          <SvgIcon size="1rem" class="delivery-mode" :icon="deliveryMode" />
        </div>
        <div class="btn"> Add to Cart </div>
      </div>
      <!-- <div
        :class="['arrow ', isItemHasSelectedFulFillmentOption ? 'hover' : '']"
      >
        <div class="ar" @click="isMobile && updateDialogState(true)">
          <SvgIcon icon="arrow-angle-right" />
        </div>
      </div> -->
    </div>
  </div>
  <div class="relative-position product-cart product-outstock" v-else>
    <span class="btn-outstock q-px-md">Out Of Stock</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { testModePaymentMessage } from 'src/constants'

export default {
  name: 'AddToCartBtn',
  props: {
    isinstock: {
      type: Boolean,
      default: true,
    },
    productId: {
      type: String,
      default: () => {
        return ''
      },
    },
    productFulFillments: {
      type: Array,
      default: () => {
        return new Array()
      },
    },
    showFullFillment: {
      type: Boolean,
      default: true,
    },
    cartLineItem: {
      type: Object,
      default: null,
    },
    fulFillmentCodeName: {
      type: String,
      default: '',
    },
    showMaxErrorOnTop: {
      type: Boolean,
      default: true,
    },
    isOnDetailPage: {
      type: Boolean,
      default: false,
    },
    onDeliveryModeChnaged: {
      type: Function,
      default: null,
    },
    applyCoupon: {
      type: Boolean,
      default: false,
    },
    showFlyAnimation: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showLoader: false,
      showMaxError: false,
      showMaxErrorTimer: null,
      focus: false,
      timer: null,
      hover: false,
      cartDelivery: '',
      noOfItemsInCart: null,
      cartDialogState: false,
    }
  },
  computed: {
    ...mapGetters('cart', [
      'currentCartDetails',
      'getProductById',
      'currentCartNumber',
    ]),
    ...mapGetters('wishlist', ['allowAddToCart']),
    ...mapGetters('discounts', ['currentDiscount']),
    cartQty: {
      get() {
        return this.noOfItemsInCart !== null
          ? this.noOfItemsInCart
          : this.cartItemInfo.orderLineItemID
          ? this.cartItemInfo.cartQty
          : 0
      },
      set(value) {
        this.noOfItemsInCart = value ? value : 0
      },
    },
    deliveryMode() {
      return this.selectedFulfillmentCodeName
    },
    isItemHasSelectedFulFillmentOption() {
      // return true
      if (this.deliveryMode) {
        for (let fulFillment of this.productFulFillments) {
          if (
            fulFillment.codeName == this.deliveryMode
            // && fulFillment.available
          ) {
            return true
          }
        }
      }
      return false
    },

    fulFillmentId() {
      return this.selectedFulfillment?.fulfillmentId
      // for (let fulFillment of this.productFulFillments) {
      //   if (fulFillment.codeName == this.deliveryMode) {
      //     return fulFillment.fulfillmentMethodID
      //   }
      // }

      // return ''
    },
    selectedFulFillment() {
      for (let fulFillment of this.productFulFillments) {
        if (fulFillment.codeName == this.deliveryMode) {
          return fulFillment
        }
      }
      return false
    },
    cartItemInfo() {
      if (this.cartLineItem) {
        return {
          cartQty: this.cartLineItem.qty,
          orderLineItemID: this.cartLineItem.orderLineItemID,
          cartDelivery: this.fulFillmentCodeName,
        }
      }

      let cartItemInfo = {
          cartQty: 0,
          orderLineItemID: '',
          cartDelivery: '',
        },
        cartDetails = this.currentCartDetails,
        isItemInCart = false

      if (cartDetails?.orderID) {
        for (let fId in cartDetails.fulfillments) {
          const fulFillment = cartDetails.fulfillments[fId]
          if (fulFillment.shoppingCartItems) {
            for (let olId in fulFillment.shoppingCartItems) {
              const item = fulFillment.shoppingCartItems[olId]

              if (item.productId == this.productId) {
                isItemInCart = true
                cartItemInfo.cartDelivery = fulFillment.codeName
                cartItemInfo.cartQty = item.qty
                cartItemInfo.orderLineItemID = item.orderLineItemID

                break
              }

              if (isItemInCart) break
            }
          }
        }
      }

      return cartItemInfo
    },
    isItemInCart() {
      return this.cartItemInfo.orderLineItemID ? true : false
    },
    flyElClass() {
      let className = '.shopping-cart'
      if (this.$route.name == 'Home' && this.isMobile) {
        className = '.shopping-cart-footer'
      }
      return className
    },
  },
  mounted() {
    if (this.isItemHasSelectedFulFillmentOption && !this.allowAddToCart) {
      this.$store.dispatch(
        'wishlist/enableAddToCartForWishlist',
        this.fulFillmentId
      )
    }
  },
  watch: {
    'cartItemInfo.cartQty': function (newVal, oldVal) {
      if (newVal != oldVal && this.cartQty != newVal)
        setTimeout(() => (this.cartQty = newVal), 100)
    },
    'cartItemInfo.cartDelivery': function (newVal, oldVal) {
      if (newVal != oldVal && this.cartDelivery != newVal)
        this.cartDelivery = newVal
    },
    fulFillmentId: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.$store.commit('wishlist/ENABLE_ADD_TO_CART_WISHLIST', newVal)
        }
      },
      deep: true,
    },
  },
  methods: {
    getProductInstruction() {
      let productInfo = this.getProductById(this.productId)
      return productInfo?.instruction || ''
    },
    updateDialogState(val) {
      this.cartDialogState = val
    },
    async deliveryModeChanged(deliveryMode) {
      this.cartDialogState = false

      this.cartDelivery = deliveryMode
      this.cartQty = this.cartItemInfo.qty

      // if (this.cartItemInfo.orderLineItemID)
      //   this.$store.dispatch('cart/toggleSubstitute', {
      //     orderLineItemId: this.cartItemInfo.orderLineItemID,
      //     allowSubstitute: false,
      //   })

      if (!this.isItemInCart) {
        this.addItemInCart(1)
      } else {
        this.cartQty = this.cartItemInfo.cartQty

        this.checkMaxLimit()

        this.setLoading(true)

        await this.$store.dispatch('cart/addUpdateCartDetail', {
          fulfillmentMethodId: this.fulFillmentId,
          products: [
            {
              productId: this.productId,
              qty: this.cartQty,
              instruction: this.getProductInstruction(),
            },
          ],
        })

        this.setLoading(false)

        this.notify('Item Updated to Cart.')
        this.$store.dispatch(
          'wishlist/enableAddToCartForWishlist',
          this.fulFillmentId
        )
      }

      if (this.onDeliveryModeChnaged) this.onDeliveryModeChnaged()
    },
    checkMaxLimit() {
      if (this.cartQty >= this.selectedFulFillment.qtyLimit) {
        this.showMaxError = true

        if (this.showMaxErrorTimer) {
          clearTimeout(this.showMaxErrorTimer)
          this.showMaxErrorTimer = null
        }

        this.showMaxErrorTimer = setTimeout(() => {
          this.showMaxError = false
        }, 2000)

        this.cartQty = this.selectedFulFillment.qtyLimit
      }
    },
    async addItemInCart(incrementQty) {
      if (
        this.businessConfigurations.mode === 2 &&
        this.businessConfigurations.currentPaymentGateway == null
      ) {
        this.showError(testModePaymentMessage)
      } else {
        if (this.isLoggedIn && !this.customerExists)
          return this.signUpConfirm().onOk(() => {
            this.addItemInCartCore(incrementQty)
          })

        if (
          this.isLoggedIn ||
          (!this.isLoggedIn && this.businessConfigurations?.enableGuestCheckout)
        ) {
          this.addItemInCartCore(incrementQty)
        } else if (!this.isLoggedIn)
          this.changeDialogState({
            dialog: 'accountDrawer',
            val: true,
          })
      }
    },
    async addItemInCartCore(incrementQty) {
      this.cartQty = parseInt(this.cartQty)
      incrementQty = parseInt(incrementQty)

      let newCartQty =
        this.cartQty + incrementQty <= 0 ? 0 : this.cartQty + incrementQty

      if (newCartQty <= 0) {
        if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null
        }
        this.setLoading(true)

        await this.$store.dispatch('cart/deleteCartItem', {
          orderItemId: this.cartItemInfo.orderLineItemID,
        })
        this.setLoading(false)

        this.notify('Item Removed from Cart.')
      } else {
        this.cartQty = newCartQty

        this.checkMaxLimit()

        if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null
        }

        this.timer = setTimeout(
          async () => {
            let params = {
              fulfillmentMethodId: this.fulFillmentId,
              products: [
                {
                  productId: this.productId,
                  qty: this.cartQty,
                  instruction: this.getProductInstruction(),
                },
              ],
            }

            this.setLoading(true)
            if (!this.currentCartDetails?.orderID) {
              this.$store.commit('cart/SET_TIP_CONFIRM', false)
            }
            let response = await this.$store.dispatch(
              'cart/addUpdateCartDetail',
              params
            )
            this.setLoading(false)

            if (response && response.success) {
              if (this.cartQty == 1 && incrementQty == 1) {
                if (this.showFlyAnimation) {
                  window.flyToElement &&
                    window.flyToElement(
                      this.$refs.addToCartButton,
                      this.flyElClass,
                      this.isOnDetailPage
                    )
                } else this.notify('Item Added to Cart.')
              } else {
                this.notify('Item Updated to Cart.')
              }
              if (this.applyCoupon && this.currentDiscount?.couponCode) {
                await this.applyDiscountCoupons(
                  this.currentDiscount?.couponCode
                )
              }
            } else if (response?.errors.length) {
              response.errors.forEach((error) => {
                if (error.errorCode == 'CRT_400') {
                  this.setLoading(true)

                  this.$store
                    .dispatch('common/getConfiguration')
                    .finally(() => {
                      this.setLoading(false)

                      const title =
                        'The fulfillment method is unavailable for your selected location (store) currently. How would you like to place your order? '
                      this.changeDialogState({
                        dialog: 'changeLocation',
                        val: true,
                        properties: {
                          dialogTitle: title,
                        },
                      })
                    })
                } else if (error.errorCode == 'CRT_0114') {
                  this.cartQty = this.cartItemInfo.cartQty
                  this.ftxConfirmCoupon(
                    '',
                    error.descreption,
                    false,
                    'Ok',
                    true,
                    false,
                    'info-filled',
                    '3em',
                    true,
                    false,
                    false,
                    true
                  )
                }
              })
            }
          },
          this.isItemInCart ? 400 : 0
        )
      }
      if (this.onDeliveryModeChnaged) this.onDeliveryModeChnaged()
    },
    async applyDiscountCoupons(discountCoupon) {
      if (discountCoupon) {
        try {
          let requestObj = {
            DiscountCouponCode: discountCoupon,
          }
          let guestCartNumber = this.$store.getters['guest/guestCartNumber']
          if (!this.isLoggedIn && guestCartNumber)
            requestObj.OrderNumber = guestCartNumber
          let res = await this.$store.dispatch(
            'cart/applyDiscountCoupons',
            requestObj
          )
          if (
            res.success &&
            res.data.errorMessage &&
            !res.data.errorMessage.length
          ) {
            this.ftxConfirmCoupon(
              '',
              'Yay! Coupon is applied',
              'Cancel',
              'Ok',
              true,
              false,
              '',
              '',
              false,
              true,
              false,
              false
            )
          }
        } catch (error) {}
      }
    },
    notify(message) {
      this.showSuccess(
        message,
        this.$route.name != 'Cart' && this.cartQty > 0
          ? [
              {
                color: 'dark',
                label: 'View Cart',
                color: 'orange-5',
                handler: () => {
                  if (this.isMobile)
                    this.$router
                      .push({
                        name: 'Cart',
                      })
                      .catch(() => {})
                  else
                    this.changeDialogState({
                      dialog: 'cartDrawer',
                      val: true,
                    })
                },
              },
            ]
          : []
      )
    },
    setLoading(loading = false) {
      this.showLoader = loading
    },
  },
}
</script>
<style lang="scss">
.h-search-2 {
  .q-list {
    .q-item {
      &:not(:nth-of-type(1)):not(:nth-of-type(2)):not(:nth-of-type(3)) {
        .product-cart .dropdown {
          bottom: calc(100% - 1px);
          top: auto;
        }
      }
    }
  }
  .q-scrollarea {
    min-height: 330px;
  }
  &.one-record {
    .q-scrollarea {
      max-height: 220px;
      min-height: 220px;
    }
  }
  &.two-record {
    .q-scrollarea {
      max-height: 270px;
      min-height: 270px;
    }
  }
}

.product-cart {
  position: relative;
  width: 100%;
  .q-btn {
    font-family: 'Lato', Arial, sans-serif;
    font-weight: normal;
  }
  .dropdown {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% - 1px);
    color: $tertiary;
    background: #fff;
    border: 1px solid $secondary;
    border: 1px solid var(--q-color-secondary) !important;
    border-radius: 0px 0px 3px 3px;
    z-index: 3;
    transition: all 0.3s ease-in-out;
    // &:nth-child(1),
    // &:nth-child(2),
    // &:nth-child(3),
    // &:nth-child(4) {
    //   top: calc(100% - 1px);
    //   bottom: auto;
    // }
    .q-item {
      min-height: 30px;
      padding: 2px 10px;
      flex-wrap: nowrap;
      background: #fff;
      i {
        color: $tertiary;
      }
      .q-item__section {
      }
    }
    .q-item__section--avatar {
      min-width: 24px;
      padding-right: 5px;
      justify-content: center;
    }
    .q-item__section--main {
      font-size: 12px !important;
      color: #314154;
    }
    .q-radio__inner {
      width: 0.4em;
      min-width: 0.4em;
      height: 0.4em;
    }
    .q-hoverable:hover .q-focus-helper {
      background: $secondary;
      background: var(--q-color-secondary) !important;
    }
  }
  .q-btn__wrapper {
    width: auto;
  }
  &:hover .q-btn--rectangle {
    border-radius: 3px 3px 0px 0px;
  }
  &:hover .q-btn--outline .q-btn__wrapper:before {
    border-color: $secondary;
    border-color: var(--q-color-secondaryOnBody) !important;
  }
  .hover:hover .dropdown {
    display: block;
  }
  .add-cart {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: center;
    background: $white;
    color: $tertiary;
    border: 1px solid $secondary;
    border: 1px solid var(--q-color-secondaryOnBody) !important;
    border-radius: 5px;
    cursor: pointer;
    .icon,
    .btn,
    .arrow {
      padding: 10px 15px;
      min-height: 17px;
      @media (max-width: 767px) {
        /*padding-left: 8px !important;
        padding-right: 8px !important;*/
        padding: 10px;
      }
      @media (max-width: 374px) {
        padding-left: 4px !important;
        padding-right: 4px !important;
      }
    }
    .arrow {
      @media (max-width: 374px) {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
    }
    .icon {
      padding: 10px;
      line-height: 16px;
      display: none;
      i {
        font-size: 14px;
      }
    }
    &.addDelivery .icon {
      display: flex;
    }
    .btn {
      font-size: 14px;
      padding: 10px 15px;
    }
    .arrow {
      border-left: 1px solid $secondary;
      border-left: 1px solid var(--q-color-secondary) !important;
      .ar {
        line-height: 13px;
      }
      i {
        font-size: 12px;
        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
      .dropdown {
        left: 0px;
        right: 0px;
      }
    }
    &.addDelivery {
      background: $secondary;
      background: var(--q-color-secondary) !important;
      color: $secondary;
      color: var(--q-color-secondaryText) !important;
      border: 1px solid $secondary;
      border: 1px solid var(--q-color-secondaryOnBody) !important;
      cursor: pointer;
      .arrow {
        border-left: 1px solid $secondary;
        border-left: 1px solid var(--q-color-secondary) !important;
      }
      .btn {
        padding: 10px 5px;
        @media (max-width: 767px) {
          padding: 10px 10px 10px 0px;
        }
        @media (max-width: 375px) {
          font-size: 12px;
        }
      }
    }
  }
  .added-cart {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    color: $tertiary;
    // border: 1px solid $secondary;
    border: 1px solid var(--q-color-secondaryOnBody) !important;
    // outline: 1px solid var(--q-color-secondaryOnBody);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    position: relative;
    .icon,
    .btn {
      // padding: 10px 8px;
      min-height: 17px;
      @media (max-width: 374px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .icon {
      padding: 9px 10px;
      background: $secondary;
      background: var(--q-color-secondary) !important;
      color: var(--q-color-secondaryText) !important;
      line-height: 16px;
      cursor: pointer;
      border-radius: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      i {
        font-size: 14px;
      }
    }
    .btn {
      flex: 10000 0 0%;
      font-size: 14px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      @media (max-width: 1024px) {
        width: 85px;
      }
      .q-btn {
        flex: 0 0 50px;
        @media (max-width: 1024px) {
          flex: 0 0 30px;
        }
        .q-focus-helper,
        .q-ripple {
          display: none;
        }
        @media (max-width: 374px) {
          flex: 0 0 36px;
        }
      }
      .q-field {
        flex: 10000 0 0%;
        @media (max-width: 991px) {
          font-size: 10px;
        }
      }
    }
    .q-field__control,
    .q-field__marginal {
      height: 35px;
      padding: 0;
    }
    .q-field__native {
      text-align: center;
    }
    .q-field--outlined .q-field__control:before,
    .q-field--outlined .q-field__control:after {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
  /* Bottom */
  &:hover .add-cart {
    border-radius: 5px 5px 0px 0px;
  }
  &:hover .addDelivery {
    border-radius: 5px 5px 5px 5px;
  }
  &.no-rounder:hover {
    .add-cart.addDelivery,
    .added-cart {
      border-radius: 5px 5px 0 0;
      .icon {
        border-top-left-radius: 0;
      }
    }
    .added-cart {
      .dropdown {
        left: -1px;
        right: -1px;
      }
    }
    .addDelivery {
      border-radius: 5px;
    }
  }
  /* Bottom */
  /* Top */
  &.top {
    .dropdown {
      border: 1px solid $secondary;
      border: 1px solid var(--q-color-secondaryOnBody) !important;
      top: auto;
      bottom: calc(100% - 1px);
      border-radius: 3px 3px 0px 0px;
    }
    &:hover .add-cart {
      border-radius: 0px 0px 5px 5px;
    }
    &:hover .addDelivery {
      border-radius: 5px 5px 5px 5px;
    }
    &.no-rounder:hover {
      .add-cart.addDelivery,
      .added-cart {
        border-radius: 0px 0px 5px 5px;
      }
    }
  }
  /* Top */
  /* Loader */
  &.product-cart-loading {
    .added-cart {
      .q-inner-loading {
        svg {
          position: absolute;
          left: 10px;
          //  @media (max-width: 767px) {
          //    left:20px;
          // }
        }
      }
    }
    .add-cart {
      .q-inner-loading {
        svg {
          position: absolute;
          right: 10px;
        }
      }
    }
    .delivery-mode,
    .ar {
      opacity: 0.3;
      transition: opacity 0.1s;
      -webkit-transition: opacity 0.1s;
    }
  }
  /* Loader */
  /* Out of Stock */
  .btn-outstock {
    min-height: 39px;
    font-size: 14px;
    line-height: 38px;
    text-align: center;
    background: rgba(255, 0, 0, 0.1);
    color: rgba(255, 0, 0, 1);
    display: block;
    border-radius: 5px;
  }
  /* Out of Stock */
}
.q-product-slider,
.discount-content {
  .product-cart {
    .dropdown {
      border: 1px solid $secondary;
      border: 1px solid var(--q-color-secondaryOnBody) !important;
      top: auto;
      bottom: calc(100% - 1px);
      border-radius: 3px 3px 0px 0px;
    }
    &:hover .add-cart {
      border-radius: 0px 0px 5px 5px;
    }
    &:hover .addDelivery {
      border-radius: 5px 5px 5px 5px;
    }
    &.no-rounder:hover {
      .add-cart.addDelivery,
      .added-cart {
        border-radius: 0px 0px 5px 5px;
      }
    }
  }
}
.error {
  color: red;
  width: 100%;
  font-size: 10px;
  position: absolute;
  font-weight: bold;
  top: 40px;
  &.top {
    top: -13px;
  }
}

.disable {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}
.notify-custom-mobile-postion {
  @media (max-width: 767px) {
    margin-top: -96px;
  }
}
</style>
