export function SET_LOADING(state, loading) {
  state.loading = loading
}

export const SET_HOME_PAGE_DATA = (state, homePageData) => {
  state.homePageData = homePageData
}

export const SET_PRODUCTS_BY_COLLECTION = (state, { collectionId, items }) => {
  state.products = {
    ...state.products,
    [collectionId]: items,
  }
}

export const UPDATE_HIDE_COMPONENT = (state, { compId, hide }) => {
  state.hideComponent = {
    ...state.hideComponent,
    [compId]: hide,
  }
}

export function SET_STATE(state, { key, value }) {
  if (key) state[key] = value
}
