<template>
  <q-card class="product-items--card" flat>
    <span v-if="currentProduct.hasAgeRestriction" class="productAgeRestricted">
      Age Restricted
    </span>
    <div class="product-mark" v-if="showCheckMark">
      <q-checkbox color="secondaryOnBody" v-model="isSelected" />
    </div>
    <div
      class="product-offer-container"
      v-if="enableOfferTag && currentProduct.hasDiscount && isShowOfferLabel"
    >
      <div
        class="product-offer-btn"
        @click="
          isMobile &&
            changeDialogState({
              dialog: 'OffersModal',
              val: true,
              properties: {
                offers: currentProduct.discounts,
              },
            })
        "
        ><SvgIcon icon="star" class="q-mr-xs" /> <span>Offer</span>
      </div>
      <div class="product-offer-dropdown">
        <ul>
          <li
            v-for="(discount, index) in currentProduct.discounts.slice(0, 3)"
            :key="index"
          >
            <span>{{ discount.displayName }}</span>
            <q-btn
              flat
              color="primary"
              class="q-ml-sm"
              size="sm"
              dense
              label="See How"
              :to="{
                name: 'DiscountDetail',
                params: { seoName: discount.discountID },
              }"
            />
          </li>
          <li v-if="currentProduct.discounts.length > 3" class="q-pt-xs">
            <q-btn
              color="primary"
              class="full-width"
              size="sm"
              dense
              unelevated
              label="More"
            />
          </li>
        </ul>
      </div>
    </div>
    <div class="product-image">
      <router-link-null
        class="no-underline"
        :to="
          currentProduct.seoName
            ? {
                name: 'Detail',
                params: { seoName: currentProduct.seoName },
              }
            : null
        "
      >
        <q-img
          spinner-color="primary"
          :src="currentProduct.profileImage | defaultProductImage"
          :ratio="1"
          contain
        >
          <template v-slot:loading>
            <q-spinner color="primary" size="1em" :thickness="2" />
          </template>
        </q-img>
      </router-link-null>

      <a
        v-if="showClose"
        href="javascript:void(0)"
        class="product-remove"
        @click="removeFromFavorite"
      >
        <SvgIcon icon="close" />
      </a>
      <a
        v-if="hideWishlist"
        href="javascript:void(0)"
        class="product-remove wishlist"
        @click="removeFromWishlist"
      >
        <SvgIcon icon="close" />
      </a>
    </div>
    <div class="product-info">
      <p :class="{ 'product-sponsored': currentProduct.isSponsored }">
        <template v-if="currentProduct.isSponsored">Sponsored</template>
      </p>
      <div class="produc-title-price">
        <q-card-section
          class="row no-wrap q-pt-sm q-pl-none q-pr-none q-pb-none product-price-favorite"
        >
          <div class="col">
            <span class="product-price">
              {{ currentProduct.price | currency }}
              <span class="q-ml-sm text-strike" v-if="false">
                {{ currentProduct.compareAtPrice | currency }}
              </span>
            </span>
            <!--<a href="javascript:void(0)" class="btn-compare">
          <SvgIcon size="1rem" icon="compare" />
        </a>-->
            <AddToWishlist :products="currentProduct" v-if="!hideWishlist" />
            <AddToFavorite :products="currentProduct" v-if="!showClose" />
          </div>
        </q-card-section>

        <router-link-null
          class="no-underline product-title-container"
          :to="
            currentProduct.seoName
              ? { name: 'Detail', params: { seoName: currentProduct.seoName } }
              : null
          "
        >
          <q-card-section class="q-pt-sm q-pl-none q-pr-none q-pb-none">
            <div class="row column no-wrap">
              <div class="cursor-pointer col product-title ellipsis-2-lines">
                {{ currentProduct.title }}
                <q-tooltip
                  v-if="!isMobile"
                  max-width="180px"
                  content-class="bg-dark"
                >
                  {{ currentProduct.title }}
                </q-tooltip>
              </div>
            </div>
          </q-card-section>
        </router-link-null>
      </div>
      <q-card-section
        class="q-pt-sm q-pl-none q-pr-none q-pb-none"
        v-if="isListView"
      >
        <div class="row column no-wrap">
          <div class="col product-desc ellipsis-2-lines">
            {{ currentProduct.shortDescription }}
          </div>
        </div>
      </q-card-section>

      <!--<q-card-section
        class="productOption q-pt-none q-pl-none q-pr-none q-pb-none"
      >
        <div v-if="variants.length > 1">
          <div
            v-if="variants[0].productVariantValues.length == 1"
            class="productSelect q-pt-sm"
          >
            <q-select
              ref="variantSelect"
              transition-show="scale"
              transition-hide="scale"
              outlined
              v-model="selectedVariantId"
              :options="variantDropdown"
              option-value="variantId"
              option-label="value"
              emit-value
              map-options
              dense
              options-dense
              popup-content-class="productSelectOption"
              @input="variantChanged"
              @popup-show="popupShow(true)"
              @popup-hide="popupShow(false)"
            />
          </div>
          <div v-else class="productDialog q-pt-sm">
            <a
              href="javascript:void(0)"
              class="quickDialog block ellipsis"
              @click="openQuickProductModel"
            >
              {{ quickDetailLinkDescription }}
              <q-tooltip
                max-width="180px"
                content-class="bg-dark"
                anchor="top middle"
              >
                {{ quickDetailLinkDescription }}
              </q-tooltip>
            </a>
          </div>
        </div>
      </q-card-section>-->

      <q-card-actions class="q-pt-md q-pl-none q-pr-none q-pb-none">
        <OrderCartButton
          :productId="currentProduct.productID"
          :product="currentProduct"
          :updateState="true"
          :isinstock="currentProduct.isinstock"
          v-if="addToCartButton === 'order'"
        />
        <AddToOrderBtn
          :productFulFillments="
            currentProduct.fulFillments
              ? currentProduct.fulFillments
              : currentProduct.productFulfillments
          "
          :productId="currentProduct.productID"
          :product="currentProduct"
          :isinstock="currentProduct.isinstock"
          :applyCoupon="applyCoupon"
          :orderId="orderId"
          v-else-if="addToCartButton === 'editorder'"
        />
        <template v-else>
          <AddToCartBtn
            :productFulFillments="
              currentProduct.fulFillments
                ? currentProduct.fulFillments
                : currentProduct.productFulfillments
            "
            :productId="currentProduct.productID"
            :isinstock="currentProduct.isinstock"
            :applyCoupon="applyCoupon"
            v-if="variants.length == 0"
          />
          <div v-else class="relative-position product-cart">
            <q-btn
              unelevated
              no-caps
              color="tertiary"
              class="full-width btn-cart-options"
              @click="openQuickProductModel"
              >Options</q-btn
            >
          </div>
        </template>
      </q-card-actions>
    </div>
  </q-card>
</template>

<script>
import { mapGetters } from 'vuex'
import AddToCartBtn from './AddToCartBtn'
import OrderCartButton from './OrderCartButton'
import AddToOrderBtn from './AddToOrderBtn'
import AddToFavorite from './AddToFavorite'
import AddToWishlist from './AddToWishlist'
import { hideShowFullSpinner } from 'src/utils'
export default {
  components: {
    AddToCartBtn,
    AddToFavorite,
    OrderCartButton,
    AddToWishlist,
    AddToOrderBtn,
  },
  props: {
    showClose: {
      type: Boolean,
      default: false,
    },
    hideWishlist: {
      type: Boolean,
      default: false,
    },
    showCheckMark: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    product: {
      type: Object,
      default: null,
    },
    addToCartButton: {
      type: String,
      default: 'cart',
    },
    parentDialog: {
      type: String,
      default: '',
    },
    isListView: {
      type: Boolean,
      default: false,
    },
    selectedProducts: {
      type: Boolean,
      default: false,
    },
    applyCoupon: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      model: '250gm',
      options: ['250gm', '500gm', '750gm', '1kg', '2.5kg'],
      selectedVariantId: '',
    }
  },
  computed: {
    isSelected: {
      get: function () {
        return this.selectedProducts
      },
      set: function (newVal) {
        this.$emit('toggleSelection', this.product.productID)
      },
    },
    currentProduct() {
      if (this.product.variants && this.product.variants.length) {
        for (let product of this.product.variants) {
          if (
            (this.selectedVariantId == '' && product.isDefaultSelected) ||
            this.selectedVariantId == product.productVariantValueID
          ) {
            product.productID = product.productVariantValueID
            return product
          }
        }
      }
      return this.product
    },
    variants() {
      if (this.product.variants) {
        return this.product.variants
      }
      return []
    },
    variantDropdown() {
      let dropdown = []
      if (this.variants.length) {
        if (this.variants[0].productVariantValues.length == 1) {
          for (let variant of this.variants) {
            dropdown.push({
              variantId: variant.productVariantValueID,
              ...variant.productVariantValues[0],
              value: `${variant.productVariantValues[0].value}${
                !variant.isinstock ? ' (Out Of Stock)' : ''
              } `,
            })
          }
        }

        dropdown.sort((a, b) => {
          try {
            if (!isNaN(parseFloat(a.value)) && !isNaN(parseFloat(b.value)))
              return a.value - b.value

            let va = a.value?.toLowerCase(),
              vb = b.value?.toLowerCase()

            if (va < vb) return -1

            if (va > vb) return 1

            return 0
          } catch (e) {
            return 0
          }
        })
      }

      return dropdown
    },
    quickDetailLinkDescription() {
      let description = '+' + (this.variants.length - 1) + ' '

      if (this.isMobile) return `${description}Options`

      for (let variantOption of this.variants[0].productVariantValues) {
        description += variantOption.codeName + '/'
      }

      description = description.substring(0, description.length - 1)

      return description.toLowerCase()
    },
    isShowOfferLabel() {
      return this.$route.name != 'DiscountDetail'
    },
  },
  methods: {
    openAddToCartDialog() {
      if (this.isMobile)
        this.changeDialogState({
          dialog: 'cart',
          val: true,
        })
      else
        this.changeDialogState({
          dialog: 'cartDrawer',
          val: true,
        })
    },
    removeFromFavorite() {
      this.$store.dispatch('favorites/removeItemFromFavorites', {
        productId: this.currentProduct.productID,
        objKey: this.product.productID,
      })

      this.$emit('removeItemFromFavorites')
    },
    removeFromWishlist() {
      this.ftxConfirm('Are you sure you want to Delete?')
        .onOk(() => {
          hideShowFullSpinner(true)
          let productIDs = [this.currentProduct.productID]
          this.$store
            .dispatch('wishlist/deleteWishlistProducts', {
              wishListID: this.$route.params.wishListID,
              productIds: productIDs,
            })
            .then(() => {})
            .catch((e) => {
              console.log('err', e)
            })
            .finally(() => hideShowFullSpinner(false))
        })
        .onCancel(() => {})
        .onDismiss(() => {})
    },
    async updateWishlist() {
      if (this.hideWishlist) {
        let requestObject = {
          ProductIds: [this.selectedVariantId],
          wishListIds: [{ wishListID: this.$route.params.wishListID }],
          // markAsWishProduct: true,
        }

        hideShowFullSpinner(true)
        await this.$store.dispatch(
          'wishlist/toggleProductIsWishlist',
          requestObject
        )
        hideShowFullSpinner(false)
        this.showSuccess('Item Updated to Wishlist')
      }
    },
    variantChanged() {
      if (this.showClose) {
        let requestObject = [
          {
            productID: this.selectedVariantId,
            markAsFavorite: true,
          },
        ]

        this.$store.dispatch('favorites/toggleProductIsFavorite', requestObject)
      }
      this.updateWishlist()
    },
    openQuickProductModel() {
      this.changeDialogState({
        dialog: 'QuickProductDetailDialog',
        val: true,
        parentDialog: this.parentDialog,
        properties: {
          quickProduct: this.product,
          cartBtnType: this.addToCartButton,
        },
        refresh: true,
      })
    },
    popupShow(showed) {
      if (showed)
        this.$root.$on('main-container-scroll', this.onMainContainerScroll)
      else this.$root.$off('main-container-scroll', this.onMainContainerScroll)
    },
    onMainContainerScroll() {
      this.$refs.variantSelect?.hidePopup()
    },
    ...mapGetters('common', ['enableOfferTag']),
  },
  created() {
    this.selectedVariantId = this.currentProduct.productID

    if (this.currentProduct && !this.currentProduct.isinstock) {
      for (let product of this.variants) {
        if (product.isinstock)
          this.selectedVariantId = product.productVariantValueID
      }
    }
  },
}
</script>

<style lang="scss">
.product-offer-container {
  position: relative;
  .product-offer-btn {
    display: flex;
    font-size: 10px;
    text-transform: uppercase;
    color: $positive;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    &:hover {
      & + .product-offer-dropdown {
        @media (min-width: 1023px) {
          display: block;
        }
      }
    }
    @media (max-width: 767px) {
      box-shadow: 0 0 4px 0px rgb(0 0 0 / 10%);
      padding: 3px;
      background-color: #fff;
      border-radius: 4px;
      top: 6px;
    }
  }
  .product-offer-dropdown {
    display: none;
    position: absolute;
    // top: calc(100%);
    top: 13px;
    left: 0;
    right: 0;

    z-index: 3;

    font-size: 10px;
    color: #000;

    &::before {
      content: '';
      width: 100%;
      height: 10px;
      display: block;
    }
    &:after {
      content: '';
      width: 10px;
      height: 10px;
      border: solid rgba(42, 157, 204, 0.4);
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
      position: absolute;
      top: 5px;
      left: 25px;
      background: #fff;
    }
    &:hover {
      display: block;
    }
    ul {
      border-radius: 7px;
      background-color: #fff;
      // box-shadow: 0 0 7px 0px rgba($grey, 0.4);
      width: 100%;
      margin: 0;
      border: 1px solid rgba($primary, 0.4);
      padding: 10px;
      list-style-type: none;
    }
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .q-btn {
        white-space: nowrap;
        text-transform: capitalize;
      }
      & + li {
        margin-top: 2px;
      }
    }
  }
}
body {
  .product-image {
    > a:not(.product-remove) {
      display: block;
      background-color: #fff !important;
    }
  }
}
.productAgeRestricted {
  position: absolute;
  right: 10px;
  top: 10px;
  background: #ff0000ab;
  display: inline-block;
  padding: 3px 10px;
  font-size: 11px;
  color: #fff;
  border-radius: 15px;
  line-height: normal;
  z-index: 2;
}
.product-image {
  padding: 15px;
  @media (min-width: 1024px) and (max-width: 1600px) {
    width: 140px;
    margin: 0 auto;
    .main-container & {
      width: 100%;
      margin: 0 auto;
    }
  }
  img {
    width: 100%;
  }
  @media (max-width: 767px) {
    padding: 20px 15px 5px;
    width: 120px;
    margin: 0 auto;
  }
}
.productOption {
  min-height: 42px;
}
.productVariant {
  margin-left: -3px;
  margin-right: -3px;
  position: relative;
  a {
    margin: 0 3px;
    padding: 5px 8px;
    border: 1px dashed $tertiary;
    min-height: 22px;
    font-size: 11px;
    line-height: 22px;
    color: $tertiary;
    text-decoration: none;
    display: inline-block;
    border-radius: 3px;
    &:hover {
      border-style: solid;
      border-color: $tertiary;
      color: $tertiary;
    }
    &.selected {
      border-style: solid;
      border-color: $primaryOnBody;
      border-color: var(--q-color-primaryOnBody) !important;
      color: $primaryOnBody;
      color: var(--q-color-primaryOnBody) !important;
    }

    &.quickDialog {
      border-style: solid;
      border-color: transparent;
      color: $tertiary;
      position: absolute;
      right: 0;
      &:hover {
        color: $primaryOnBody;
        color: var(--q-color-primaryOnBody) !important;
      }
    }
  }
}
.productDialog {
  a {
    display: inline-block;
    padding: 5px 0;
    min-height: 24px;
    font-size: 13px;
    line-height: 24px;
    color: $tertiary;
    &:hover {
      color: $primaryOnBody;
      color: var(--q-color-primaryOnBody) !important;
    }
  }
}
.productVSelected {
  span {
    display: inline-block;
    padding: 5px 0;
    min-height: 24px;
    font-size: 13px;
    line-height: 24px;
    color: $tertiary;
  }
}
.productSelect {
  .q-field--dense {
    .q-field__control {
      min-height: 22px;
    }
    .q-field__native {
      font-size: 12px;
      min-height: 22px;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .q-field__marginal {
      height: 34px;
    }
  }
}
.productSelectOption {
  font-size: 13px;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.24);
}
.productSelect .q-field--outlined .q-field__control:after {
  border-width: 1px;
}

.btn-cart-options {
  font-family: 'Lato', sans-serif;
  padding: 1px 0 2px;
  -webkit-border-radius: 4px !important;
  border-radius: 4px !important;
}
</style>
