export default function () {
  return {
    isVerificationSuccess: false,
    isVerificationFail: false,
    showVeirificationPopup: true,
    loading: false,
    cartNumber: null,
    skipBrowsingAgeRestrictedProd: null,
  }
}
