export function addresses(state) {
  return state.addresses
}

export function billingAddresses(state) {
  return state.billingAddresses
}

export function loading(state) {
  return state.loading
}

export function newAddressId(state) {
  return (oldId) => state.addressMapping[oldId]
}

export function defaultAddress(state) {
  let defaultAddress = null

  for (let key in state.addresses) {
    if (state.addresses[key].isDefault) {
      defaultAddress = state.addresses[key]
      break
    } else if (!defaultAddress) {
      defaultAddress = state.addresses[key]
    }
  }

  return defaultAddress
}

export function selectedAddressForFulfillmentCode(
  state,
  getters,
  rootState,
  rootGetters
) {
  return (fulfillmentCode) => {
    let cartDetail = rootGetters['cart/currentCartDetails']
    let selectedAddress = state.selectedAddress[fulfillmentCode]
    if (selectedAddress) {
      return selectedAddress
    }

    if (cartDetail) {
      for (let fId in cartDetail.fulfillments) {
        let fulfillment = cartDetail.fulfillments[fId]
        if (fulfillment.codeName == fulfillmentCode && fulfillment.addressID) {
          for (let key in state.addresses) {
            if (key == fulfillment.addressID) {
              return state.addresses[key]
            }
          }
        }
      }
    }

    return getters.defaultAddress
  }
}

export function getAddressByAddressID(state) {
  return (addressId) => {
    let address = null
    for (let key in state.addresses) {
      if (key == addressId) address = state.addresses[key]
    }
    return address
  }
}

export const alertData = (state) => (alertKey) =>
  state.alertData[alertKey] || null

export function getAddressByCustomerAddressID(state) {
  return (customerAddressesID) => {
    let selectedAddress = null
    for (let key in state.addresses) {
      let address = state.addresses[key]
      if (address && address.customerAddressesID == customerAddressesID) {
        selectedAddress = address
        break
      } else continue
    }
    return selectedAddress
  }
}
