export async function getHomePageTemplate({ commit, rootGetters }, params) {
  commit('SET_LOADING', true)

  return this.$axiosInstance
    .get('/PageTemplate', {
      params: {
        locationID: rootGetters['persisted/currentLocationId'],
        filterAgeRestrictedItem:
          rootGetters['ageVerification/showAgeVerificationOption'],
      },
    })
    .then((response) => {
      if (response?.data?.data) {
        commit('SET_STATE', {
          key: 'hideComponent',
          value: {},
        })
        commit('SET_HOME_PAGE_DATA', response.data.data)
      }
    })
    .finally(() => {
      commit('SET_LOADING', false)
    })
}

export function getProductsByCollection({ commit, rootGetters }, collection) {
  if (!collection?.id) return null

  let params = {
    Page: 1,
    PageSize: 15,
    locationId: rootGetters['persisted/currentLocationId'],
    productsBy: 'CL',
    ...rootGetters['common/currentDeliveryModes'],
    collectionId: collection.id,
    global: true,
    zipCode: rootGetters['persisted/locationOrShippingZip'],
  }
  if (
    rootGetters['ageVerification/skipBrowsingAgeRestrictedProd'] &&
    ((!rootGetters['auth/isLoggedIn'] &&
      !rootGetters['persisted/eaivPersistedVerificationSuccess']) ||
      (rootGetters['auth/isLoggedIn'] &&
        !rootGetters['customer/isVerifiedOnline']))
  ) {
    params['skipBrowsingAgeRestrictedProd'] = true
  }
  return this.$axiosInstance
    .get('/Product', { params })
    .then((response) => {
      commit('SET_PRODUCTS_BY_COLLECTION', {
        collectionId: collection.id,
        items: response?.data?.data?.items || [],
      })
      return response?.data?.data
    })
    .catch(() => {
      commit('SET_PRODUCTS_BY_COLLECTION', {
        collectionId: collection.id,
        items: [],
      })
      return null
    })
}
