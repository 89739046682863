<template>
  <div class="fullscreen text-center q-pa-md flex flex-center">
    <div>
      <q-img
        src="images/no-connection.svg"
        :style="
          isMobile
            ? 'width: 250px; height: 200px;'
            : 'width: 400px; height: 300px;'
        "
      />
      <h6
        class="q-ma-none q-mb-md text-h6 q-mt-md text-dark"
        style="opacity:.8"
      >
        <strong>Oops, No internet connection.</strong>
      </h6>
      <p
        >Make sure wifi or cellular data is turned <br />on and then try
        again.</p
      >
      <p class="form-action"
        ><q-btn
          unelevated
          :rounded="!isMobile"
          color="primary"
          label="Try Again"
          class="q-px-sm"
      /></p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NoConnection',
}
</script>
