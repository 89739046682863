export const getDefaultState = () => {
  return {
    dialog: {},
    parentDialogs: [],
    dialogHistory: [],
    showLocationHeader: false,
    drawerWidth: 350,
    footerHeight: 0,
    properties: {},
  }
}

export default getDefaultState()
