<template>
  <div
    class="col-12"
    :style="$q.screen.lt.sm ? '' : { width: `${column.width}%` }"
    v-if="allowedComponents.length"
  >
    <ColumnComponent
      v-for="(component, key, index) in visibleComponents"
      :key="`${key}-${index}`"
      :component="component"
      :isPreview="isPreview"
    />
  </div>
</template>

<script>
export default {
  name: 'SectionColumn',
  props: {
    column: {
      type: Object,
      default: new Object(),
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    hideComponent: {
      type: Object,
      default: new Object(),
    },
  },
  computed: {
    visibleComponents() {
      let visComponents = {}

      if (this.column?.children) {
        for (let iId in this.column.children) {
          if (
            this.column.children[iId].visible &&
            !this.hideComponent[iId] &&
            (this.isPreview ||
              !this.column.children[iId].hasOwnProperty('isActive') ||
              (this.column.children[iId].hasOwnProperty('isActive') &&
                this.column.children[iId].isActive))
          )
            visComponents[iId] = this.column.children[iId]
        }
      }

      return visComponents
    },
    allowedComponents() {
      let allowedComponentIds = []

      if (this.column?.children) {
        for (let iId in this.column.children) {
          // if (
          //   !this.column.children[iId].isAgeRestricted ||
          //   (this.column.children[iId].isAgeRestricted &&
          //     (!this.storeAgeVerificationRequired ||
          //       (this.storeAgeVerificationRequired && this.isUserAgeVerified)))
          // ) {
          // }

          if (
            this.isPreview ||
            !this.column.children[iId].hasOwnProperty('isActive') ||
            (this.column.children[iId].hasOwnProperty('isActive') &&
              this.column.children[iId].isActive)
          ) {
            allowedComponentIds.push(iId)
            break
          }
        }
      }

      return allowedComponentIds
    },
  },
}
</script>
