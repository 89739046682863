export async function getTheme({ commit, getters }) {
  try {
    let response = await this.$axiosInstance.get('/Configuration/Theme', {
      params: {
        global: true,
      },
    })

    if (response?.data?.data) commit('SET_THEME', response.data.data || {})
  } catch (error) {
    if (!getters['theme']) commit('SET_THEME', {})
    return error
  }
}
