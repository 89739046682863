var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"collectionDiv",class:['template-collections q-mb-md']},[(
      _vm.component.collection?.seoName &&
      (_vm.products === false || _vm.products?.length > 0)
    )?[_c('AgeRestrictedBlur',{attrs:{"isPreview":_vm.isPreview,"component":_vm.component}}),_c('div',{class:[
        'row items-start q-product-slider',
        _vm.productsInRow == 0 ? 'no-products' : '',
      ]},[_c('Swiper',{attrs:{"products":_vm.products,"productsInRow":_vm.productsInRow,"title":_vm.component.collectionTitle || _vm.component.collection?.name || '',"seoName":_vm.component.collection.seoName}}),(!_vm.products?.length)?_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row q-col-gutter-md q-mb-sm"},_vm._l((_vm.productsInRow),function(n){return _c('div',{key:n,staticClass:"col"},[_c('q-card',{staticClass:"q-width q-pa-sm",attrs:{"flat":"","bordered":""}},[_c('q-skeleton',{staticClass:"q-mb-md",attrs:{"width":"100%","height":"180px"}}),_c('q-card-section',{staticClass:"row no-wrap q-pt-sm q-pl-none q-pr-none q-pb-none"},[_c('div',{staticClass:"col"},[_c('q-skeleton',{attrs:{"width":"60%","height":"15px"}})],1),_c('div',{staticClass:"col-1"},[_c('q-skeleton',{attrs:{"width":"15px","height":"15px"}})],1)]),_c('q-card-section',{staticClass:"q-pt-sm q-pl-none q-pr-none q-pb-none"},[_c('q-skeleton',{staticClass:"text-caption q-mt-sm",attrs:{"type":"text","width":"100%"}}),_c('q-skeleton',{staticClass:"text-caption",attrs:{"type":"text","width":"30%"}})],1),_c('q-card-section',{staticClass:"q-pt-none q-pl-none q-pr-none q-pb-none"},[_c('q-skeleton',{staticClass:"q-mt-md",attrs:{"width":"40%","height":"15px"}})],1),_c('q-card-actions',{staticClass:"q-pt-md q-pl-none q-pr-none q-pb-none"},[_c('q-skeleton',{attrs:{"width":"100%","type":"QBtn"}})],1)],1)],1)}),0)]):_vm._e()],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }