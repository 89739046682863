export const isVerificationSuccess = (state) => state.isVerificationSuccess

export const isVerificationFail = (state) => state.isVerificationFail

export const showVeirificationPopup = (state) => state.showVeirificationPopup

export const loading = (state) => state.loading

export const isOrderVerified = (state, getters, rootState, rootGetters) =>
  !!(
    state.cartNumber &&
    state.cartNumber == rootGetters['cart/currentCartNumber']
  )

export const skipBrowsingAgeRestrictedProd = (state) =>
  state.skipBrowsingAgeRestrictedProd

export const isUserAgeVerified = (state, getters, rootState, rootGetters) => {
  const isLoggedIn = rootGetters['auth/isLoggedIn']
  return (
    (!isLoggedIn &&
      rootGetters['persisted/eaivPersistedVerificationSuccess']) ||
    (isLoggedIn && rootGetters['customer/customer']?.isVerifiedOnline)
  )
}

export const showAgeVerificationOption = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  let businessConfigurations = rootGetters['common/businessConfigurations']

  return (
    businessConfigurations?.isStoreAgeRestricted &&
    (businessConfigurations.enableAgeRestrictedBrowsing ||
      (businessConfigurations.enableAgeRestrictedCheckout &&
        (getters.skipBrowsingAgeRestrictedProd ||
          getters.skipBrowsingAgeRestrictedProd == null))) &&
    !getters.isUserAgeVerified
  )
}
