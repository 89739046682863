export const SET_USER = (state, user) => (state.user = user)

export function SET_LOADING(state, loading) {
  state.loading = loading
}

export const SET_USER_INFO = (state, currentUser) =>
  (state.currentUser = currentUser)

export const SET_LOADING_USER = (state, loadingUser) =>
  (state.loadingUser = loadingUser)

export const SET_CUSTOMER_EXIST = (state, exists) => (state.exists = exists)

export const SET_PASSWORD_POLICY = (state, passwordPolicy) =>
  (state.passwordPolicy = passwordPolicy)
