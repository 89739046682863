<template>
  <div ref="webSwiperRef">
    <div class="row q-col-gutter-md q-slider-title">
      <div class="col">
        <h2 class="text-h5 q-mt-auto">
          {{ title }}
          <router-link
            class="link-primaryOnBody text-underline"
            :to="{
              name: 'Collection',
              params: { seoName: seoName },
            }"
          >
            <!--
            v-if="products?.length > productsInRow" -->
            See all
          </router-link>
        </h2>
      </div>
      <div class="col-4 relative-position collection-slider-btn">
        <q-btn
          class="slider-prev"
          :disabled="!hasPrevious"
          @click="$refs[name].previous()"
          round
          label="prev"
        />
        <q-btn
          class="slider-next"
          :disabled="!hasNext"
          @click="$refs[name].next()"
          round
          label="next"
        />
      </div>
    </div>
    <q-carousel
      v-if="count && noOfPages > 0 && productsInRow > 0"
      v-model="slide"
      transition-prev="slide-right"
      transition-next="slide-left"
      :swipeable="isMobile"
      animated
      control-color="primary"
      :ref="name"
      :infinite="isMobile"
    >
      <q-carousel-slide
        v-for="page in noOfPages"
        :key="page"
        :name="page"
        class="column no-wrap"
      >
        <div class="row justify-start items-start no-wrap">
          <div
            :class="[
              'col',
              products[productIndex(page, element)] ? 'col-has-prod' : '',
            ]"
            v-for="element in productsInRow"
            :key="element"
          >
            <template v-if="products[productIndex(page, element)]">
              <ProductCard :product="products[productIndex(page, element)]" />
            </template>
          </div>
        </div>
      </q-carousel-slide>
      <!--<template v-slot:control>
        <q-carousel-control position="top-right" :offset="[0, 0]">
          <q-btn
            class="slider-prev"
            :disabled="!hasPrevious"
            @click="$refs[name].previous()"
            round
          />
          <q-btn
            class="slider-next"
            :disabled="!hasNext"
            @click="$refs[name].next()"
            round
          />
        </q-carousel-control>
      </template>-->
    </q-carousel>
    <!-- <q-resize-observer @resize="onResizeSwiper" /> -->
  </div>
</template>

<script>
import ProductCard from 'components/ProductCard'
export default {
  name: 'WebSwiper',
  components: {
    ProductCard,
  },
  props: {
    products: {
      type: [Array, Boolean],
      default: () => {
        return []
      },
    },
    name: {
      type: String,
      default: Math.floor(Date.now() / 100).toString(),
    },
    includLast: {
      type: Boolean,
      default: true,
    },
    blnAppendToCompleteRows: {
      type: Boolean,
      default: false,
    },
    productsInRow: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    seoName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      slide: 1,
      // parentWidth: 0,
    }
  },
  computed: {
    hasNext() {
      return !(this.noOfPages == this.slide)
    },
    hasPrevious() {
      return this.slide > 1
    },
    count() {
      return this.products?.length || 0
    },
    blnIncludeLast() {
      return this.includLast &&
        this.productsInRow > 2 &&
        this.count > this.productsInRow
        ? true
        : false
    },
    noOfPages() {
      if (this.blnIncludeLast) {
        return Math.ceil((this.count - 1) / (this.productsInRow - 1))
      }
      return Math.ceil(this.count / this.productsInRow)
    },
    productLen() {
      return this.products?.length || 0
    },
  },
  watch: {
    productsInRow: function (newVal, oldVal) {
      if (newVal !== oldVal) this.slide = 1
    },
  },
  methods: {
    productIndex(pageNo, elementNo) {
      let index =
        (pageNo - 1) * this.productsInRow +
        elementNo -
        (this.blnIncludeLast ? pageNo : 1)

      return this.blnAppendToCompleteRows &&
        index >= this.products.length &&
        this.count > this.productsInRow
        ? index % this.products.length
        : index
    },
    getProduct(page, element) {
      let index = this.productIndex(page, element)

      if (this.products.length) {
        index =
          index >= this.products.length ? this.products.length % index : index

        this.products[index]
      }
      return null
    },
    // onParentResize() {
    //   this.parentWidth =
    //     this.$refs.webSwiperRef?.parentElement?.clientWidth || 0
    // },
  },
  // mounted() {
  //   // this.parentWidth = this.$refs.webSwiperRef?.parentElement?.clientWidth || 0
  //   // if (this.$refs.webSwiperRef?.parentElement)
  //   //   new ResizeObserver(this.onParentResize).observe(
  //   //     this.$refs.webSwiperRef?.parentElement
  //   //   )
  // },
}
</script>
<style lang="scss">
.q-carousel {
  height: auto;
  /*overflow: inherit;
  .q-carousel__slides-container {
    overflow: hidden;
    .q-panel.scroll {
      overflow: hidden !important;
    }
  }*/
  .q-carousel__slide {
    padding: 0;
  }
}
.q-product-slider {
  &.no-products {
    border: 1px solid #f5f5f5;
    height: calc(100% - 16px);
    .text-h5 {
      padding: 25px;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      min-height: 150px;
    }
    .q-carousel,
    .collection-slider-btn {
      display: none;
    }
  }
  .collection-slider-btn {
    min-height: 58px;
  }
  .slider-prev,
  .slider-next {
    display: block;
    font-size: 0;
    width: 30px;
    height: 30px;
    background: $secondary;
    background: var(--q-color-secondary) !important;
    color: #fff;
    border-radius: 50%;
    top: 16px;
    right: 0;
    position: absolute;
    cursor: pointer;
    @media (max-width: 767px) {
      right: 5px;
    }
    &:after {
      content: '';
      width: 8px;
      height: 8px;
      display: block;
      border-right: 1px solid #fff;
      border-top: 1px solid #fff;
      border-right-color: var(--q-color-secondaryText) !important;
      border-top-color: var(--q-color-secondaryText) !important;
      transform: rotate(45deg);
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -4px;
      margin-left: -6px;
    }
  }
  .slider-prev {
    left: auto;
    right: 40px;
    &:after {
      transform: rotate(-135deg);
      margin-left: -3px;
    }
  }
}
</style>
