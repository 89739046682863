import { Loading } from 'quasar'
import { jwtDecode } from 'jwt-decode'
import { scroll } from 'quasar'

import LoadMoreSpinner from 'components/LoadMoreSpinner'
import CheckoutFullLoader from 'components/CheckoutFullLoader'
import normalizeString from 'src/filters/normalizeString'

const { getScrollTarget, setScrollPosition } = scroll

// export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const convertArrayToObject = (array, key) => {
  if (!Array.isArray(array)) {
    return array
  }

  const initialValue = {}

  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    }
  }, initialValue)
}

export const cloneAsObject = (obj) => {
  const temp = {}

  if (obj === null || !(obj instanceof Object)) return obj

  for (let key in obj) {
    temp[key] = cloneAsObject(obj[key])
  }

  return temp
}

export const formatBookSlotTime = (time) => {
  let formatedTime = time

  try {
    formatedTime = formatedTime.replace(/-/, 'to')
  } catch (err) {}
  return formatedTime
}

export const validateMinCheckoutAmount = (cartDetails) => {
  let cartIsValid = {
    valid: true,
  }

  if (cartDetails && cartDetails.fulfillments) {
    for (const fId in cartDetails.fulfillments) {
      const fulfillment = cartDetails.fulfillments[fId]

      if (
        fulfillment?.minCheckoutAmount >
          fulfillment.totalAmount +
            cartDetails.orderSummary.tipAmount +
            fulfillment.additionalCharges.reduce(
              (sum, x) => sum + x.chargeAmount,
              0
            ) &&
        (!fulfillment.fixedCharge || fulfillment.fixedCharge <= 0)
      ) {
        cartIsValid = {
          valid: false,
          fulfillmentMethodID: fulfillment.fulfillmentMethodID,
        }

        return false
      }
    }
  }

  return cartIsValid
}

export const cartHasFulfillment = (cartDetails, fulfillmentCodeName) => {
  let hasFulfillments = false

  if (cartDetails?.fulfillments) {
    for (const fId in cartDetails.fulfillments) {
      if (cartDetails.fulfillments[fId].codeName == fulfillmentCodeName) {
        hasFulfillments = true
        break
      }
    }

    return hasFulfillments
  }
}

export const mergeObjects = (obj1 = {}, obj2 = {}) => {
  return Object.assign({}, obj1, obj2)
}

export const maskPhoneNumber = (phoneNumber) => {
  if (phoneNumber && phoneNumber.length == 10) {
    return (
      '(' +
      phoneNumber.slice(0, 3) +
      ')-' +
      phoneNumber.slice(3, 6) +
      '-' +
      phoneNumber.slice(6, 10)
    )
  }
  return 'NA'
}

export const toInteger = (value, defaultValue = NaN) => {
  const integer = parseInt(value, 10)
  return isNaN(integer) ? defaultValue : integer
}

export const toFloat = (value, defaultValue = NaN) => {
  const float = parseFloat(value)
  return isNaN(float) ? defaultValue : float
}

export const toFixed = (val, precision) =>
  toFloat(val).toFixed(toInteger(precision, 0))

const toType = (val) => typeof val

export const isBoolean = (val) => toType(val) === 'boolean'

export const isNumeric = (value) => !isNaN(parseInt(value, 10))

export const requestAF =
  (typeof window !== 'undefined' &&
    (window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.msRequestAnimationFrame ||
      window.oRequestAnimationFrame)) ||
  // Fallback, but not a true polyfill
  // Only needed for Opera Mini
  /* istanbul ignore next */
  ((cb) => setTimeout(cb, 16))

export const splashscreenShowHide = (show = false) => {
  if (typeof navigator !== 'undefined' && navigator.splashscreen) {
    if (show) navigator.splashscreen.show()
    else navigator.splashscreen.hide()
  }
}

export const reloadPage = (path = null) => {
  // splashscreenShowHide(true)

  if (window.location.hash) {
    window.location.hash = `#${path || '/'}`
    window.location.reload()
  } else {
    window.location.assign(path || '/')
  }
}

export const hideShowFullSpinner = (show = false, onCheckoutPage = false) => {
  if (show) {
    Loading.show({
      spinner: onCheckoutPage ? CheckoutFullLoader : LoadMoreSpinner,
      backgroundColor: 'grey-3',
    })
  } else {
    Loading.hide()
  }
}

/**
 * checks if an element is visible in the viewport or not
 *
 * @export
 * @param {*} el a DOM element
 * @returns {boolean}
 */
export function isElementInViewport(el) {
  const rect = el.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    parseInt(rect.bottom) <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    parseInt(rect.right) <=
      (window.innerWidth || document.documentElement.clientWidth)
  )
}

export const getOffset = (element) => {
  if (!element) {
    return { top: 0, left: 0 }
  }

  if (!element.getClientRects().length) {
    return { top: 0, left: 0 }
  }

  let rect = element.getBoundingClientRect(),
    win = element.ownerDocument.defaultView

  return {
    top: rect.top + win.pageYOffset,
    left: rect.left + win.pageXOffset,
  }
}

export const validateJWTToken = (token) => {
  if (token) {
    var decoded = jwtDecode(token),
      { exp } = decoded

    if (Date.now() >= exp * 1000) return false

    return true
  }
  return false
}

// export const getContrast = (hexColor) => {
//   hexColor = hexColor.replace('#', '')
//   var r = parseInt(hexColor.substr(0, 2), 16)
//   var g = parseInt(hexColor.substr(2, 2), 16)
//   var b = parseInt(hexColor.substr(4, 2), 16)
//   var yiq = (r * 299 + g * 587 + b * 114) / 1000

//   return yiq >= 128 ? '#000000' : '#ffffff'
// }

// export const checkColorIsLight = (hexColor, compareValue = 128) => {
//   hexColor = hexColor.replace('#', '')
//   var r = parseInt(hexColor.substr(0, 2), 16)
//   var g = parseInt(hexColor.substr(2, 2), 16)
//   var b = parseInt(hexColor.substr(4, 2), 16)
//   var yiq = (r * 299 + g * 587 + b * 114) / 1000

//   return yiq >= compareValue
// }

// export const isTokenValid = (token) => {
//   if (!token) return false
//   const { exp } = jwtDecode(token)
//   if (new Date().getTime() + 1 >= exp * 1000) return false
//   return true
// }

// export const base64toBlob = (b64Data, contentType, sliceSize) => {
//   contentType = contentType || ''
//   sliceSize = sliceSize || 512
//   var byteCharacters = atob(b64Data)
//   var byteArrays = []

//   for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//     var slice = byteCharacters.slice(offset, offset + sliceSize)
//     var byteNumbers = new Array(slice.length)
//     for (var i = 0; i < slice.length; i++) {
//       byteNumbers[i] = slice.charCodeAt(i)
//     }
//     var byteArray = new Uint8Array(byteNumbers)
//     byteArrays.push(byteArray)
//   }

//   var blob = new Blob(byteArrays, { type: contentType })
//   return blob
// }

// export const saveBase64AsPDF = (folderpath, filename, content, contentType) => {
//   return new Promise((resolve, reject) => {
//     // Convert the base64 string in a Blob
//     var DataBlob = base64toBlob(content, contentType)
//     let splitFileName = filename.split('.')
//     filename = `${splitFileName[0]}_${Math.ceil(new Date().valueOf() / 1000)}.${
//       splitFileName[1]
//     }`

//     const onErrorLoadFs = (error) => {
//       console.log(error)
//       reject()
//     }

//     const writeFile = (fileEntry, dataObj) => {
//       // Create a FileWriter object for our FileEntry
//       fileEntry.createWriter(function (fileWriter) {
//         fileWriter.onwriteend = function () {
//           console.log('Successful file write...')
//         }

//         fileWriter.onerror = function (error) {
//           console.log('Failed file write: ' + error)
//         }
//         fileWriter.write(dataObj)
//         resolve()
//       })
//     }

//     const createFile = (dirEntry, fileName, blob) => {
//       // Creates a new file
//       dirEntry.getFile(
//         fileName,
//         { create: true, exclusive: false },
//         function (fileEntry) {
//           writeFile(fileEntry, blob)
//         },
//         onErrorLoadFs
//       )
//     }

//     window.resolveLocalFileSystemURL(
//       folderpath,
//       function (mainDir) {
//         createFile(mainDir, filename, DataBlob)
//       },
//       onErrorLoadFs
//     )
//   })
// }

export const unmask = (maskedValue, mask) => {
  let defaultTokens = ['#', 'X', 'S', 'A', 'a', '!'],
    unmaskedValue = '',
    isToken

  for (let i = 0; i < maskedValue.length; i++) {
    isToken = false
    for (let j = 0; j < defaultTokens.length; j++) {
      if (mask[i] == defaultTokens[j]) isToken = true
    }

    if (isToken) unmaskedValue += maskedValue[i]
  }

  return unmaskedValue
}

export const isEqualObject = (object1, object2) => {
  let arrKeys = Object.keys(object1),
    excludeKeys = ['page', 'pageSize']

  if (arrKeys.length !== Object.keys(object2).length) return false

  if (!arrKeys.length) return true

  for (let key of excludeKeys) {
    let searchIndex = arrKeys.indexOf(key)
    if (searchIndex >= 0) arrKeys.splice(searchIndex, 1)
  }

  for (let key of arrKeys) {
    if (typeof object1[key] === 'object') {
      if (object2[key] && typeof object2[key] === 'object') {
        let isSame = isEqualObject(object1[key], object2[key])
        if (!isSame) return false
      } else return false
    } else if (object1[key] !== object2[key]) {
      return false
    }
  }

  return true
}

export const changeProductSlide = ($event, swiperTop) => {
  let wrapperElement = document.querySelector('.productThumb .swiper-wrapper')
  if (wrapperElement && wrapperElement.childNodes.length == 1) return
  if (swiperTop) {
    if ($event.target) {
      let targetElement = $event.target.parentElement.parentElement
      if (
        targetElement &&
        targetElement.dataset &&
        targetElement.dataset.swiperSlideIndex
      ) {
        let swiperSlideIndex = targetElement.dataset.swiperSlideIndex
        if (swiperSlideIndex && !isNaN(swiperSlideIndex)) {
          swiperTop.$swiper.slideTo(swiperSlideIndex)
        }

        /* Start: Remove slide-active class to all other siblings */
        let prevSibling = targetElement.previousElementSibling
        while (prevSibling) {
          prevSibling.classList.remove('slide-active')
          prevSibling = prevSibling.previousElementSibling
        }
        let nextSibling = targetElement.nextElementSibling
        while (nextSibling) {
          nextSibling.classList.remove('slide-active')
          nextSibling = nextSibling.nextElementSibling
        }
        /* End: Remove slide-active class to all other siblings */
        targetElement.classList.add('slide-active')
      }
    }
  }
}

export const appliedCouponName = (couponName) => {
  return couponName.length > 20
    ? couponName.substring(0, 20) + '...'
    : couponName
}

export const scrollToElement = (el) => {
  const target = getScrollTarget(el)
  const offset = el.offsetTop - el.scrollHeight
  const duration = 1000
  setScrollPosition(target, offset, duration)
}

export const formatPhoneNumber = (phoneNumberString) => {
  var match = phoneNumberString.match(/^(\+?1|\+?91)?(.{3})(.{3})(.{4})$/)
  if (match) {
    var intlCode = match[1] ? `${match[1]} ` : ''
    return [intlCode, '(', match[2], ')-', match[3], '-', match[4]].join('')
  }
  return null
}

export const getClassForCardType = (cardType) => {
  cardType = normalizeString(cardType, true)
  const masterCard = ['mc', 'master', 'mastercard']
  if (masterCard.includes(cardType)) {
    return 'mc'
  } else {
    return cardType
  }
}

export const isValidUuid = (uuid) => {
  const REGEX =
    /^(?:[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}|00000000-0000-0000-0000-000000000000)$/i

  return typeof uuid === 'string' && REGEX.test(uuid)
}

export const normalizeUrlToPath = (url, placeholder = '{id}') => {
  let replacedUrl = ''

  if (url) {
    let splitUrl = url.split('/')
    splitUrl.forEach((sItem) => {
      if (
        sItem &&
        sItem.toLowerCase() !== 'v1' &&
        sItem.toLowerCase() !== 'v2'
      ) {
        if (isValidUuid(sItem)) replacedUrl += `/${placeholder}`
        else replacedUrl += `/${sItem.toLowerCase()}`
      }
    })
  }

  return replacedUrl || url
}

export const isDateEqual = (d1, d2) => {
  d1 = new Date(d1)
  d2 = new Date(d2)
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getDate() === d2.getDate() &&
    d1.getMonth() === d2.getMonth()
  )
}

// export const iosPWASplash = (t, options) => {
//   if ('string' != typeof t || 0 === t.length)
//     throw Error('Invalid icon URL provided')
//   let i = screen.width,
//     a = screen.height,
//     h = window.devicePixelRatio || 1,
//     n = document.createElement('canvas'),
//     l = document.createElement('canvas'),
//     r = n.getContext('2d'),
//     d = l.getContext('2d'),
//     o = new Image()
//   o.crossOrigin = 'anonymous'
//   ;(o.onerror = function () {
//     throw Error('Failed to load icon image')
//   }),
//     (o.src = t),
//     (o.onload = function () {
//       /* Start: meta tag */
//       let m1 = document.createElement('meta'),
//         m2 = document.createElement('meta'),
//         m3 = document.createElement('meta'),
//         m4 = document.createElement('meta'),
//         m5 = document.createElement('meta'),
//         m6 = document.createElement('meta')
//       m1.name = 'mobile-web-app-capable'
//       m1.content = 'yes'
//       m2.name = 'apple-mobile-web-app-status-bar-style'
//       m2.content = 'black'
//       m3.name = 'apple-mobile-web-app-capable'
//       m3.content = 'yes'
//       m4.name = 'apple-mobile-web-app-title'
//       m4.content = options.appTitle
//       m5.name = 'application-name'
//       m5.content = options.appTitle

//       document.head.appendChild(m1)
//       document.head.appendChild(m2)
//       document.head.appendChild(m3)
//       document.head.appendChild(m4)
//       document.head.appendChild(m5)
//       /* End: meta tag */
//       let t = o.width / (3 / h),
//         g = o.height / (3 / h)
//       ;(n.width = i * h),
//         (l.height = n.width),
//         (n.height = a * h),
//         (l.width = n.height),
//         (r.fillStyle = options.splashColor),
//         (d.fillStyle = options.splashColor),
//         r.fillRect(0, 0, n.width, n.height),
//         d.fillRect(0, 0, l.width, l.height)
//       let c = (n.width - t) / 2,
//         p = (n.height - g) / 2,
//         s = (l.width - t) / 2,
//         w = (l.height - g) / 2
//       r.drawImage(o, c, p, t, g), d.drawImage(o, s, w, t, g)
//       let m = n.toDataURL('image/png'),
//         u = l.toDataURL('image/png'),
//         f = document.createElement('link')
//       f.setAttribute('rel', 'apple-touch-startup-image'),
//         f.setAttribute('media', 'screen and (orientation: portrait)'),
//         f.setAttribute('href', m),
//         document.head.appendChild(f)
//       let A = document.createElement('link')
//       A.setAttribute('rel', 'apple-touch-startup-image'),
//         A.setAttribute('media', 'screen and (orientation: landscape)'),
//         A.setAttribute('href', u),
//         document.head.appendChild(A)
//     })
// }

// export const isAndroidOrIosOs = () => {
//   var userAgent = navigator.userAgent || navigator.vendor || window.opera

//   if (/android/i.test(userAgent)) {
//     // return "Android";
//     return true
//   }

//   // iOS detection from: http://stackoverflow.com/a/9039885/177710
//   if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
//     // return "iOS";
//     return true
//   }

//   return false
// }

export const isString = (str) => {
  return typeof str === 'string' || str instanceof String
}

export const addInSortFashion = (el, arr) => {
  const findLoc = (el, arr) => {
    let en = arr.length,
      toBeAdded = el

    if (isNumeric(toBeAdded)) {
      toBeAdded = parseFloat(toBeAdded)
    } else if (isString(toBeAdded)) toBeAdded = toBeAdded.toLowerCase()

    for (let i = 0; i < arr.length; i++) {
      let curTarget = arr[i]

      if (isNumeric(curTarget)) {
        curTarget = parseFloat(curTarget)
      } else if (isString(curTarget)) curTarget = curTarget.toLowerCase()

      if (curTarget > toBeAdded) return i - 1
    }

    return en
  }

  arr.splice(findLoc(el, arr) + 1, 0, el)
  return arr
}

export const isPWA = () => {
  try {
    if (typeof window == 'undefined') return false

    return !!(
      window.matchMedia('(display-mode: standalone)').matches ||
      window.navigator.standalone ||
      document.referrer.includes('android-app://')
    )
  } catch (err) {
    return false
  }
}

export const isBeforeInstallPromptSupported = () => {
  return typeof BeforeInstallPromptEvent === 'function'
}

export const isObject = (myObj) => {
  return typeof myObj === 'object' && !Array.isArray(myObj) && myObj !== null
}

export const getCardType = (cardType) => {
  let type = cardType.toLowerCase(),
    availableTypes = {
      dcvr: 'Discoverlogo',
      discover: 'Discoverlogo',
      amex: 'Amexlogo',
      visa: 'Visalogo',
      master: 'Masterlogo',
      'm/c': 'Masterlogo',
    },
    result = {
      title: '',
      logo: '',
    }
  if (type && availableTypes.hasOwnProperty(type))
    result = { title: cardType, logo: `${availableTypes[type]}.webp` }
  else result = { title: '', logo: `other.webp` }
  return result
}
