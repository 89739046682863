import { Notify } from 'quasar'

Notify.registerType('ftx-notify', { classes: 'notify-custom-mobile-postion' })

const timeout = 2000

export const NotifyMethods = {
  showSuccess(message, actions = []) {
    if (message)
      Notify.create({
        type: 'ftx-notify',
        message: `<i aria-hidden="true" role="presentation" class="material-icons q-icon notranslate text-positive q-ma-xs">check_circle</i>${message}`,
        position: 'bottom',
        timeout: timeout,
        actions: actions,
        html: true,
      })
  },
  showError(message, time = timeout) {
    Notify.create({
      type: 'ftx-notify',
      message: `<i aria-hidden="true" role="presentation" class="material-icons q-icon notranslate text-negative q-ma-xs">error</i>${message}`,
      position: 'bottom',
      timeout: time,
      html: true,
    })
  },
  showWarning(message) {
    Notify.create({
      type: 'ftx-notify',
      message: `<i aria-hidden="true" role="presentation" class="material-icons q-icon notranslate text-warning q-ma-xs">warning</i>${message}`,
      position: 'bottom',
      timeout: timeout,
      html: true,
    })
  },
  // showInfo(message) {},
  showAppDownloadNotify(installHandler) {
    Notify.create({
      message: 'Install Our Android Mobile App',
      color: 'grey-2',
      classes: 'install-app-modal',
      avatar: 'images/android-app-icon.png',
      timeout: 30 * 1000,
      position: 'bottom-left',
      caption: 'To Enjoy A Seamless Experience',
      html: true,
      actions: [
        {
          label: 'Install',
          color: 'primary',
          class: 'install-app-btn',
          handler: () => {
            if (installHandler) installHandler()
          },
        },
        {
          label: 'x',
          color: 'grey-5',
          class: 'modal-close',
        },
      ],
    })
  },
  showMultipleCouponsError(message) {
    Notify.create({
      type: 'ftx-notify',
      message: `${message}`,
      position: 'bottom',
      timeout: 5000,
      html: true,
    })
  },
}

export default ({ Vue }) => {
  for (let methodName in NotifyMethods) {
    if (NotifyMethods.hasOwnProperty(methodName))
      Vue[methodName] = Vue.prototype[methodName] = NotifyMethods[methodName]
  }
}
