// import { Platform, openURL } from 'quasar'
import { mapGetters } from 'vuex'
import { commonMixinConstants } from 'src/constants'
import { unmask, isPWA, isBeforeInstallPromptSupported } from 'src/utils'

export default async ({ Vue, store }) => {
  for (let constKey in commonMixinConstants) {
    if (commonMixinConstants.hasOwnProperty(constKey))
      Vue.prototype[constKey] = commonMixinConstants[constKey]
  }

  // Vue.prototype['isMobile'] = Platform?.is?.mobile || false
  // Vue.prototype['isCordova'] = Platform?.is?.cordova || false

  // Vue.prototype['isPWA'] = isPWA()
  // Vue.prototype['isBeforeInstallPromptSupported'] =
  //   isBeforeInstallPromptSupported()

  Vue.prototype['unmask'] = unmask

  Vue.prototype['scrollToPosition'] = (
    scrollArea = null,
    position = 0,
    delay = 0
  ) => {
    if (scrollArea) scrollArea.setScrollPosition(position, delay)
  }

  Vue.mixin({
    data: function () {
      return {
        commonMeta: {
          titleTemplate: (title) => {
            if (this.businessConfigurations?.businessName)
              return title
                ? `${this.businessConfigurations.businessName} - ${title}`
                : this.businessConfigurations.businessName

            return ''
          },
        },
        logoutInitiated: false,
      }
    },
    computed: {
      ...mapGetters('ageVerification', ['skipBrowsingAgeRestrictedProd']),
      ...mapGetters('persisted', [
        'currentLocationId',
        'currentLocation',
        'currentLocationName',
        'selectedFulfillment',
        'selectedFulfillmentCodeName',
        'eaivPersistedSessionId',
        'eaivPersistedVerificationSuccess',
      ]),
      ...mapGetters('common', [
        'backRoute',
        'businessFulfillments',
        'businessConfigurations',
        'commonStateByKey',
      ]),
      ...mapGetters('auth', [
        'customerExists',
        'isLoggedIn',
        'currentUser',
        'currentUserIdentity',
        'currentUserName',
        'currentUserImage',
      ]),
      ...mapGetters('customer', ['customer']),
      // productsInCollectionRow() {
      //   let currentWidth = 0
      //   for (let screenWidth in this.swiperBreakpoints) {
      //     if (screenWidth > this.homePageSwiperWidth) {
      //       break
      //     }
      //     currentWidth = screenWidth
      //   }

      //   return currentWidth ? this.swiperBreakpoints[currentWidth].elements : 1
      // },
      // isPWA() {
      //   if (typeof window == 'undefined') return false

      //   return !!(
      //     window.matchMedia('(display-mode: standalone)').matches ||
      //     window.navigator.standalone ||
      //     document.referrer.includes('android-app://')
      //   )
      // },
      isBeforeInstallPromptSupported,
      isUserAgeVerified() {
        return (
          (!this.isLoggedIn && this.eaivPersistedVerificationSuccess) ||
          (this.isLoggedIn && this.customer?.isVerifiedOnline)
        )
      },
      // storeAgeVerificationRequired() {
      //   let isRequired = false
      //   if (
      //     this.businessConfigurations?.isStoreAgeRestricted &&
      //     (this.businessConfigurations?.enableAgeRestrictedBrowsing ||
      //       (this.businessConfigurations?.enableAgeRestrictedCheckout &&
      //         this.skipBrowsingAgeRestrictedProd))
      //   )
      //     isRequired = true
      //   return isRequired
      // },
      showAgeVerificationBtn() {
        return (
          this.businessConfigurations?.isStoreAgeRestricted &&
          (this.businessConfigurations.enableAgeRestrictedBrowsing ||
            (this.businessConfigurations.enableAgeRestrictedCheckout &&
              (this.skipBrowsingAgeRestrictedProd ||
                this.skipBrowsingAgeRestrictedProd == null))) &&
          !this.isUserAgeVerified
        )
      },
      lnsLoaded: {
        get() {
          return this.commonStateByKey('lnsLoaded') || false
        },
        set(val) {
          this.$store.commit('common/UPDATE_STATE', {
            key: 'lnsLoaded',
            val,
          })
        },
      },
      isMobile() {
        return this.$q.platform.is.mobile
      },
      isCordova() {
        return this.$q.platform.is.cordova
      },
      storeHasAgeRestriction() {
        return (
          this.businessConfigurations?.isStoreAgeRestricted &&
          (this.businessConfigurations.enableAgeRestrictedBrowsing ||
            this.businessConfigurations.enableAgeRestrictedCheckout)
        )
      },
      isPWA,
    },
    methods: {
      applyBack() {
        let backRoute = this.backRoute
        if (backRoute) {
          this.$router
            .push({
              name: backRoute.name,
              params: backRoute.params,
              query: backRoute.query,
            })
            .catch(() => {})
        } else {
          this.$router
            .push({
              name: 'Home',
            })
            .catch(() => {})
        }
      },
      // onResizeSwiper(size) {
      //   this.$store.commit('common/SET_HOME_PAGE_SWIPER_WIDTH', size.width)
      // },
      async loginWithIdentity(isSignup = false, signupAcr = '') {
        this.$store
          .dispatch('auth/signIn', {
            isSignup,
            signupAcr,
          })
          .then((user) => {
            if (this.isCordova && user) {
              this.$router.push({ name: 'Callback' })
            }
          })
          .catch((error) => {})
      },
      async performLogout(removeLnsToken = true) {
        if (this.logoutInitiated) return

        this.logoutInitiated = true
        if (this.isCordova) {
          await this.$store
            .dispatch('auth/deleteFCMToken', device.uuid)
            .then((response) => response)
            .catch((error) => error)
            .finally(() => {
              this.$store.dispatch('auth/logout')
            })
        } else if (this.lnsLoaded && removeLnsToken) {
          this.$root.$emit('remove-lns-firebase-token')
          // setTimeout(() => {
          //   this.$store.dispatch('auth/logout')
          // }, 5000)
        } else this.$store.dispatch('auth/logout')
      },
    },
  })
}
