export function paymentMethods(state) {
  return state.availableMethods
}

export function getPaymentMethodById(state) {
  return (paymentMethodId) => state.availableMethods[paymentMethodId]
}

export function loading(state) {
  return state.loading
}
