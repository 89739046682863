import { render, staticRenderFns } from "./SignUpConfirm.vue?vue&type=template&id=4bd2ed65"
import script from "./SignUpConfirm.vue?vue&type=script&lang=js"
export * from "./SignUpConfirm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QBtn,QImg,QIcon,QChip,QCheckbox});qInstall(component, 'directives', {ClosePopup});
