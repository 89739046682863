import { setAVSessionID, removeAVSessionID } from 'src/boot/axios'

export async function getCustomer({ commit, dispatch }) {
  try {
    let { data } = await this.$axiosInstance.get(`/Customer`, {
      params: { global: true },
    })

    if (data.data) {
      // if (data.data.isBlocked && this.$router.currentRoute.name != 'Home') {
      //   this.$router.push({ name: 'Home' })
      // }
      commit('SET_CUSTOMER', data.data)
      dispatch('getTotalRedeemableDollars')
    }
  } catch (error) {}
}

export async function mapAgeVerification(
  { commit, getters },
  eaivPersistedSessionId
) {
  try {
    setAVSessionID(eaivPersistedSessionId)
    const { data } = await this.$axiosInstance.post('/AgeVerification/Map')
    if (data.data) {
      commit('SET_CUSTOMER', {
        ...getters['customer'],
        isVerifiedOnline: true,
      })
    }
  } catch (error) {
  } finally {
    removeAVSessionID()
  }
}

export async function getTotalRedeemableDollars({
  commit,
  rootGetters,
  getters,
}) {
  try {
    if (
      rootGetters['common/businessConfigurations'].lnsSubscriptionType == 2 ||
      (rootGetters['common/businessConfigurations'].lnsSubscriptionType == 1 &&
        getters['customer']['isLoyalty'])
    ) {
      let { data } = await this.$axiosInstance.get(
        '/Customer/TotalRedeemableDollars'
      )
      if (data?.success && data?.data?.totalRedeemedDollars)
        commit('SET_TOTAL_REDEEMABLE_DOLLARS', data.data.totalRedeemedDollars)
    }
  } catch (error) {}
}

export async function GetCoupons({ commit, rootGetters }, body) {
  try {
    let { data } = await this.$axiosInstance.get(
      `/Customer/Coupons?locationID=${body.locationID}`
    )

    if (data.success && data.data) commit('SET_COUPONS', data.data)

    return data
  } catch (error) {
    return error
  }
}
