export function SET_SELECTED_SLOT(state, { key, val }) {
  if (val) state[key] = Object.assign({}, val)
  else state[key] = null
}

export function RESET_SLOTS(state) {
  state.storePickupSlot = null
  state.scheduledDeliverySlot = null
}

export function RESET_CURBSIDE_SLOT(state) {
  state.storePickupSlot = null
}

export function RESET_SCHEDULE_DELIVERY_SLOT(state) {
  state.scheduledDeliverySlot = null
}
