import currency from 'src/filters/currency'
import defaultProductImage from 'src/filters/defaultProductImage'
import formatAddress from 'src/filters/formatAddress'
import formatDate from 'src/filters/formatDate'
import kCurrency from 'src/filters/k-currency'
// import normalizeString from 'src/filters/normalizeString'
import titleCase from 'src/filters/titlecase'

export default async ({ Vue, store }) => {
  Vue.filter('currency', currency)
  Vue.filter('formatDate', formatDate)
  Vue.filter('formatAddress', formatAddress)
  // Vue.filter('normalizeString', normalizeString)
  Vue.filter('kCurrency', kCurrency)
  Vue.filter('defaultProductImage', (image, imageType = 'Small') =>
    defaultProductImage(image, imageType, store)
  )
  Vue.filter('titleCase', titleCase)
}
