<template>
  <div
    :class="[
      'template-age-restricted',
      storeHasAgeRestriction && component.isAgeRestricted ? 'blur' : 'no-blur',
    ]"
    v-if="isPreview"
  >
    <q-btn
      icon="schedule"
      unelevated
      no-caps
      round
      color="primary"
      class="no-hover q-mr-sm"
      v-if="component.schedule?.length"
    >
      <!-- component.isScheduled && -->
      <q-tooltip
        class="bg-primary text-caption q-px-sm q-py-none"
        anchor="top middle"
        self="bottom middle"
        :offset="[10, 4]"
      >
        Scheduled
      </q-tooltip>
    </q-btn>
    <q-btn
      unelevated
      no-caps
      round
      color="red"
      class="no-hover"
      v-if="storeHasAgeRestriction && component.isAgeRestricted"
    >
      <SvgIcon size="1.5rem" icon="age-verify" />
      <q-tooltip
        class="bg-red text-caption q-px-sm q-py-none"
        anchor="top middle"
        self="bottom middle"
        :offset="[10, 4]"
      >
        Age Restricted
      </q-tooltip>
    </q-btn>
  </div>
  <div
    :class="['template-age-restricted', blur ? 'blur' : 'no-blur']"
    v-else-if="showAgeVerificationBtn && component.isAgeRestricted"
  >
    <q-avatar
      color="primary"
      class="cursor-pointer unlock-age-restricted"
      @click="toggleAgeRestrictionDialog"
    >
      <SvgIcon size="2rem" icon="age-verify" />
      <q-tooltip
        v-if="!$q.screen.lt.md"
        content-class="bg-dark text-center text-subtitle2 q-px-md"
        :offset="[10, 10]"
      >
        To see the age-restricted products, <br />you need to verify age by
        clicking a 'Lock' icon.
      </q-tooltip>
    </q-avatar>
  </div>
</template>

<script>
export default {
  name: 'AgeRestrictedBlur',
  props: {
    component: {
      type: Object,
      default: new Object(),
    },
    blur: {
      type: Boolean,
      default: true,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    addAgeClickEvent: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    showAgeVerifyPopover: {
      get() {
        return this.getDialogState('showAgeVerifyPopover')
      },
      set(val) {
        this.changeDialogState({
          dialog: 'showAgeVerifyPopover',
          val: val,
        })
      },
    },
  },
  methods: {
    toggleAgeRestrictionDialog() {
      if (this.addAgeClickEvent)
        this.showAgeVerifyPopover = !this.showAgeVerifyPopover
    },
  },
}
</script>
