<template>
  <q-toolbar-title class="q-pl-xs">
    <!-- <SvgIcon className="headerlogosvg" icon="Logo" /> -->
    <!-- <q-img
      v-if="theme && theme.logoURL"
      :src="theme.logoURL"
      :style="
        isMobile
          ? 'max-width: 160px; max-height: 40px;'
          : 'max-width: 200px; max-height: 40px;'
      "
    /> -->
    <img
      v-if="theme?.logoURL"
      :style="
        !isMobile
          ? 'max-width:100%; width:auto; max-height:48px;'
          : ' max-width:100%; width:auto; max-height:50px;'
      "
      :src="theme.logoURL"
      class="inline-block vertical-middle"
      @click="goToHomePage"
    />
    <label
      v-else
      class="q-my-none logo-title"
      v-text="businessConfigurations?.businessName || ''"
      @click="goToHomePage"
    />
  </q-toolbar-title>
</template>
<style scoped>
.q-toolbar__title {
  cursor: pointer;
}
.logo-title {
  font-size: 0.95rem;
  font-weight: bold;
  line-height: 2rem;
  letter-spacing: 0.05em;
}
</style>
<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('theme', ['theme']),
  },
  methods: {
    goToHomePage(event) {
      if (event.ctrlKey) {
        let routeData = this.$router.resolve({ name: 'Home' })
        window.open(routeData.href, '_blank')
      } else if (this.$router.currentRoute.name != 'Home')
        this.$router.push({ name: 'Home' }).catch(() => {})
    },
  },
}
</script>
