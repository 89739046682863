export const getDefaultState = () => {
  return {
    pwaPopupDenied: false,
    cartPersistedInfo: null,
    isCartSectionPined: false,
    filters: {},
    orderFilters: {
      selectedFilter: 'Last 3 Months',
      filterOptions: [],
    },
    showAppDownloadNotification: false,
    disclaimerUpdate: {},
    announcementUpdate: {},
    deliveryAddress: null,
    selectedAddress: {},
    subscribeToLNS: false,
    isContactlessdelivery: false,
  }
}

export default getDefaultState()
