import Vue from 'vue'

export function SET_CUSTOMER_ADDRESSES(state, addresses) {
  state.addresses = addresses
}

export function SET_CUSTOMER_BILLING_ADDRESSES(state, billingAddresses) {
  state.billingAddresses = billingAddresses
}

export function SET_LOADING(state, loading) {
  state.loading = loading
}

export function ADD_OR_UPDATE_BILLING_ADDRESS(state, address) {
  if (address.isDefault) {
    for (let key in state.billingAddresses) {
      state.billingAddresses[key].isDefault = false
    }
  }

  state.billingAddresses = Object.assign({}, state.billingAddresses, {
    [address.addressId]: address,
  })
}

export function ADD_OR_UPDATE_ADDRESS(state, address) {
  if (address.isDefault) {
    for (let key in state.addresses) {
      state.addresses[key].isDefault = false
    }
  }

  state.addresses = Object.assign({}, state.addresses, {
    [address.addressId]: address,
  })
}

export function SET_ADDRESS_MAPPING(state, mapping) {
  state.addressMapping = Object.assign({}, mapping)
}

export function SET_FULFILLMENT_ADDRESS(state, { address, fulfillmentCode }) {
  state.selectedAddress = Object.assign({}, state.selectedAddress, {
    [fulfillmentCode]: address,
  })
}

export function REMOVE_ADDRESS(state, addressId) {
  Vue.delete(state.addresses, addressId)
}

export function SET_ADDRESS_ALERT(state, { alertKey, alertData }) {
  if (typeof alertData === 'object' && alertData !== null)
    Vue.set(state.alertData, alertKey, alertData)
  else Vue.set(state.alertData, alertKey, null)
}
