import Vue from 'vue'
export const updateDialogState = (state, { dialog, val, parentDialog }) => {
  if (parentDialog && !state.parentDialogs.includes(dialog)) {
    state.parentDialogs.push(parentDialog)
  }

  if (state.parentDialogs.includes(dialog)) state.parentDialogs.pop()

  if (val) {
    for (let key in state.dialog) {
      if (
        key != dialog &&
        !state.parentDialogs.includes(key) &&
        key != 'BetaModeDialog'
      )
        state.dialog[key] = false
    }
  }

  if (val) {
    state.dialogHistory = [...state.dialogHistory, dialog]
  } else {
    state.dialogHistory = state.dialogHistory.filter(
      (hDialog) => hDialog !== dialog
    )
  }

  Vue.set(state.dialog, dialog, val)
}

export const updateDrawerWidth = (state, val) => {
  state.drawerWidth = val
}

export const updateLocationHeader = (state, val) => {
  state.showLocationHeader = val
}

export const updateFooterHeight = (state, val) => {
  state.footerHeight = val
}

export const SET_DIALOG_PROPERTIES = (
  state,
  { dialog, properties, refresh = false }
) => {
  if (!state.properties[dialog]) {
    state.properties = Object.assign({}, state.properties, { [dialog]: {} })
  }
  if (refresh) {
    state.properties[dialog] = Object.assign({}, properties)
  } else {
    state.properties[dialog] = Object.assign(
      {},
      state.properties[dialog],
      properties
    )
  }
}

export const DELETE_DIALOG_PROPERTIES = (state, dialog) => {
  Vue.delete(state.properties, dialog)
}
