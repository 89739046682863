var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-card',{staticClass:"product-items--card",attrs:{"flat":""}},[(_vm.currentProduct.hasAgeRestriction)?_c('span',{staticClass:"productAgeRestricted"},[_vm._v("\n    Age Restricted\n  ")]):_vm._e(),(_vm.showCheckMark)?_c('div',{staticClass:"product-mark"},[_c('q-checkbox',{attrs:{"color":"secondaryOnBody"},model:{value:(_vm.isSelected),callback:function ($$v) {_vm.isSelected=$$v},expression:"isSelected"}})],1):_vm._e(),(_vm.enableOfferTag && _vm.currentProduct.hasDiscount && _vm.isShowOfferLabel)?_c('div',{staticClass:"product-offer-container"},[_c('div',{staticClass:"product-offer-btn",on:{"click":function($event){_vm.isMobile &&
          _vm.changeDialogState({
            dialog: 'OffersModal',
            val: true,
            properties: {
              offers: _vm.currentProduct.discounts,
            },
          })}}},[_c('SvgIcon',{staticClass:"q-mr-xs",attrs:{"icon":"star"}}),_c('span',[_vm._v("Offer")])],1),_c('div',{staticClass:"product-offer-dropdown"},[_c('ul',[_vm._l((_vm.currentProduct.discounts.slice(0, 3)),function(discount,index){return _c('li',{key:index},[_c('span',[_vm._v(_vm._s(discount.displayName))]),_c('q-btn',{staticClass:"q-ml-sm",attrs:{"flat":"","color":"primary","size":"sm","dense":"","label":"See How","to":{
              name: 'DiscountDetail',
              params: { seoName: discount.discountID },
            }}})],1)}),(_vm.currentProduct.discounts.length > 3)?_c('li',{staticClass:"q-pt-xs"},[_c('q-btn',{staticClass:"full-width",attrs:{"color":"primary","size":"sm","dense":"","unelevated":"","label":"More"}})],1):_vm._e()],2)])]):_vm._e(),_c('div',{staticClass:"product-image"},[_c('router-link-null',{staticClass:"no-underline",attrs:{"to":_vm.currentProduct.seoName
          ? {
              name: 'Detail',
              params: { seoName: _vm.currentProduct.seoName },
            }
          : null}},[_c('q-img',{attrs:{"spinner-color":"primary","src":_vm._f("defaultProductImage")(_vm.currentProduct.profileImage),"ratio":1,"contain":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('q-spinner',{attrs:{"color":"primary","size":"1em","thickness":2}})]},proxy:true}])})],1),(_vm.showClose)?_c('a',{staticClass:"product-remove",attrs:{"href":"javascript:void(0)"},on:{"click":_vm.removeFromFavorite}},[_c('SvgIcon',{attrs:{"icon":"close"}})],1):_vm._e(),(_vm.hideWishlist)?_c('a',{staticClass:"product-remove wishlist",attrs:{"href":"javascript:void(0)"},on:{"click":_vm.removeFromWishlist}},[_c('SvgIcon',{attrs:{"icon":"close"}})],1):_vm._e()],1),_c('div',{staticClass:"product-info"},[_c('p',{class:{ 'product-sponsored': _vm.currentProduct.isSponsored }},[(_vm.currentProduct.isSponsored)?[_vm._v("Sponsored")]:_vm._e()],2),_c('div',{staticClass:"produc-title-price"},[_c('q-card-section',{staticClass:"row no-wrap q-pt-sm q-pl-none q-pr-none q-pb-none product-price-favorite"},[_c('div',{staticClass:"col"},[_c('span',{staticClass:"product-price"},[_vm._v("\n            "+_vm._s(_vm._f("currency")(_vm.currentProduct.price))+"\n            "),(false)?_c('span',{staticClass:"q-ml-sm text-strike"},[_vm._v("\n              "+_vm._s(_vm._f("currency")(_vm.currentProduct.compareAtPrice))+"\n            ")]):_vm._e()]),(!_vm.hideWishlist)?_c('AddToWishlist',{attrs:{"products":_vm.currentProduct}}):_vm._e(),(!_vm.showClose)?_c('AddToFavorite',{attrs:{"products":_vm.currentProduct}}):_vm._e()],1)]),_c('router-link-null',{staticClass:"no-underline product-title-container",attrs:{"to":_vm.currentProduct.seoName
            ? { name: 'Detail', params: { seoName: _vm.currentProduct.seoName } }
            : null}},[_c('q-card-section',{staticClass:"q-pt-sm q-pl-none q-pr-none q-pb-none"},[_c('div',{staticClass:"row column no-wrap"},[_c('div',{staticClass:"cursor-pointer col product-title ellipsis-2-lines"},[_vm._v("\n              "+_vm._s(_vm.currentProduct.title)+"\n              "),(!_vm.isMobile)?_c('q-tooltip',{attrs:{"max-width":"180px","content-class":"bg-dark"}},[_vm._v("\n                "+_vm._s(_vm.currentProduct.title)+"\n              ")]):_vm._e()],1)])])],1)],1),(_vm.isListView)?_c('q-card-section',{staticClass:"q-pt-sm q-pl-none q-pr-none q-pb-none"},[_c('div',{staticClass:"row column no-wrap"},[_c('div',{staticClass:"col product-desc ellipsis-2-lines"},[_vm._v("\n          "+_vm._s(_vm.currentProduct.shortDescription)+"\n        ")])])]):_vm._e(),_c('q-card-actions',{staticClass:"q-pt-md q-pl-none q-pr-none q-pb-none"},[(_vm.addToCartButton === 'order')?_c('OrderCartButton',{attrs:{"productId":_vm.currentProduct.productID,"product":_vm.currentProduct,"updateState":true,"isinstock":_vm.currentProduct.isinstock}}):(_vm.addToCartButton === 'editorder')?_c('AddToOrderBtn',{attrs:{"productFulFillments":_vm.currentProduct.fulFillments
            ? _vm.currentProduct.fulFillments
            : _vm.currentProduct.productFulfillments,"productId":_vm.currentProduct.productID,"product":_vm.currentProduct,"isinstock":_vm.currentProduct.isinstock,"applyCoupon":_vm.applyCoupon,"orderId":_vm.orderId}}):[(_vm.variants.length == 0)?_c('AddToCartBtn',{attrs:{"productFulFillments":_vm.currentProduct.fulFillments
              ? _vm.currentProduct.fulFillments
              : _vm.currentProduct.productFulfillments,"productId":_vm.currentProduct.productID,"isinstock":_vm.currentProduct.isinstock,"applyCoupon":_vm.applyCoupon}}):_c('div',{staticClass:"relative-position product-cart"},[_c('q-btn',{staticClass:"full-width btn-cart-options",attrs:{"unelevated":"","no-caps":"","color":"tertiary"},on:{"click":_vm.openQuickProductModel}},[_vm._v("Options")])],1)]],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }