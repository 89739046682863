export const getDefaultState = () => {
  return {
    details: {},
    seoMapping: {},
    loading: false,
    companionProducts: {},
    sortByOptions: [
      { value: 'Price Low to High', label: 'Price Low to High' },
      { value: 'Price High to Low', label: 'Price High to Low' },
      { value: 'Best Sellers', label: 'Best Sellers' },
      { value: 'Customer Reviews', label: 'Customer Reviews (Ratings)' },
    ],
    pagination: {
      totalCount: 0,
      pageSize: 20,
      currentPage: 1,
    },
    currentSelectedProductVariant: {},
  }
}

export default getDefaultState()
