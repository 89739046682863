export const clientAccessToken = (state) =>
  state.clientCred && state.clientCred.access_token

export const currentLocationId = (state, getters) =>
  state.selectedLocation?.locationId

// export const currentFulfillmentId = (state, getters) => state.fulfillmentId

// To be Removed
export const currentLocation = (state) => state.selectedLocation

export const currentLocationName = (state, getters) =>
  (getters.currentLocation && getters.currentLocation.name) || ''

export const selectedFulfillment = (state) => state.selectedFulfillment

export const selectedFulfillmentCodeName = (state) =>
  state.selectedFulfillment?.codeName || ''
// To be Removed

export const selectedZipCode = (state) => state.selectedZipCode

export const locationOrShippingZip = (state) => state.locationOrShippingZip

// Unused
// export const isPreferredLocation = (state, getters) =>
//   getters.currentLocation && getters.currentLocation.isPreferredLocation

// export const isProceedLocation = (state) => state.isProceedLocation

export const authRedirectUri = (state) => state.authRedirectUri

export const apiAccessToken = (state, getters, rootState, rootGetters) =>
  rootGetters['auth/isLoggedIn']
    ? rootGetters['auth/accessToken']
    : getters['clientAccessToken']

export const activeMode = (state) => state.modes.activeMode

export const isBetaAccessCodeValidated = (state) =>
  state.modes.accessCodeValidated

export const isOfflineModeActive = (state) => state.modes.activeMode === 1

export const isTestModeActive = (state) => state.modes.activeMode === 2

export const isBetaModeActive = (state) => state.modes.activeMode === 3

export const eaivPersistedSessionId = (state) => state.eaivSessionId

export const eaivPersistedVerificationSuccess = (state) =>
  state.eaivVerificationSuccess

export const showEAIVPage = (state) => state.showEAIVPage
