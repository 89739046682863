import { convertArrayToObject, mergeObjects } from 'src/utils'
import { NotifyMethods } from 'src/boot/notify'

export async function getAvailableMethods({ commit }, params = {}) {
  try {
    commit('SET_LOADING', true)

    let { data } = await this.$axiosInstance.get('/PaymentMethod', {
      params: {
        global: true,
        ...params,
      },
    })

    if (data?.success) {
      let paymentMethods = convertArrayToObject(data.data, 'paymentMethodId')
      commit('SET_PAYMENT_METHODS', paymentMethods)
    }

    commit('SET_LOADING', false)
  } catch (error) {}
}

export async function addPaymentMethod({ commit, state }, address) {
  try {
    let { data } = await this.$axiosInstance.post('/PaymentMethod', address)

    if (data?.success) {
      let paymentMethods = convertArrayToObject(data.data, 'paymentMethodId')
      let newPaymentMethodId = null

      for (let key in paymentMethods) {
        if (!state.availableMethods[key]) {
          newPaymentMethodId = key
          break
        }
      }

      commit('SET_PAYMENT_METHODS', paymentMethods)

      return { ...data, newPaymentMethodId }
    }
  } catch (error) {
    return error
  }
}

export async function deletePaymentMethod({ commit }, paymentMethod) {
  if (paymentMethod && paymentMethod.paymentMethodId) {
    try {
      let { data } = await this.$axiosInstance.delete(
        `/PaymentMethod/${paymentMethod.paymentMethodId}`
      )

      if (data && data.data) {
        commit('REMOVE_PAYMENT_METHODS', paymentMethod.paymentMethodId)
        return true
      }
      return false
    } catch (error) {
      return false
    }
  }
}

export async function UpdateDefaultPaymentMethod(
  { commit, rootGetters },
  paymentMethod
) {
  try {
    let { data } = await this.$axiosInstance.put(`/PaymentMethod/SetDefault`, {
      paymentMethodId: paymentMethod.paymentMethodId,
    })

    if (data?.data) {
      commit(
        'ADD_OR_UPDATE_PAYMENT_METHODS',
        mergeObjects(paymentMethod, { isDefault: true })
      )
      NotifyMethods.showSuccess('Default payment method modified successfully.')
      return true
    }
    return false
  } catch (error) {
    NotifyMethods.showError(error.message)
    return false
  }
}

export async function updateBillingAddress(
  { commit, state, getters },
  { paymentMethodId, address, customerID = null }
) {
  try {
    let reqData = {
      customerAddressId: address.customerAddressesID,
    }
    if (customerID) reqData.customerID = customerID
    let { data } = await this.$axiosInstance.put(
      `/PaymentMethod/BillingAddress/${paymentMethodId}`,
      reqData
    )

    if (data?.success && data?.data === true) {
      let paymentMethod = getters.getPaymentMethodById(paymentMethodId)
      if (paymentMethod?.paymentMethodId) {
        let updatedMethod = {
          ...paymentMethod,
          ['address']: {
            line1: address.line1,
            line2: address.line2,
            city: address.city,
            state: address.state,
            zip: address.zip,
            country: address.country,
            phone: address.phone,
            firstName: address.firstName,
            lastName: address.lastName,
            id: address.customerAddressesID,
          },
        }
        commit('ADD_OR_UPDATE_PAYMENT_METHODS', updatedMethod)
      }

      return data
    }
  } catch (error) {
    return error
  }
}

export async function getUSAePayToken({}, { locationID, cardInfo }) {
  try {
    const { data } = await this.$axiosInstance.post(
      `/PaymentGateway/usaepay/tokenize/${locationID}`,
      cardInfo
    )

    return data
  } catch (error) {
    return error
  }
}
