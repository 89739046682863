import { render, staticRenderFns } from "./Logo.vue?vue&type=template&id=668b8f1e&scoped=true"
import script from "./Logo.vue?vue&type=script&lang=js"
export * from "./Logo.vue?vue&type=script&lang=js"
import style0 from "./Logo.vue?vue&type=style&index=0&id=668b8f1e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "668b8f1e",
  null
  
)

export default component.exports
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QToolbarTitle,QImg,QToolbar});
