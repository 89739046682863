<template>
  <q-avatar
    :size="size"
    :font-size="fontSize"
    color="grey-2"
    :text-color="textColor"
  >
    <q-spinner v-if="false" color="primary" size="1.5em" :thickness="4" />
    <q-img :ratio="1" v-else-if="currentUserImage" :src="currentUserImage">
      <template v-slot:loading>
        <q-spinner color="white" size="1.5em" :thickness="4" />
      </template>
    </q-img>
    <template v-else> {{ avatarCharacters }} </template>
  </q-avatar>
</template>
<script>
export default {
  name: 'UserAvatar',
  props: {
    size: {
      type: String,
      default: 'sm',
    },
    fontSize: {
      type: String,
      default: 'sm',
    },
    textColor: {
      type: String,
      default: 'primaryOnBody',
    },
  },
  computed: {
    avatarCharacters() {
      let avatarCharacters = ''

      if (this.currentUserName && !this.currentUserImage) {
        let arrName = this.currentUserName.split(' ')

        for (let word of arrName) {
          if (word.trim()) {
            avatarCharacters += word[0]
          }
        }
      }

      return avatarCharacters.toUpperCase()
    },
  },
}
</script>
