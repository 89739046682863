export const getDefaultState = () => {
  return {
    locations: {},
    location: null,
    loading: false,
    milesOptions: [
      {
        label: '5 Miles',
        val: '5',
      },
      {
        label: '10 Miles',
        val: '10',
      },
      {
        label: '15 Miles',
        val: '15',
      },
      {
        label: '20 Miles',
        val: '20',
      },
      {
        label: '25 Miles',
        val: '25',
      },
      {
        label: '25+ Miles',
        val: '-1',
      },
    ],
  }
}

export default getDefaultState()
