<template>
  <ftx-dialog position="standard" persistent :dialog="dialog">
    <template v-slot:DialogContent>
      <q-card class="q-py-md accesscode-dialog">
        <q-card-section
          horizontal
          class="row wrap q-col-gutter-lg items-center"
        >
          <q-card-section class="col accesscode-dialog-left">
            <img
              src="images/accesscode-thumb.png"
              alt=""
              title="Beta Mode Banner Image"
            />
          </q-card-section>
          <q-card-section class="col text-center">
            <div class="text-center q-mb-md"><Logo v-show="header.logo" /></div>
            <!-- Show Beta Image -->
            <!-- <div class="text-center q-mb-md">
              <img
                v-if="theme && theme.betaImageUrl"
                :style="
                  !isMobile
                    ? 'max-width:100%; width:auto; max-height:80px;'
                    : ' max-width:100%; width:auto; max-height:60px;'
                "
                :src="theme.betaImageUrl"
                class="inline-block vertical-middle"
              />
            </div> -->
            <h2 class="text-weight-bold text-h6 text-dark q-mb-md">
              Save time, skip the line!
            </h2>
            <p class="q-mb-lg">
              Please enter your 4 digit code to gain exclusive early access to
              our new shop online.
            </p>
            <q-form @submit="submitCode" class="full-width">
              <OTPInput
                ref="otpInput"
                input-classes="otp-input q-mb-md"
                separator=""
                :num-inputs="4"
                :should-auto-focus="true"
                :is-input-num="true"
                @on-change="
                  (val) => {
                    accessCode = val
                  }
                "
              />
              <q-btn
                unelevated
                no-caps
                :disabled="!accessCode || accessCode.length < 4"
                color="secondaryOnBody"
                label="Start Shopping!"
                class="full-width q-py-xs"
                type="submit"
                :loading="loading"
              />
            </q-form>
          </q-card-section>
          <q-card-section class="col-12 q-pt-none">
            <div
              class="q-pa-md bg-grey-3 text-caption line-height-normal r-6 text-justify"
            >
              Welcome to the BETA testing of our online store. Our website is
              currently limited to those with a 4-digit access code. This allows
              us to gather valuable feedback and improve our online store before
              we officially launch. Thank you for your patience and
              understanding, we look forward to bringing you the best shopping
              experience. Stay tuned for our official launch!
            </div>
          </q-card-section>
        </q-card-section>
      </q-card>
    </template>
  </ftx-dialog>
</template>

<script>
import Logo from 'components/header/Logo.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'BetaModeDialog',
  data() {
    return {
      dialog: 'BetaModeDialog',
      accessCode: '',
      loading: false,
    }
  },
  components: {
    Logo,
    OTPInput: () => import('components/otp-input'),
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    ...mapGetters('common', ['header']),
    ...mapGetters('persistedLocal', ['showAppDownloadNotification']),
    properties() {
      return this.getDialogProperties(this.dialog)
    },
    dialogState: {
      get() {
        return this.getDialogState(this.dialog)
      },
      set(val) {
        this.changeDialogState({
          dialog: this.dialog,
          val,
        })
      },
    },
  },
  methods: {
    submitCode() {
      this.loading = true
      this.$store
        .dispatch('common/validateAccessCode', {
          mode: 3,
          code: this.accessCode,
        })
        .then((response) => {
          if (response?.success && response?.data === true) {
            this.$store.commit('persisted/VALIDATE_ACCESS_CODE', response.data)
            this.showSuccess('Access code verified successfully.')
            if (
              !this.isCordova &&
              this.showAppDownloadNotification &&
              !this.$q.platform.is.ios
            )
              this.$store.dispatch('common/getMobileAppData')
            this.updateDialogState({
              dialog: this.dialog,
              val: false,
            })
          } else if (response?.success && response?.data === false) {
            this.showError('Invalid access code.')
            this.$refs.otpInput.clearInput()
          } else if (response?.success === false) {
            if (response.errors?.length)
              this.showError(response.errors[0].descreption)
            else this.showError(response.message)
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
.q-dialog__inner--maximized > div.accesscode-dialog,
.accesscode-dialog {
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    flex-wrap: wrap;
    @media (min-width: 767px) {
      margin: 0;
      box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px rgb(0 0 0 / 14%),
        0 1px 10px rgb(0 0 0 / 12%);
      height: auto;
      max-height: calc(100vh - 48px);
      max-width: 800px;
      width: calc(100% - 30px);
      border-radius: 10px !important;
      padding: 45px;
    }
  }
  @media (max-width: 1023px) {
    > div {
      padding: 15px;
      max-width: 400px;
    }
  }
  .q-toolbar__title {
    cursor: default;
  }
  .text-h5 {
    font-size: 32px;
    @media (max-width: 1023px) {
      font-size: 22px;
    }
  }
  p {
    font-size: 16px;
  }
  .access-code-input {
    input {
      text-align: center;
    }
  }
  &-left {
    @media (max-width: 1023px) {
      display: none;
    }
  }
  .otp-group .otp-input {
    height: 44px;
  }
}
</style>
