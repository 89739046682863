import { reloadPage, hideShowFullSpinner } from 'src/utils'

export async function searchLocation({ commit }, params) {
  try {
    let { data } = await this.$axiosInstance.get('/location', { params })

    return data
  } catch (error) {
    return false
  }
}

export async function verifyShipping({ commit }, params) {
  try {
    let { data } = await this.$axiosInstance.get('/Location/Shipping', {
      params,
    })

    return data
  } catch (error) {
    return error
  }
}

export async function getMyLocation({}, params) {
  try {
    let { data } = await this.$axiosInstance.get('/Location/SearchLocation', {
      params,
    })

    return data
  } catch (error) {}
}

export async function ApplyChangeLocation({ commit, getters }, body) {
  try {
    let changeLocationCartDetail = getters['changeLocationCartDetail']
    await this.$axiosInstance.put(
      '/Cart/ApplyChangeLocation',
      {},
      { params: body }
    )

    if (changeLocationCartDetail?.availableProductsCount == 0) {
      commit('guest/SET_GUEST_CART_NUMBER', null, {
        root: true,
      })
      commit('guest/SET_GUEST_USER', null, {
        root: true,
      })
    }
    return true
  } catch (error) {
    return false
  }
}

export async function setAsMyStore(
  { commit, rootGetters, dispatch },
  {
    location,
    fulfillment,
    selectedZipCode,
    deliveryAddress = null,
    forceCompare = false,
    forceReload = true,
  }
) {
  hideShowFullSpinner(true)

  let isLoggedIn = rootGetters['auth/isLoggedIn']
  if (!isLoggedIn) {
    let currentLocationId = rootGetters['persisted/currentLocationId']
    if (location.locationId != currentLocationId)
      commit('guest/RESET_SLOTS', null, { root: true })
  }

  if (
    forceCompare ||
    (rootGetters['cart/currentCartDetails'] &&
      rootGetters['cart/currentCartDetails']['orderID'])
  ) {
    try {
      let fulfillmentId =
          fulfillment?.fulfillmentID ||
          fulfillment?.fulfillmentId ||
          fulfillment?.fulfillmentMethodID ||
          '',
        guestCartNumber = rootGetters['guest/guestCartNumber'],
        params = {
          locationId: location.locationId,
          fulfillmentMethodID: fulfillmentId,
          zipCode:
            fulfillment.codeName === 'sd'
              ? location.customerZip
              : location.address?.zip,
        }
      if (!isLoggedIn && guestCartNumber) params.orderNumber = guestCartNumber

      let { data } = await this.$axiosInstance.get(
        '/Cart/ChangeLocationDetail',
        { params }
      )

      if (data.success) {
        commit('SET_CHANGE_LOCATION_CART_DETAIL', data.data)
      }
    } catch (error) {}

    commit('SET_TO_LOCATION_N_FULFILLMENT', {
      location,
      fulfillment,
      selectedZipCode,
      deliveryAddress,
    })
    commit(
      'showcase/updateDialogState',
      {
        dialog: 'ChangeLocationConfirm',
        val: true,
        // parentDialog: 'changeLocation',
      },
      { root: true }
    )
  } else {
    await dispatch(
      'persisted/setServiceLocation',
      { location, fulfillment, selectedZipCode, deliveryAddress },
      { root: true }
    )

    if (rootGetters['location/changeLocationAndAddToCart'])
      await dispatch(
        'cart/addUpdateCartDetail',
        rootGetters['location/changeLocationAndAddToCart'],
        { root: true }
      ).then(() => commit('SET_CHANGE_LOCATION_ADD_TO_CART', null))

    if (forceReload) {
      let currentRoute = this.$router.currentRoute
      reloadPage(currentRoute.query.redirectFrom)
    }
  }
  hideShowFullSpinner(false)
}

export async function getAvailableNearByStoreDetails({}, params) {
  try {
    let { data } = await this.$axiosInstance.get(
      '/Location/NearByStoreDetails',
      { params }
    )

    return data
  } catch (error) {}
}
