export function SET_LOCATION(state, location) {
  state.location = location
}

export function ADD_LOCATION(state, location) {
  state.locations[location.locationId] = location
}

export function UPDATE_CHANGE_LOCATION_FIELD(state, { field, val }) {
  state[field] = val
}

export function SET_LOADING(state, loading) {
  state.loading = loading
}
