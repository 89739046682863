import { convertArrayToObject } from 'src/utils'

// add wishlist product
export function toggleProductIsWishlist({ commit }, products = []) {
  let promise = this.$axiosInstance.post('/Wishlist', products)

  promise.then((response) => {
    if (response?.data?.data) {
      commit('SET_WISHLIST_COUNT', response.data.data.wishProductCount)
      commit(
        'MARK_AS_WISHLIST',
        convertArrayToObject(response.data.data.wishProducts, 'productID')
      )
    }
  })
  return promise
}
// get wishlist product
export async function getWishlistProducts(
  { commit, rootGetters },
  { WishListID, params }
) {
  if (
    !rootGetters['persisted/currentLocationId'] ||
    !rootGetters['auth/customerExists']
  )
    return false

  try {
    if (params.Page == 1) commit('SET_LOADING', true)
    let { data } = await this.$axiosInstance.get(
      `/Wishlist/Products/${WishListID}`,
      { params }
    )

    commit('SET_TOTAL', data.data.count)
    let wishlistProducts = convertArrayToObject(data.data.items, 'productID')

    if (params.Page == 1) {
      commit('SET_WISHLIST', wishlistProducts)
      commit('SET_LOADING', false)
    } else {
      commit('ADD_WISHLIST', wishlistProducts)
    }

    return data.data.hasNext
  } catch (error) {}
}

// remove all wishlist products
export async function removeAllItemFromWishlist(
  { state, commit, dispatch },
  body
) {
  let promise = this.$axiosInstance.post('/Wishlist/DeleteProducts', body)

  promise.then((response) => {
    if (response.data.success) {
      commit('SET_TOTAL', state.totalCount - body.productIds.length)
      commit('REMOVE_ALL_ITEM_FROM_WISHLIST', body.productIds)
    }
  })
  return promise
}

export function enableAddToCartForWishlist({ commit }, fulFillmentMethodID) {
  commit('ENABLE_ADD_TO_CART_WISHLIST', fulFillmentMethodID)
}

// add Product wishlist name list
export function addToWishlist({}, body) {
  try {
    let promise = this.$axiosInstance.post('/Wishlist', body)

    promise.then((response) => {})
    return promise
  } catch (error) {
    return error
  }
}
//create new wishlist name
export async function createNewWishlist({ commit, dispatch }, wishListName) {
  try {
    let data = await this.$axiosInstance.post('/Wishlist/Add', {
      wishListName,
    })

    if (data?.data?.success) {
      dispatch('getAllWishlist')
      return data.data
    }
  } catch (error) {
    return error
  }
}

// get all wishlist name
export async function getAllWishlist({ commit, rootGetters }) {
  // if (
  //   !rootGetters['persisted/currentLocationId'] ||
  //   !rootGetters['auth/customerExists']
  // )
  //   return false

  try {
    commit('SET_LOADING', true)
    let params = {
      locationID: rootGetters['persisted/currentLocationId'],
      zipCode: rootGetters['persisted/locationOrShippingZip'],
    }

    let { data } = await this.$axiosInstance.get('v1/Wishlist', {
      params,
    })
    if (data?.data) commit('SET_ALL_WISHLIST', data.data)
  } catch (error) {
  } finally {
    commit('SET_LOADING', false)
  }
}

// delete wishlist name
export async function deleteWishlist({ commit }, wishlistId) {
  try {
    let { data } = await this.$axiosInstance.delete(
      `/Wishlist?wishListID=${wishlistId}`
    )

    if (data?.data) {
      commit('UPDATE_ALL_WISHLIST', wishlistId)
      return data
    }
  } catch (error) {}
}
// edit wishlist name
export async function editWishlist({ dispatch }, params) {
  try {
    let { data } = await this.$axiosInstance.put('/Wishlist', {
      wishListID: params.wishListID,
      wishListName: params.wishListName,
    })

    if (data?.success) {
      dispatch('getAllWishlist')
      return data
    }
  } catch (error) {}
}
// delete single wishlist product
export function deleteWishlistProducts({ state, commit }, body) {
  let promise = this.$axiosInstance.post('/Wishlist/DeleteProducts', body)
  promise.then((response) => {
    if (response.data.success) {
      commit('SET_TOTAL', state.totalCount - 1)
      commit('REMOVE_ITEM_FROM_WISHLIST', body.productIds[0])
    }
  })
  return promise
}
