<template>
  <q-icon :size="size" :class="className" :data-icon="icon" :color="color">
    <svg v-if="useSvg">
      <use v-bind="{ 'xlink:href': 'svg-icons.svg#' + icon }" />
    </svg>
    <component :is="myComponent" v-else />
  </q-icon>
</template>

<script>
export default {
  name: 'svg-icon',
  props: {
    icon: {
      type: String,
      default: 'arrow-down',
    },
    size: {
      type: String,
      default: '',
    },
    className: {
      type: [String, Array],
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    useSvg: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    loader() {
      if (!this.useSvg) return () => import(`./svg-icons/${this.icon}`)
      return null
    },
  },
  data() {
    return {
      myComponent: '',
    }
  },
  created() {
    if (!this.useSvg)
      this.loader().then((res) => {
        this.myComponent = () => this.loader()
      })
  },
  watch: {
    icon: function (newVal, oldVal) {
      if (newVal != oldVal && !this.useSvg)
        this.loader().then((res) => {
          this.myComponent = () => this.loader()
        })
    },
  },
}
</script>
