<template>
  <div :class="['template-slider q-mb-md']">
    <!-- <div class="template-age-restricted">
      <q-avatar color="primary">
        <SvgIcon size="2rem" icon="age-verify" />
        <q-tooltip
          v-if="!$q.screen.lt.md"
          content-class="bg-dark text-center text-subtitle2 q-px-md"
          :offset="[10, 10]"
        >
          To see the age-restricted products, <br />you need to verify age by clicking a 'Lock' icon.
        </q-tooltip>
      </q-avatar>
    </div> -->
    <!-- :class="['template-slider', component.height]"    -->
    <!--<q-carousel
      v-if="visibleChild && Object.keys(visibleChild).length"
      v-model="selSlide"
      :arrows="component.arrows"
      :navigation="component.navigation"
      :infinite="component.infinite"
      :control-type="component.navigationStyle"
      :animated="component.animated"
      :transition-prev="component.transitionPrev"
      :transition-next="component.transitionNext"
      :autoplay="component.autoplay ? component.autoplayDuration * 1000 : 0"
      swipeable
      :navigation-position="component.navigationPosition"
      :vertical="
        component.navigationPosition == 'left' ||
        component.navigationPosition == 'right'
      "
      >
      <q-carousel-slide
        class="ftx-grid-layer"
        v-for="(child, key) of visibleChild"
        :img-src="child.imageUrl || child.defaultImage"
        :key="key"
        :name="child.id"
        :id="`child-${child.id}`"
      >
        <div class="row fit justify-start items-center no-wrap">
          <img
            class="full-width full-height"
            :src="child.imageUrl || child.defaultImage"
            contain
          />
        </div>
        <div
          class="template-slider__caption"
          v-if="
            child.contentSubTitle || child.contentTitle || child.buttonLabel
          "
        >
          <div
            class="template-slider__overlay"
            :style="{ opacity: child.opacity / 100 }"
          />
          <div
            :class="[child.contentPosition, 'q-pa-xl template-slider__text']"
          >
            <div
              class="template-slider__subtitle"
              v-html="child.contentSubTitle"
            />
            <div class="template-slider__title" v-html="child.contentTitle" />
            <q-btn
              v-if="isCordova || !child.openInNewTab"
              unelevated
              no-caps
              outline
              class="q-mt-sm"
              v-show="child.buttonLabel"
              :label="child.buttonLabel"
              :to="getButtonLink(child)"
            />
            <q-btn
              v-else
              unelevated
              no-caps
              outline
              class="q-mt-sm"
              v-show="child.buttonLabel"
              :label="child.buttonLabel"
              @click="handleNavigation(child)"
            />
          </div>
        </div>
      </q-carousel-slide>
    </q-carousel>-->
    <!-- :to="getButtonLink(child)" -->
    <!-- <router-link
            class="q-btn q-mt-md"
            v-show="child.buttonLabel"
            v-text="child.buttonLabel"
            :target="child.openInNewTab ? '_blank' : '_self'"
            :to="getButtonLink(child)"
          /> -->

    <swiper
      v-if="Object.keys(visibleChild).length"
      :options="{
        slidesPerView: 1,
        loop: !!component.infinite || false,
        autoHeight: true,
        navigation: {
          nextEl: `#swiper-button-next-${component.id}`,
          prevEl: `#swiper-button-prev-${component.id}`,
        },
        pagination: {
          el: `#swiper-pagination-${component.id}`,
          clickable: true,
        },
        autoplay: component.autoplay
          ? {
              delay: component.autoplayDuration * 1000,
              disableOnInteraction: false,
            }
          : false,
      }"
      @click.native="slideClicked"
    >
      <swiper-slide
        :class="[
          'ftx-grid-layer ',
          showAgeVerificationBtn && child.isAgeRestricted
            ? 'age-restricted-slide'
            : '',
        ]"
        v-for="(child, key) of visibleChild"
        :img-src="child.imageUrl || child.defaultImage"
        :key="key"
        :name="child.id"
        :id="`child-${child.id}`"
      >
        <div
          class="row fit justify-start items-center no-wrap template-slider__image"
        >
          <SlideLink :slide="child" class="full-width full-height block">
            <div
              class="template-slider__overlay"
              :style="{
                opacity: child.opacity / 100,
              }"
              v-if="
                !(showAgeVerificationBtn && child.isAgeRestricted) &&
                (child.contentSubTitle ||
                  child.contentTitle ||
                  child.buttonLabel)
              "
            />
            <img
              :src="`https://placehold.co/${child.imageWidth || 1246}x${
                child.imageHeight || 415
              }/F5F5F5/black?text=Age+Restricted`"
              alt=""
              class="full-width full-height block"
              v-if="showAgeVerificationBtn && child.isAgeRestricted"
            />
            <img
              v-else
              class="full-width full-height block"
              :src="child.imageUrl || child.defaultImage"
              contain
            />
          </SlideLink>
        </div>
        <!--<div
          class="template-slider__caption"
          v-if="
            child.contentSubTitle || child.contentTitle || child.buttonLabel
          "
        >-->
        <!--<div
            class="template-slider__overlay"
            :style="{ opacity: child.opacity / 100 }"
          />-->
        <div
          :class="[child.contentPosition, 'q-pa-xl template-slider__text']"
          v-if="
            !(showAgeVerificationBtn && child.isAgeRestricted) &&
            (child.contentSubTitle || child.contentTitle || child.buttonLabel)
          "
        >
          <div
            class="template-slider__subtitle"
            v-html="child.contentSubTitle"
          />
          <div class="template-slider__title" v-html="child.contentTitle" />
          <div>
            <q-btn
              v-if="isCordova || !child.openInNewTab"
              unelevated
              no-caps
              outline
              class="q-mt-sm"
              v-show="child.buttonLabel"
              :label="child.buttonLabel"
              :to="getButtonLink(child)"
            />
            <q-btn
              v-else
              unelevated
              no-caps
              outline
              class="q-mt-sm"
              v-show="child.buttonLabel"
              :label="child.buttonLabel"
              @click="handleNavigation(child)"
            />
          </div>
          <!-- :to="getButtonLink(child)" -->
          <!-- <router-link
            class="q-btn q-mt-md"
            v-show="child.buttonLabel"
            v-text="child.buttonLabel"
            :target="child.openInNewTab ? '_blank' : '_self'"
            :to="getButtonLink(child)"
          /> -->
        </div>

        <AgeRestrictedBlur
          :isPreview="isPreview"
          :component="child"
          :blur="false"
          :addAgeClickEvent="false"
        />

        <!--</div>-->
      </swiper-slide>

      <div
        v-if="component.arrows"
        class="swiper-button-prev"
        :id="`swiper-button-prev-${component.id}`"
        slot="button-prev"
      />
      <div
        v-if="component.arrows"
        class="swiper-button-next"
        :id="`swiper-button-next-${component.id}`"
        slot="button-next"
      />
      <div
        v-if="component.navigation"
        class="swiper-pagination"
        :id="`swiper-pagination-${component.id}`"
        slot="pagination"
      />
    </swiper>
  </div>
</template>

<script>
//import { Pagination, Navigation, Autoplay } from 'swiper/modules'
//import { Swiper, SwiperSlide } from 'swiper/vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import SlideLink from './SlideLink'

// import swiper module styles
import 'swiper/css/swiper.css'
//import 'swiper/css/pagination.css'
//import 'swiper/css/navigation.css'
// more module style...

export default {
  name: 'ImageSlider',
  props: {
    component: {
      type: Object,
      default: new Object(),
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Swiper,
    SwiperSlide,
    SlideLink,
  },
  data() {
    return {
      localSelSlide: null,
    }
  },
  computed: {
    selSlide: {
      get() {
        if (this.localSelSlide) return this.localSelSlide

        return Object.keys(this.visibleChild)[0]
      },
      set(value) {
        this.localSelSlide = value
      },
    },
    visibleChild() {
      let activeChild = {}

      if (this.component.children) {
        for (const uniqueId in this.component.children) {
          let child = this.component.children[uniqueId]

          if (
            child.visible &&
            (this.isPreview ||
              !child.hasOwnProperty('isActive') ||
              (child.hasOwnProperty('isActive') && child.isActive))
          ) {
            // if (
            //   !this.component.children[uniqueId].isAgeRestricted ||
            //   (this.component.children[uniqueId].isAgeRestricted &&
            //     (!this.storeAgeVerificationRequired ||
            //       (this.storeAgeVerificationRequired &&
            //         this.isUserAgeVerified)))
            // )
            activeChild[uniqueId] = child
          }
        }
      }

      return activeChild
    },
    showAgeVerifyPopover: {
      get() {
        return this.getDialogState('showAgeVerifyPopover')
      },
      set(val) {
        this.changeDialogState({
          dialog: 'showAgeVerifyPopover',
          val: val,
        })
      },
    },
  },
  methods: {
    slideClicked(event) {
      if (event?.target?.closest('.unlock-age-restricted'))
        this.showAgeVerifyPopover = !this.showAgeVerifyPopover
    },
    handleNavigation(child) {
      let uri = this.getButtonLink(child)
      window.open(uri, '_blank', this.isPWA ? 'toolbar=no' : '')
    },
    getButtonLink(child) {
      if (child.buttonLinkType && child.seoName)
        return `/${child.buttonLinkType}/${child.seoName}`

      return null
    },
  },
}
</script>

<style lang="scss" scoped>
.template-slider {
  &__title {
    font-size: 32px;
  }
  &__subtitle {
    font-size: 16px;
  }
  &__caption {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &__text {
    position: absolute;
    color: #fff;
    padding-left: 56px;
    padding-right: 56px;

    @media (max-width: 767px) {
      background: #000;
      align-items: center !important;
    }
  }
  &__overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background-color: #000;
  }
  &__sm {
    max-height: 420px;
    height: 420px;
  }
  &__md {
    max-height: 560px;
    height: 560px;
  }
  &__lg {
    max-height: 720px;
    height: 720px;
  }
  @media (max-width: 1023px) {
    &__title {
      font-size: 24px;
    }
    &__subtitle {
      font-size: 14px;
    }
    &__sm {
      max-height: 280px;
      height: 280px;
    }
    &__md {
      max-height: 340px;
      height: 340px;
    }
    &__lg {
      max-height: 390px;
      height: 390px;
    }
  }
  @media (max-width: 767px) {
    &__caption {
      position: relative !important;
      left: 0;
      right: 0;
      background: #000;
    }
    &__text {
      position: relative !important;
      padding: 16px;
      padding-bottom: 60px;
      text-align: center !important;
    }
  }
}

.absolute-middle-left,
.absolute-middle-center,
.absolute-top-center,
.absolute-bottom-center,
.absolute-middle-right {
  display: flex;
  flex-direction: column;
  position: absolute;
}

.absolute-middle-left {
  top: 0;
  bottom: 0;
  left: 0;
  align-items: flex-start;
  justify-content: center;
}
.absolute-middle-center {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
}
.absolute-top-center {
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
}
.absolute-bottom-center {
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
}
.absolute-middle-right {
  top: 0;
  bottom: 0;
  right: 0;
  align-items: start;
  justify-content: center;
}
</style>
<style lang="scss">
.template-slider {
  .swiper-container {
    .age-restricted-slide {
      .template-slider__image,
      .template-slider__text {
        filter: blur(5px);
      }
    }
    .template-slider__text {
      .q-btn {
        min-height: auto;
        .q-btn__wrapper {
          width: auto;
          min-height: auto;
        }
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      width: 36px;
      height: 36px;
      background: rgba(255, 255, 255, 0.1);
      -webkit-border-radius: 50%;
      border-radius: 50%;
      &:after {
        content: none;
        font-size: 14px;
        font-weight: bold;
        color: #fff;
      }
      &:before {
        content: '';
        width: 8px;
        height: 8px;
        display: block;
        border-right: 2px solid #fff;
        border-top: 2px solid #fff;
        top: 50%;
      }
      &:hover {
        background: rgba(255, 255, 255, 0.3);
      }
    }
    .swiper-button-next {
      &:before {
        transform: rotate(45deg);
        position: absolute;
        margin-left: -5px;
        margin-top: -4px;
      }
    }
    .swiper-button-prev {
      &:before {
        transform: rotate(-135deg);
        margin-left: 3px;
      }
    }
    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      background: #fff;
    }
  }
}
</style>
