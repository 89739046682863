<template>
  <q-dialog
    v-model="dialogState"
    :maximized="maximized"
    :contentClass="className"
    :position="position"
    @before-show="beforeShow"
    @hide="localOnHide"
    @show="onShow"
    :persistent="persistent"
  >
    <slot name="DialogContent" />
  </q-dialog>
</template>
<script>
export default {
  name: 'FTXDialog',
  props: {
    maximized: {
      type: Boolean,
      default: true,
    },
    dialog: {
      type: String,
      default: '',
    },
    onHide: {
      type: Function,
      default: null,
    },
    beforeShow: {
      type: Function,
      default: () => {},
    },
    onShow: {
      type: Function,
      default: () => {},
    },
    position: {
      type: String,
      default: 'right',
    },
    className: {
      type: String,
      default: '',
    },
    persistent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dialogState: {
      get() {
        return this.getDialogState(this.dialog)
      },
      set(val) {
        this.changeDialogState({
          dialog: this.dialog,
          val,
        })
      },
    },
  },
  methods: {
    localOnHide() {
      this.deleteDialogProperties(this.dialog)
      if (this.onHide) this.onHide()
    },
  },
}
</script>
