<template>
  <section class="section-component" v-if="visibleColumnLen">
    <div class="row">
      <SectionColumn
        v-for="(column, key) in visibleColumn"
        :key="`split-column-${key}`"
        :column="column"
        :isPreview="isPreview"
        :hideComponent="hideComponent"
      />
    </div>
  </section>
</template>

<script>
const columnHasVisibleComponent = (
  column,
  isPreview = false,
  hideComponent = {}
) => {
  let isVisible = false
  if (column.visible) {
    if (column.children) {
      for (let comId in column.children) {
        let component = column.children[comId]

        if (
          component.visible &&
          !hideComponent[component.id] &&
          (isPreview ||
            !component.hasOwnProperty('isActive') ||
            (component.hasOwnProperty('isActive') && component.isActive))
        ) {
          if (component.type === 'section') {
            let hasVisibleInnerCol = false
            for (let innerColId in component.children) {
              let innerCol = component.children[innerColId]
              hasVisibleInnerCol = columnHasVisibleComponent(
                innerCol,
                isPreview,
                hideComponent
              )
              if (hasVisibleInnerCol) break
            }

            if (hasVisibleInnerCol) {
              isVisible = true
              break
            }
          } else if (component.type === 'imageSlider') {
            let hasVisibleSlide = false
            for (let slideId in component.children) {
              const slide = component.children[slideId]

              if (
                slide.visible &&
                !hideComponent[slide.id] &&
                (isPreview ||
                  !slide.hasOwnProperty('isActive') ||
                  (slide.hasOwnProperty('isActive') && slide.isActive))
              ) {
                hasVisibleSlide = true
                break
              }
            }
            if (hasVisibleSlide) {
              isVisible = true
              break
            }
          } else {
            isVisible = true
            break
          }
        }
      }
    }
  }
  return isVisible
}

export default {
  name: 'SplitSection',
  props: {
    section: {
      type: Object,
      default: new Object(),
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    hideComponent: {
      type: Object,
      default: new Object(),
    },
  },
  computed: {
    // visibleColumn() {
    //   let visColumn = {}
    //   if (this.section?.children) {
    //     for (const unId in this.section.children) {
    //       if (this.section.children[unId]?.visible) {
    //         visColumn[unId] = {
    //           ...this.section.children[unId],
    //         }
    //       }
    //     }
    //   }
    //   return visColumn
    // },
    visibleColumn() {
      let visColumn = {}
      if (this.section?.children) {
        let cloneSection = JSON.parse(JSON.stringify(this.section))

        for (const unId in cloneSection.children) {
          let column = cloneSection.children[unId]
          let isVisible = columnHasVisibleComponent(
            column,
            this.isPreview,
            this.hideComponent
          )

          if (isVisible) {
            visColumn[unId] = {
              ...cloneSection.children[unId],
            }
          }

          if (!isVisible) {
            for (const colId in cloneSection.children) {
              if (colId !== unId) {
                let rColumn = cloneSection.children[colId]
                if (rColumn.visible) {
                  let hasOnlyCollectionComponent = true,
                    hasVisible = false

                  if (rColumn.children) {
                    for (let comId in rColumn.children) {
                      let component = rColumn.children[comId]
                      if (
                        component.visible &&
                        !this.hideComponent[component.id] &&
                        (!component.hasOwnProperty('isActive') ||
                          (component.hasOwnProperty('isActive') &&
                            component.isActive))
                      ) {
                        hasVisible = true

                        if (component.type !== 'collection') {
                          hasOnlyCollectionComponent = false
                          break
                        }
                      }
                    }
                  }

                  if (hasOnlyCollectionComponent && hasVisible) {
                    rColumn.width += column.width
                    if (visColumn[colId]) {
                      visColumn[colId] = {
                        ...visColumn[colId],
                        width: rColumn.width,
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      return visColumn
    },
    visibleColumnLen() {
      return Object.keys(this.visibleColumn || {}).length
    },
  },
}
</script>
