<template>
  <ftx-dialog
    dialog="LegalDialog"
    position="standard"
    :maximized="false"
    class="full-width"
    :onShow="onShow"
    :persistent="true"
  >
    <template v-slot:DialogContent>
      <q-card
        class="bg-white legal-dialog-card q-py-md q-pl-md rounded q-pt-xl"
      >
        <q-btn
          flat
          icon="close"
          class="absolute-top-right"
          @click="
            changeDialogState({
              dialog: 'LegalDialog',
              val: false,
            })
          "
        />
        <q-scroll-area
          ref="scrollAreaRef"
          class=""
          :thumb-style="scrollAreaThumbStyle"
          :bar-style="scrollAreaBarStyle"
          :visible="scrollAreaVisible"
          :style="'height:calc(90vh - 100px); padding-right:25px'"
        >
          <h2 class="text-h5 q-mt-none text-primary">Terms of Services</h2>
          <div v-html="businessConfigurations.termsOfService"></div>
          <h2 class="text-h5 text-primary" ref="privacyTitle"
            >Privacy Policy</h2
          >
          <div v-html="businessConfigurations.privacyPolicy"></div>
        </q-scroll-area>
      </q-card>
    </template>
  </ftx-dialog>
</template>
<script>
export default {
  data() {
    return {
      position: 300,
      scrollAreaVisible: true,
      scrollAreaThumbStyle: {
        right: '4px',
        borderRadius: '5px',
        backgroundColor: '#b7b7b7',
        width: '5px',
        opacity: 1,
        zIndex: 9999,
      },
      scrollAreaBarStyle: {
        right: '2px',
        borderRadius: '9px',
        backgroundColor: '#e5e5e5',
        width: '9px',
        opacity: 1,
        zIndex: 9999,
      },
    }
  },
  computed: {
    properties() {
      return this.getDialogProperties('LegalDialog')
    },
  },
  methods: {
    onShow() {
      if (this.properties.isPrivacy) {
        this.$refs.scrollAreaRef.setScrollPosition(
          'vertical',
          this.$refs.privacyTitle.offsetTop,
          300
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.legal-dialog-card {
  max-width: 1023px;
  max-height: 90vh;
  border-radius: 7px;
  width: 100%;
}
</style>
