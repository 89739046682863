export const isDev =
  typeof process !== 'undefined' && process?.env?.DEV ? true : false

export const isSsr =
  typeof process !== 'undefined' && process?.env?.ssr ? true : false

export const API_ROOT_URI = process.env.API_ROOT_URI

export const commonMixinConstants = {
  lazyValidationRules: true,
  scrollAreaVisible: true,
  scrollAreaThumbStyle: {
    right: '4px',
    borderRadius: '5px',
    backgroundColor: '#b7b7b7',
    width: '5px',
    opacity: 1,
    zIndex: 9999,
  },
  scrollAreaBarStyle: {
    right: '2px',
    borderRadius: '9px',
    backgroundColor: '#e5e5e5',
    width: '9px',
    opacity: 1,
    zIndex: 9999,
  },
  // hideLocationTimeOut: 5000,
  hideLocationPopup: 3000,
  phoneMask: '(###)-###-####',
}

export const loyalNSaveLink = 'https://loyalnsave.com/how-it-works'

// export const categorySliderSettings = {
//   dots: false,
//   arrows: true,
//   infinite: true,
//   centerMode: true,
//   centerPadding: '20px',
//   variableWidth: true,
//   speed: 500,
//   slidesToShow: 6,
//   slidesToScroll: 1,
//   initialSlide: 0,
//   responsive: [
//     {
//       breakpoint: 1024,
//       settings: {
//         slidesToShow: 4,
//         slidesToScroll: 1,
//         infinite: true,
//       },
//     },
//     {
//       breakpoint: 600,
//       settings: {
//         slidesToShow: 3,
//         slidesToScroll: 1,
//         initialSlide: 1,
//       },
//     },
//     {
//       breakpoint: 480,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//       },
//     },
//   ],
// }

export const detailSwiperOption = {
  slidesPerView: 6,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    1920: {
      slidesPerView: 9,
    },
    1600: {
      slidesPerView: 7,
    },
    1440: {
      slidesPerView: 6,
    },
    1200: {
      slidesPerView: 5,
    },
    1024: {
      slidesPerView: 5,
    },
    768: {
      slidesPerView: 4,
    },
    640: {
      freeMode: true,
      slidesPerView: 3,
      loop: true,
      centeredSlides: true,
      spaceBetween: 10,
    },
    320: {
      freeMode: true,
      slidesPerView: 2,
      loop: true,
      centeredSlides: true,
      spaceBetween: 10,
    },
  },
}

export const swiperOptionTop = {
  loop: true,
  slidesPerView: 1,
  loopedSlides: 10, // looped slides should be the same
  spaceBetween: 10,
  touchRatio: 0.2,
  breakpoints: {
    599: {
      slidesPerView: 1,
      pagination: {
        el: '.swiper-pagination',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    },
    320: {
      slidesPerView: 1,
      pagination: {
        el: '.swiper-pagination',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    },
  },
}

export const swiperOptionThumbs = {
  loop: false,
  slidesPerView: 7,
  loopedSlides: 7,
  initialSlide: 0,
  centeredSlides: false,
  // navigation: {
  //   nextEl: '.swiper-button-next',
  //   prevEl: '.swiper-button-prev',
  // },
  breakpoints: {
    1200: {
      slidesPerView: 7,
      direction: 'vertical',
      slideToClickedSlide: true,
      loopedSlides: 7,
    },
    1023: {
      slidesPerView: 4,
      slideToClickedSlide: true,
      loopedSlides: 4,
    },
    599: {
      slidesPerView: 3,
      loopedSlides: 3,
    },
  },
}

// export const swiperOption = {
//   slidesPerView: 6,
//   slidesPerGroup: 5,
//   navigation: {
//     nextEl: '.swiper-button-next',
//     prevEl: '.swiper-button-prev',
//   },
//   breakpoints: {
//     1920: {
//       slidesPerView: 9,
//       slidesPerGroup: 8,
//     },
//     1600: {
//       slidesPerView: 7,
//       slidesPerGroup: 6,
//     },
//     1440: {
//       slidesPerView: 6,
//       slidesPerGroup: 5,
//     },
//     1200: {
//       slidesPerView: 6,
//       slidesPerGroup: 5,
//     },
//     1024: {
//       slidesPerView: 5,
//       slidesPerGroup: 4,
//     },
//     768: {
//       slidesPerView: 4,
//       slidesPerGroup: 3,
//     },
//     640: {
//       freeMode: true,
//       slidesPerView: 3,
//       slidesPerGroup: 2,
//       loop: true,
//       centeredSlides: true,
//       spaceBetween: 10,
//     },
//     320: {
//       freeMode: true,
//       slidesPerView: 2,
//       slidesPerGroup: 2,
//       loop: true,
//       centeredSlides: true,
//       spaceBetween: 10,
//     },
//   },
// }

// export const products = [
//   {
//     productID: '3ba56ae5-dd10-4372-993f-b99fe0310a28',
//     title: 'Wonder Nation Girls No Show Socks, 10 Pairs',
//     shortDescription:
//       "Girls always want to be like mom, so why not put them in something that is comfortable enough for you and durable enough to keep up with her and her busy play schedule. Wonder Nation girls' socks are the best of both worlds.",
//     images: [],
//     pageTitle: 'Wonder Nation Girls No Show Socks, 10 Pairs',
//     metaDescription: 'Wonder Nation Girls No Show Socks, 10 Pairs',
//     pageURL: 'http://polo',
//     compareAtPrice: 32,
//     price: 39,
//     hasDiscount: false,
//     discounts: [],
//     isFavorite: false,
//     favoriteID: null,
//     fulFillments: [
//       {
//         fulfillmentMethodID: '0b7fb340-32e3-4e6d-93e8-b03458baafd4',
//         displayName: 'In-Store Pickup',
//         codeName: 'is',
//         available: true,
//       },
//       {
//         fulfillmentMethodID: '885cb3cf-e30e-4fad-85de-4c6c0e8fdfa0',
//         displayName: 'Curbside Pickup',
//         codeName: 'cs',
//         available: true,
//       },
//       {
//         fulfillmentMethodID: '47909038-cb0f-4360-99f0-67ae54fa31ff',
//         displayName: 'Standard Delivery',
//         codeName: 'sd',
//         available: true,
//       },
//       {
//         fulfillmentMethodID: '169aace8-daa6-4a3f-ba51-53bdcd9e68d0',
//         displayName: 'Delivery',
//         codeName: 'scd',
//         available: true,
//       },
//     ],
//   },
//   {
//     productID: '3cf5f8ee-cbf6-48ae-ae8b-2554e7fa245b',
//     title: "Athletic Works Sustainable Men's Mesh Boxer Briefs, 3-pack",
//     shortDescription:
//       'Experience all day comfort with Athletic Works Sustainable Men’s Mesh 6" Boxer Briefs. ',
//     images: [],
//     pageTitle: "Athletic Works Sustainable Men's Mesh Boxer Briefs, 3-pack",
//     metaDescription:
//       "Athletic Works Sustainable Men's Mesh Boxer Briefs, 3-pack",
//     pageURL: 'http://polo',
//     compareAtPrice: 49,
//     price: 56,
//     hasDiscount: false,
//     discounts: [],
//     isFavorite: false,
//     favoriteID: null,
//     fulFillments: [
//       {
//         fulfillmentMethodID: '0b7fb340-32e3-4e6d-93e8-b03458baafd4',
//         displayName: 'In-Store Pickup',
//         codeName: 'is',
//         available: true,
//       },
//       {
//         fulfillmentMethodID: '885cb3cf-e30e-4fad-85de-4c6c0e8fdfa0',
//         displayName: 'Curbside Pickup',
//         codeName: 'cs',
//         available: true,
//       },
//       {
//         fulfillmentMethodID: '47909038-cb0f-4360-99f0-67ae54fa31ff',
//         displayName: 'Standard Delivery',
//         codeName: 'sd',
//         available: true,
//       },
//       {
//         fulfillmentMethodID: '169aace8-daa6-4a3f-ba51-53bdcd9e68d0',
//         displayName: 'Delivery',
//         codeName: 'scd',
//         available: true,
//       },
//     ],
//   },
//   {
//     productID: '4e3589e7-38c3-493e-9e5a-21c16e6720bf',
//     title: "Athletic Works Sustainable Men's Mesh Boxer Briefs, 3-pack",
//     shortDescription:
//       'Experience all day comfort with Athletic Works Sustainable Men’s Mesh 6" Boxer Briefs. ',
//     images: [],
//     pageTitle: "Athletic Works Sustainable Men's Mesh Boxer Briefs, 3-pack",
//     metaDescription:
//       "Athletic Works Sustainable Men's Mesh Boxer Briefs, 3-pack",
//     pageURL: 'http://polo',
//     compareAtPrice: 19,
//     price: 26,
//     hasDiscount: false,
//     discounts: [],
//     isFavorite: false,
//     favoriteID: null,
//     fulFillments: [
//       {
//         fulfillmentMethodID: '0b7fb340-32e3-4e6d-93e8-b03458baafd4',
//         displayName: 'In-Store Pickup',
//         codeName: 'is',
//         available: true,
//       },
//       {
//         fulfillmentMethodID: '885cb3cf-e30e-4fad-85de-4c6c0e8fdfa0',
//         displayName: 'Curbside Pickup',
//         codeName: 'cs',
//         available: true,
//       },
//       {
//         fulfillmentMethodID: '47909038-cb0f-4360-99f0-67ae54fa31ff',
//         displayName: 'Standard Delivery',
//         codeName: 'sd',
//         available: true,
//       },
//       {
//         fulfillmentMethodID: '169aace8-daa6-4a3f-ba51-53bdcd9e68d0',
//         displayName: 'Delivery',
//         codeName: 'scd',
//         available: true,
//       },
//     ],
//   },
//   {
//     productID: '5aafbd0d-9bfe-43eb-a890-73ece443ab6b',
//     title: 'Wonder Nation Girls No Show Socks, 10 Pairs',
//     shortDescription:
//       "Girls always want to be like mom, so why not put them in something that is comfortable enough for you and durable enough to keep up with her and her busy play schedule. Wonder Nation girls' socks are the best of both worlds.",
//     images: [],
//     pageTitle: 'Wonder Nation Girls No Show Socks, 10 Pairs',
//     metaDescription: 'Wonder Nation Girls No Show Socks, 10 Pairs',
//     pageURL: 'http://polo',
//     compareAtPrice: 38,
//     price: 45,
//     hasDiscount: false,
//     discounts: [],
//     isFavorite: false,
//     favoriteID: null,
//     fulFillments: [
//       {
//         fulfillmentMethodID: '0b7fb340-32e3-4e6d-93e8-b03458baafd4',
//         displayName: 'In-Store Pickup',
//         codeName: 'is',
//         available: true,
//       },
//       {
//         fulfillmentMethodID: '885cb3cf-e30e-4fad-85de-4c6c0e8fdfa0',
//         displayName: 'Curbside Pickup',
//         codeName: 'cs',
//         available: true,
//       },
//       {
//         fulfillmentMethodID: '47909038-cb0f-4360-99f0-67ae54fa31ff',
//         displayName: 'Standard Delivery',
//         codeName: 'sd',
//         available: true,
//       },
//       {
//         fulfillmentMethodID: '169aace8-daa6-4a3f-ba51-53bdcd9e68d0',
//         displayName: 'Delivery',
//         codeName: 'scd',
//         available: true,
//       },
//     ],
//   },
//   {
//     productID: '77b54d7a-3e21-4300-9be8-6d7388852508',
//     title: 'Wonder Nation Girls Unicorn No Show Socks, 6-Pack',
//     shortDescription:
//       "Fill up the sock drawer with these magical Wonder Nation Girls' Unicorn No Show Socks. Featuring colorful unicorn designs crafted in a soft polyester/spandex blend, these socks are as comfortable as they are cute.",
//     images: [],
//     pageTitle: 'Wonder Nation Girls Unicorn No Show Socks, 6-Pack',
//     metaDescription: 'Wonder Nation Girls Unicorn No Show Socks, 6-Pack',
//     pageURL: 'http://polo',
//     compareAtPrice: 38,
//     price: 45,
//     hasDiscount: false,
//     discounts: [],
//     isFavorite: false,
//     favoriteID: null,
//     fulFillments: [
//       {
//         fulfillmentMethodID: '0b7fb340-32e3-4e6d-93e8-b03458baafd4',
//         displayName: 'In-Store Pickup',
//         codeName: 'is',
//         available: true,
//       },
//       {
//         fulfillmentMethodID: '885cb3cf-e30e-4fad-85de-4c6c0e8fdfa0',
//         displayName: 'Curbside Pickup',
//         codeName: 'cs',
//         available: true,
//       },
//       {
//         fulfillmentMethodID: '47909038-cb0f-4360-99f0-67ae54fa31ff',
//         displayName: 'Standard Delivery',
//         codeName: 'sd',
//         available: true,
//       },
//       {
//         fulfillmentMethodID: '169aace8-daa6-4a3f-ba51-53bdcd9e68d0',
//         displayName: 'Delivery',
//         codeName: 'scd',
//         available: true,
//       },
//     ],
//   },
//   {
//     productID: '7bf232a2-fbaa-470f-ad78-c07aa92df31e',
//     title: 'Wonder Nation Girls Unicorn No Show Socks, 6-Pack',
//     shortDescription:
//       "Fill up the sock drawer with these magical Wonder Nation Girls' Unicorn No Show Socks. Featuring colorful unicorn designs crafted in a soft polyester/spandex blend, these socks are as comfortable as they are cute.",
//     images: [],
//     pageTitle: 'Wonder Nation Girls Unicorn No Show Socks, 6-Pack',
//     metaDescription: 'Wonder Nation Girls Unicorn No Show Socks, 6-Pack',
//     pageURL: 'http://polo',
//     compareAtPrice: 52,
//     price: 59,
//     hasDiscount: false,
//     discounts: [],
//     isFavorite: false,
//     favoriteID: null,
//     fulFillments: [
//       {
//         fulfillmentMethodID: '0b7fb340-32e3-4e6d-93e8-b03458baafd4',
//         displayName: 'In-Store Pickup',
//         codeName: 'is',
//         available: true,
//       },
//       {
//         fulfillmentMethodID: '885cb3cf-e30e-4fad-85de-4c6c0e8fdfa0',
//         displayName: 'Curbside Pickup',
//         codeName: 'cs',
//         available: true,
//       },
//       {
//         fulfillmentMethodID: '47909038-cb0f-4360-99f0-67ae54fa31ff',
//         displayName: 'Standard Delivery',
//         codeName: 'sd',
//         available: true,
//       },
//       {
//         fulfillmentMethodID: '169aace8-daa6-4a3f-ba51-53bdcd9e68d0',
//         displayName: 'Delivery',
//         codeName: 'scd',
//         available: true,
//       },
//     ],
//   },
//   {
//     productID: '7f167749-eb70-441d-ade9-cbb416d2808a',
//     title: "Athletic Works Sustainable Men's Mesh Boxer Briefs, 3-pack",
//     shortDescription:
//       'Experience all day comfort with Athletic Works Sustainable Men’s Mesh 6" Boxer Briefs. ',
//     images: [],
//     pageTitle: "Athletic Works Sustainable Men's Mesh Boxer Briefs, 3-pack",
//     metaDescription:
//       "Athletic Works Sustainable Men's Mesh Boxer Briefs, 3-pack",
//     pageURL: 'http://polo',
//     compareAtPrice: 9,
//     price: 16,
//     hasDiscount: false,
//     discounts: [],
//     isFavorite: false,
//     favoriteID: null,
//     fulFillments: [
//       {
//         fulfillmentMethodID: '0b7fb340-32e3-4e6d-93e8-b03458baafd4',
//         displayName: 'In-Store Pickup',
//         codeName: 'is',
//         available: true,
//       },
//       {
//         fulfillmentMethodID: '885cb3cf-e30e-4fad-85de-4c6c0e8fdfa0',
//         displayName: 'Curbside Pickup',
//         codeName: 'cs',
//         available: true,
//       },
//       {
//         fulfillmentMethodID: '47909038-cb0f-4360-99f0-67ae54fa31ff',
//         displayName: 'Standard Delivery',
//         codeName: 'sd',
//         available: true,
//       },
//       {
//         fulfillmentMethodID: '169aace8-daa6-4a3f-ba51-53bdcd9e68d0',
//         displayName: 'Delivery',
//         codeName: 'scd',
//         available: true,
//       },
//     ],
//   },
// ]

// export const collections = [
//   {
//     collectionId: 1,
//     collectionName: 'Featured Products',
//     products: [...products.slice(0, 10)].sort(() => Math.random() - 0.5),
//   },
//   {
//     collectionId: 2,
//     collectionName: 'Top Delivered Items/Top Picked',
//     products: [...products.slice(0, 10)].sort(() => Math.random() - 0.5),
//   },
//   {
//     collectionId: 3,
//     collectionName: 'Best Seller in "Winchester north"',
//     products: [...products.slice(0, 10)].sort(() => Math.random() - 0.5),
//   },
// ]

export const mobileFooterLinks = [
  {
    id: 'shop',
    title: 'Shop',
    icon: 'home-o',
    link: 'Home',
  },
  {
    id: 'cart',
    title: 'Cart',
    icon: 'cart',
    link: 'Cart',
    counter: true,
  },
  {
    id: 'deals',
    title: 'Deals',
    icon: 'deals-o',
    link: 'DiscountDetail',
    isComingSoon: false,
  },
  {
    id: 'loyalty',
    title: 'Loyalty',
    icon: 'loyalty-o',
    checkGetter: 'common/storeHasLnsSubscription',
  },
  {
    id: 'account',
    title: 'Account',
    icon: 'avtar',
    link: 'Account',
  },
]

// export const LNSNavLinks = [
//   {
//     title: 'Loyalty Program',
//     id: 'LoyaltyDashboard',
//     icon: 'loyalty-dashboard',
//     link: '',
//   },
//   {
//     title: 'Deals',
//     id: 'Deals',
//     icon: 'loyalty-deals',
//     link: '',
//   },
//   {
//     title: 'Coupons',
//     id: 'Coupons',
//     icon: 'loyalty-coupons',
//     link: '',
//   },
//   {
//     title: "MFG's",
//     id: 'Mfgs',
//     icon: 'loyalty-mfgs',
//     link: '',
//   },
//   {
//     title: 'Swag Shop',
//     id: 'SwagShop',
//     icon: 'loyalty-swagshop',
//     link: '',
//   },
//   {
//     title: 'Friends',
//     id: 'Friends',
//     icon: 'loyalty-friends',
//     link: '',
//   },
//   {
//     title: 'Messages',
//     id: 'Messages',
//     icon: 'loyalty-messages',
//     link: '',
//   },
//   {
//     title: 'Receipts',
//     id: 'Receipts',
//     icon: 'loyalty-receipts',
//     link: '',
//   },
//   {
//     title: 'Help',
//     id: 'Help',
//     icon: 'loyalty-help',
//     link: '',
//   },
//   {
//     title: 'Team',
//     id: 'Team',
//     icon: 'loyalty-teams',
//     link: '',
//   },
//   {
//     title: 'Reviews',
//     id: 'Reviews',
//     icon: 'loyalty-reviews',
//     link: '',
//   },
// ]

export const ageRestrictedBrowsingPages = [
  'Home',
  'Category',
  'Collection',
  'Search',
]

export const ageRestrictedNonBrowsingPages = [
  'Account',
  'Checkout',
  'OrderEdit',
]

export const PaymentGateways = {
  DATACAP: 'DATACAP',
  USAEPAY: 'USAEPAY',
}

export const testModePaymentMessage =
  'Currently, the store is under maintenance so you can’t place the order.'

export const cartDrawerPinedPage = [
  'Home',
  'Detail',
  'Search',
  'Collection',
  'Category',
  'Favorites',
  'Wishlist',
  'DiscountDetail',
]

export const swiperBreakpoints = {
  1920: {
    elements: 9,
  },
  1600: {
    elements: 7,
  },
  1440: {
    elements: 6,
  },
  1280: {
    elements: 6,
  },
  1024: {
    elements: 5,
  },
  800: {
    elements: 4,
  },
  768: {
    elements: 4,
  },
  640: {
    elements: 3,
  },
  580: {
    elements: 3,
  },
  450: {
    elements: 2,
  },
  320: {
    elements: 2,
  },
}
