export const getDefaultState = () => {
  return {
    // List of Discount Data
    page: 1,
    pageSize: 10,
    hasMoreDiscount: false,
    discounts: {},
    loading: true,
    loadingMore: false,
    // for Discount page
    currentDiscount: null,
    loadingCurrentDiscount: false,
    products: {},
  }
}

export default getDefaultState()
