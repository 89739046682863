export function SET_CATEGORIES(state, categories) {
  state.categories = categories
}

export function SET_TOTAL_COUNT(state, count) {
  state.totalProductsCount = count
}

export function SET_TRENDING_CATEGORY(state, categories) {
  state.trendingCategories = categories
}
export function SET_LOADING_TRENDING_CATEGORY(state, loading) {
  state.loadingTrendingCategories = loading
}

export function SET_CATEGORY(state, category) {
  if (category) state.currentCategory = Object.assign({}, category)
  else state.currentCategory = null
}

export function SET_LOADING_CATEGORY(state, loading) {
  state.loadingCategory = loading
}

export function SET_LOADING_CATEGORIES(state, loading) {
  state.loadingCategories = loading
}

export function SET_LOADING_Filters(state, loading) {
  state.loadingFilters = loading
}

export function SET_PRODUCTS(state, products) {
  state.products = Object.assign({}, products)
}

export function ADD_PRODUCTS(state, products) {
  state.products = Object.assign({}, state.products, products)
}

export function ADD_FILTER(state, filter) {
  state.filters = Object.assign({}, state.filters, filter)
}

export function INIT_FILTER(state, filter) {
  state.filters = Object.assign({})
}

export function SET_SELECTED_SEARCH_CATEGORY(state, category = null) {
  state.selectedSearchCategory = category
}
