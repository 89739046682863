import { render, staticRenderFns } from "./AccountCloseConfirm.vue?vue&type=template&id=ce832cd8"
import script from "./AccountCloseConfirm.vue?vue&type=script&lang=js"
export * from "./AccountCloseConfirm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QCardSection,QSpace,QBtn,QSelect,QCheckbox});qInstall(component, 'directives', {ClosePopup});
