import LogRocket from 'logrocket'

const { VUE_APP_BUILD_NUM } = process.env,
  { LOG_ROCKET_APP_ID } = process.env

let setUserToLogRocket

export default async ({ Vue, store }) => {
  if (!LOG_ROCKET_APP_ID) return

  LogRocket.init(LOG_ROCKET_APP_ID, {
    release: VUE_APP_BUILD_NUM || '',
    shouldParseXHRBlob: true,
    network: {
      requestSanitizer: (request) => {
        if (request.url.includes('smartlook.cloud')) return null
        return request
      },
    },
  })

  Vue.config.errorHandler = (err, vm, info) => {
    LogRocket.captureException(err, {
      extra: { vm, info },
    })
  }

  setUserToLogRocket = () => {
    const isLoggedIn = store.getters['auth/isLoggedIn'],
      customerExists = store.getters['auth/customerExists']

    if (isLoggedIn && customerExists) {
      const currentUserIdNumber =
          store.getters['auth/currentUser']?.userId || '',
        currentUserIdentity = store.getters['auth/currentUserIdentity'],
        currentUserName = store.getters['auth/currentUserName']

      LogRocket.identify(currentUserIdNumber, {
        name: currentUserName,
        email: currentUserIdentity,

        // Add your own custom user variables here, ie:
        // subscriptionType: 'pro'
      })
    } else LogRocket.identify('anonymous')
  }
}

export { setUserToLogRocket }
