<template>
  <q-dialog
    ref="dialog"
    :position="isMobile ? 'bottom' : 'standard'"
    @hide="onDialogHide"
    :maximized="isMobile"
    :no-backdrop-dismiss="!isMobile"
    no-esc-dismiss
  >
    <q-card style="width: 100%" class="simpleDialog">
      <!-- <q-btn
        icon="close"
        flat
        round
        dense
        v-close-popup
        class="absolute-top-right q-mt-md q-mr-md"
      /> -->
      <div class="container">
        <div class="row q-col-gutter-md justify-center text-center">
          <div class="col-12">
            <span class="avatar-absolute rounded-borders shadow-3 q-pa-md">
              <!-- <q-img
                v-if="theme"
                :src="theme.logoURL"
                style="max-width: 200px; max-height: 40px;"
              /> -->
              <img
                v-if="theme && theme.logoURL"
                style="max-width: 100%; width: auto; max-height: 50px"
                :src="theme.logoURL"
                class="inline-block vertical-middle"
              />
            </span>
            <q-icon
              name="link"
              size="28px"
              color="primaryOnBody"
              class="q-mt-md rotate-90"
            />
          </div>
          <div class="col-12">
            <div class="row q-col-gutter-md items-center justify-center">
              <div class="col-auto">
                <q-btn round class="no-hover" size="xl">
                  <UserAvatar size="60px" font-size="24px" />
                  <!--  When used characters: color="grey" text-color="dark" -->
                </q-btn>
              </div>
              <div class="col-auto">
                <h2 class="q-pt-none" v-text="`Hello, ${currentUserName}`"></h2>
                <p>
                  <q-chip>
                    <q-icon name="person" class="q-mr-sm" />
                    {{ currentUserIdentity }}
                  </q-chip>
                </p>
              </div>
            </div>
          </div>
          <div class="col-12"> </div>
          <div class="col-12">
            <p>
              Would you like to continue to
              {{ businessConfigurations.businessName }} with the logged in
              account?
            </p>
            <div class="text-left q-mb-none text-body1 q-mt-md">
              <q-checkbox
                dense
                v-model="isPolicyAccepted"
                @input="toggleLegal"
                class="q-mr-xs"
              />
              <span class="inline-block vertical-middle">
                I agree to the
                <span
                  class="link-primary text-underline cursor-pointer"
                  @click="
                    changeDialogState({
                      dialog: 'LegalDialog',
                      val: true,
                    })
                  "
                  >Terms of Service</span
                >
                and
                <span
                  @click="
                    changeDialogState({
                      dialog: 'LegalDialog',
                      val: true,
                      properties: {
                        isPrivacy: true,
                      },
                    })
                  "
                  class="link-primary text-underline cursor-pointer"
                >
                  Privacy Policy </span
                >.
              </span>
            </div>
            <div
              v-if="isLegalAgreementUncheck"
              class="text-negative text-caption text-left q-mb-none"
              >You must agree to our Terms of Service and Privacy Policy.</div
            >
            <div
              :class="
                !isMobile
                  ? 'row q-col-gutter-md q-mt-lg'
                  : 'row q-col-gutter-md q-mt-xs'
              "
            >
              <div class="col-6 col-md-6 text-left">
                <div class="form-action">
                  <q-btn
                    unelevated
                    no-caps
                    v-close-popup
                    color="tertiary"
                    label="No, Logout"
                    class="q-btn-lg full-width"
                    @click="performLogout"
                    :disable="submitting"
                    :loading="logoutInitiated"
                  />
                </div>
              </div>
              <div class="col-6 col-md-6 text-right">
                <div class="form-action">
                  <q-btn
                    unelevated
                    no-caps
                    color="primary"
                    label="Yes, Sure"
                    class="q-btn-lg full-width"
                    @click="onOKClick"
                    :loading="submitting"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </q-card>
    <LegalDialog />
  </q-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import LegalDialog from 'src/components/dialog/LegalDialog.vue'
export default {
  name: 'SignUpConfirm',
  computed: {
    ...mapGetters('theme', ['theme']),
    ...mapGetters('auth', ['accessToken']),
    ...mapGetters('persisted', [
      'eaivPersistedSessionId',
      'eaivPersistedVerificationSuccess',
    ]),
    ...mapGetters('ageVerification', [
      'isVerificationFail',
      'showVeirificationPopup',
    ]),
    ...mapGetters('customer', ['customer']),
    eaivSessionId() {
      return this.$route.query?.sessionId
    },
  },
  data() {
    return {
      submitting: false,
      isPolicyAccepted: false,
      isLegalAgreementUncheck: false,
    }
  },
  components: {
    LegalDialog,
  },
  methods: {
    toggleLegal() {
      this.isPolicyAccepted
        ? (this.isLegalAgreementUncheck = false)
        : (this.isLegalAgreementUncheck = true)
    },
    // following method is REQUIRED
    // (don't change its name --> "show")
    show() {
      // var base64Url = this.accessToken.split('.')[1]
      // var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      // var decodedJwtToken = decodeURIComponent(
      //   window
      //     .atob(base64)
      //     .split('')
      //     .map(function (c) {
      //       return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      //     })
      //     .join('')
      // )

      // decodedJwtToken = JSON.parse(decodedJwtToken)
      // console.log(decodedJwtToken.cst)
      // if (decodedJwtToken && !decodedJwtToken.cst) {
      //   // do logout
      //   // this.$store.dispatch('auth/logout')
      // }
      this.$refs.dialog.show()
    },

    // following method is REQUIRED
    // (don't change its name --> "hide")
    hide() {
      this.$refs.dialog.hide()
    },

    onDialogHide() {
      // required to be emitted
      // when QDialog emits "hide" event
      this.$emit('hide')
    },

    async onOKClick() {
      // on OK, it is REQUIRED to
      // emit "ok" event (with optional payload)
      // before hiding the QDialog
      if (!this.isPolicyAccepted) {
        this.isLegalAgreementUncheck = true
        return
      }
      this.submitting = true
      try {
        let response = await this.$store.dispatch('auth/signUpAsCustomer', {
          isPolicyAccepted: true,
        })
        if (!response.success) {
          return this.showError('Something went wrong!')
        }

        // check for age verification if already verified before login
        await this.$store.dispatch('customer/getCustomer')
        if (
          !this.customer.isVerifiedOnline &&
          this.eaivPersistedSessionId &&
          this.businessConfigurations?.isStoreAgeRestricted &&
          this.businessConfigurations?.enableAgeRestrictedBrowsing
        ) {
          await this.$store.dispatch(
            'customer/mapAgeVerification',
            this.eaivPersistedSessionId
          )
        }
      } catch (e) {
        return this.showError('Something went wrong!')
      } finally {
        this.submitting = false
      }

      if (this.currentLocationId)
        this.$store.dispatch('persisted/setServiceLocation', {
          location: this.currentLocation,
          fulfillment: this.selectedFulfillment,
        })

      this.$emit('ok')
      // or with payload: this.$emit('ok', { ... })

      // then hiding dialog
      this.hide()
    },

    onCancelClick() {
      // we just need to hide dialog
      this.hide()
    },
  },
}
</script>
