export default (image = '', imageType, store) => {
  let theme = store.getters['theme/theme']
  let defaultProductImageURL =
    image ||
    (theme && theme.defaultProductImageURL
      ? theme.defaultProductImageURL
      : imageType == 'Large'
      ? 'images/no-image-500.png'
      : 'images/no-image-200.png')

  return defaultProductImageURL
}
