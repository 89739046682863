export const getDefaultState = () => {
  return {
    // for home page
    trendingCategories: [],
    loadingTrendingCategories: true,
    categories: [],
    selectedSearchCategory: null,
    // for Category page
    currentCategory: null,
    loadingCategory: true,
    loadingCategories: true,
    products: {},
    totalProductsCount: 0,
    parameters: {
      sortBy: 'Featured',
    },
    sortByOptions: [
      { value: 'Price Low to High', label: 'Price Low to High' },
      { value: 'Price High to Low', label: 'Price High to Low' },
      { value: 'Best Sellers', label: 'Best Sellers' },
      { value: 'Customer Reviews', label: 'Customer Reviews (Ratings)' },
    ],
    filters: {},
    loadingFilters: false,
  }
}
export default getDefaultState()
