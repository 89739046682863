export default function () {
  return {
    cartDetail: {},
    cartNumber: null,
    loading: true,
    currentOrderParameters: {
      orderNumber: null,
      paymentMethodId: null,
      orderFulfillments: [],
      tipAmount: null,
    },
    checkoutInReviewMode: false,
    substituteDetail: {},
    recentSubstituteDetail: {},
    alertDetail: {},
    outOfStockProducts: {},
    isPayAtStoreAvailable: {
      is: false,
      cs: false,
      scd: false,
      sd: false,
    },
    shippingRates: {},
    alertData: {},
    allowPlaceOrder: true,
    isTipConfirmed: false,
    checkoutSelection: null,
  }
}
