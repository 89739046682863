export const getLocationById = (state) => (locationId) =>
  state.locations[locationId]

export const location = (state) => state.location

export const milesOptions = (state) => state.milesOptions

export const loading = (state) => state.loading

export const allowPayAtStoreForFulfillment = (state) => {
  return (fulfillmentCode) => {
    let allowPayAtStore = false
    state.location?.fulFillments.every(function (fulfillment) {
      if (fulfillment.codeName == fulfillmentCode) {
        allowPayAtStore = fulfillment.allowPayAtStore
        return false
      }
      return true
    })
    return allowPayAtStore
  }
}
