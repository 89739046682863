export const trendingCategories = (state) => state.trendingCategories || []

export const loadingTrendingCategories = (state) =>
  state.loadingTrendingCategories

export const searchCategory = (state) =>
  state.selectedSearchCategory || { categoryID: '', categoryName: 'All' }

export const totalCount = (state) => state.totalProductsCount

export const sortByOptions = (state) => state.sortByOptions

export const categories = (state) => state.categories

export const currentCategory = (state) => state.currentCategory

export const loadingCategory = (state) => state.loadingCategory

export const loadingCategories = (state) => state.loadingCategories

export const loadingFilters = (state) => state.loadingFilters

export const products = (state) => state.products

export const parameters = (state) => state.parameters

export function getFilterByCategoryId(state) {
  return (categoryId) => state.filters[categoryId]
}

export function getCategoryTreeInfoById(state, getters) {
  return (categoryId) => {
    let categories = getters['categories']
    return searchTree(categories, categoryId)
  }
}

export function searchTree(
  categories,
  categoryId,
  parentCategory,
  parentsOfSelected = []
) {
  let parent = parentCategory
  let parents = [...parentsOfSelected]

  for (let category of categories) {
    if (!parentCategory) {
      parent = category
    }

    parents.push(category.categoryID)

    if (category.categoryID == categoryId) {
      return {
        parent,
        parentsOfSelected,
      }
    } else if (category.hasChild) {
      let result = searchTree(
        category.childCategory,
        categoryId,
        parent,
        parents
      )

      if (result) {
        return result
      }
    }

    parents.pop()
  }
}

export function getCategoryById(state, getters) {
  return (categoryId = null, seoName = null) => {
    let categories = getters['categories']
    return searchCategoryInTree(categories, categoryId, seoName)
  }
}

export function searchCategoryInTree(
  categories,
  categoryId = null,
  seoName = null
) {
  for (let category of categories) {
    if (
      (categoryId && category.categoryID == categoryId) ||
      (seoName && category.seoName == seoName)
    ) {
      return category
    } else if (category.hasChild) {
      let result = searchCategoryInTree(
        category.childCategory,
        categoryId,
        seoName
      )

      if (result) {
        return result
      }
    }
  }
}
