import { Platform } from 'quasar'

export async function fetchLocation(
  { commit, getters, state },
  { locationId, isLocationDetailPage = false }
) {
  const setHeaderTitle = (location) => {
    if (Platform?.is?.mobile)
      commit(
        'common/SET_HEADER_TITLE',
        { title: location.name },
        { root: true }
      )
  }

  if (state.location && locationId == state.location.locationId) {
    isLocationDetailPage && setHeaderTitle(state.location)
    return state.location
  }

  var location = getters.getLocationById(locationId)
  if (location) {
    isLocationDetailPage && commit('SET_LOCATION', location)
    return location
  }

  isLocationDetailPage && commit('SET_LOADING', true)

  try {
    let { data } = await this.$axiosInstance.get(
      `/Location/Detail/${locationId}`,
      {
        params: {
          IncludeConfiguration: true,
        },
      }
    )

    if (data.data) isLocationDetailPage && setHeaderTitle(data.data)

    isLocationDetailPage && commit('SET_LOCATION', data.data)
    commit('ADD_LOCATION', data.data)
    commit('SET_LOADING', false)

    return data.data
  } catch (error) {}
}

export async function getNearBYLocation({}, params) {
  try {
    let { data } = await this.$axiosInstance.get('/location', { params })
    return data
  } catch (error) {
    return false
  }
}
