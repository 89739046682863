export function PUSH_PRODUCT_DETAIL(state, product) {
  if (product && product.productID) {
    state.seoMapping = Object.assign(
      {},
      { [product.seoName]: product.productID }
    )
    state.details = Object.assign({}, state.details, {
      [product.productID]: product,
    })
  }
}

export function PUSH_COMPANION_PRODUCTS(state, { ProductSeoName, items }) {
  state.companionProducts = Object.assign({}, state.companionProducts, {
    [ProductSeoName]: items,
  })
}

export function SET_LOADING(state, loading) {
  state.loading = loading
}

export function UPDATE_PAGINATION(state, pagination) {
  state.pagination = pagination
}

export function RESET_PAGINATION(state) {
  state.pagination = {
    totalCount: 0,
    pageSize: 20,
    currentPage: 1,
  }
}

export function UPDATE_CURRENTSELECTEDPRODUCT_VARIANT(
  state,
  SelectedProductVariant
) {
  state.currentSelectedProductVariant = SelectedProductVariant
}
