<template>
  <ftx-dialog position="standard" persistent :dialog="dialog">
    <template v-slot:DialogContent>
      <q-card class="q-py-md accesscode-dialog">
        <q-card-section horizontal class="row q-col-gutter-lg items-center">
          <q-card-section class="col">
            <div class="row q-mb-lg">
              <div
                class=""
                :class="
                  $q.screen.lt.md ? 'col-12 text-center' : 'col-auto q-pr-md'
                "
              >
                <Logo />
              </div>
              <q-separator vertical size="3px" v-if="!$q.screen.lt.md" />
              <div class="col q-pl-md">
                <div
                  class="text-h6 text-black"
                  :class="$q.screen.lt.md ? 'text-center q-pt-md' : ''"
                  >You entering into <br /><strong class="text-h5"
                    >Test Mode</strong
                  ></div
                >
              </div>
            </div>
            <p class="q-mb-lg"> Enter password to access website. </p>
            <q-form @submit="submitCode" class="full-width">
              <q-input
                outlined
                unelevated
                no-caps
                dense
                autofocus
                v-model="accessCode"
                type="password"
                placeholder="Enter Password"
                autocomplete="off"
                class="col q-mb-md"
                ref="accessCodeInput"
              />
              <q-btn
                unelevated
                :disabled="!accessCode.length"
                color="secondaryOnBody"
                label="Continue to website"
                class="full-width q-py-xs"
                type="submit"
                :loading="loading"
                no-caps
              />
            </q-form>
          </q-card-section>

          <q-card-section class="q-pt-none">
            <div
              class="q-pa-md bg-grey-3 text-caption line-height-normal r-6 text-justify"
              ><!-- bg-orange-2 text-dark -->
              Our online store is temporarily limited to our internal team as we
              are working on improving your shopping experience with us. We
              appreciate your patience and understanding as we work through
              these changes. Stay tuned for updates!
            </div>
          </q-card-section>
        </q-card-section>
      </q-card>
    </template>
  </ftx-dialog>
</template>

<script>
import Logo from 'components/header/Logo.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'TestModeDialog',
  data() {
    return {
      dialog: 'TestModeDialog',
      accessCode: '',
      loading: false,
    }
  },
  components: {
    Logo,
  },
  computed: {
    ...mapGetters('persistedLocal', ['showAppDownloadNotification']),
    properties() {
      return this.getDialogProperties(this.dialog)
    },
    dialogState: {
      get() {
        return this.getDialogState(this.dialog)
      },
      set(val) {
        this.changeDialogState({
          dialog: this.dialog,
          val,
        })
      },
    },
  },
  methods: {
    submitCode() {
      this.loading = true
      this.$store
        .dispatch('common/validateAccessCode', {
          mode: 2,
          code: this.accessCode,
        })
        .then((response) => {
          if (response?.success && response?.data === true) {
            this.$store.commit('persisted/VALIDATE_ACCESS_CODE', response.data)
            this.showSuccess('Password verified successfully.')
            if (
              !this.isCordova &&
              this.showAppDownloadNotification &&
              !this.$q.platform.is.ios
            )
              this.$store.dispatch('common/getMobileAppData')
            this.updateDialogState({
              dialog: this.dialog,
              val: false,
            })
          } else if (response?.success && response?.data === false) {
            this.showError('Password not match. Please try again')
            this.accessCode = ''
            this.$refs.accessCodeInput.focus()
          } else if (response?.success === false) {
            if (response.errors?.length)
              this.showError(response.errors[0].descreption)
            else this.showError(response.message)
            this.accessCode = ''
            this.$refs.accessCodeInput.focus()
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.q-dialog__inner--maximized > div.accesscode-dialog,
.accesscode-dialog {
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    @media (min-width: 767px) {
      margin: 0;
      box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
      height: auto;
      max-height: calc(100vh - 48px);
      max-width: 600px;
      width: calc(100% - 30px);
      border-radius: 10px !important;
      padding: 45px;
    }
  }
  @media (max-width: 1023px) {
    > div {
      padding: 15px;
      max-width: 400px;
    }
  }
  .q-toolbar__title {
    cursor: default;
  }
  .text-h5 {
    font-size: 32px;
    @media (max-width: 1023px) {
      font-size: 22px;
    }
  }
  p {
    font-size: 16px;
  }
  .access-code-input {
    input {
      text-align: center;
    }
  }
  &-left {
    @media (max-width: 1023px) {
      display: none;
    }
  }
  .otp-group .otp-input {
    height: 44px;
  }
}
</style>
