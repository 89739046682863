import Vue from 'vue'
import { defaultState } from './state'

export function SET_SELECT_PRODUCT(state, productId) {
  let index = state.selectedproductid.indexOf(productId)
  index === -1
    ? state.selectedproductid.push(productId)
    : state.selectedproductid.splice(index, 1)
}

export function SET_WISHLIST(state, wishlist) {
  state.wishlist = Object.assign({}, wishlist)
}

export function SET_WISHLIST_COUNT(state, wishlistCount) {
  state.wishlistCount = wishlistCount
}

export function SET_TOTAL(state, intTotal) {
  state.totalCount = intTotal
}

export function ADD_WISHLIST(state, wishlist) {
  state.wishlist = Object.assign({}, state.wishlist, wishlist)
}

export function MARK_AS_WISHLIST(state, wishlist) {
  state.markedAsWishlist = Object.assign({}, state.markedAsWishlist, {
    ...wishlist,
  })
}

export function REMOVE_ITEM_FROM_WISHLIST(state, productId) {
  Vue.delete(state.wishlist, productId)
}
export function REMOVE_ALL_ITEM_FROM_WISHLIST(state, productIds) {
  for (let i = 0; i < productIds.length; i++) {
    Vue.delete(state.wishlist, productIds[i])
  }
}

export function RESET_STATE(state) {
  Object.assign(state, defaultState())
}
export function RESET_WISHLISTPRODUCT(state) {
  state.wishlist = {}
  state.totalCount = 0
}

export function SET_LOADING(state, loading) {
  state.loading = loading
}
export function ENABLE_ADD_TO_CART_WISHLIST(state, fulFillmentMethodID) {
  state.allowAddToCart = true
  state.selectedFulfillmentMethodID = fulFillmentMethodID
}

export function RESET_ADD_TO_CART_WISHLIST(state, fulFillmentMethodID) {
  state.allowAddToCart = false
  state.selectedFulfillmentMethodID = fulFillmentMethodID
}

export function SET_ALL_WISHLIST(state, wishlist) {
  state.allWishlist = Object.assign([], state.allWishlist, wishlist)
}
export function UPDATE_ALL_WISHLIST(state, wishlistId) {
  state.allWishlist.splice(
    state.allWishlist.findIndex(
      (wishlist) => wishlist.wishListID == wishlistId
    ),
    1
  )
}
