<template>
  <div
    :class="['template-collections q-mb-md']"
    v-if="component.collection?.seoName || isPreview"
  >
    <AgeRestrictedBlur :isPreview="isPreview" :component="component" />
    <div ref="collectionDiv">
      <div
        :class="[
          'row items-start q-product-slider',
          productsInRow == 0 ? 'no-products' : '',
        ]"
      >
        <!--<div class="col-12">
          <h2 class="text-h5 q-mt-auto">
            {{
              component.collectionTitle ||
              component.collection?.name ||
              'Select Collection'
            }}

            <a
              class="link-primaryOnBody text-underline"
              href="javascript:void(0);"
            >
              See all
            </a>
          </h2>
        </div>-->

        <Swiper
          :products="dummyProducts"
          :productsInRow="productsInRow"
          :title="
            component.collectionTitle ||
            component.collection?.name ||
            'Select Collection'
          "
          :seoName="component.collection?.seoName"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar'
import Swiper from 'components/Swiper'
import { swiperBreakpoints } from 'src/constants'

export default {
  name: 'Collection',
  components: {
    Swiper,
  },
  props: {
    component: {
      type: Object,
      default: () => ({}),
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      parentWidth: 0,
      dummyProducts: new Array(12).fill().map((e, i) => {
        return {
          productID: uid(),
          title: 'Example Product Title',
          profileImage: `product-placeholder-${i + 1 < 6 ? i + 1 : 1}.png`,
          isDummyProd: true,
        }
      }),
    }
  },
  methods: {
    onParentResize() {
      this.parentWidth = this.$refs.collectionDiv?.clientWidth || 0
    },
  },
  computed: {
    productsInRow() {
      let currentWidth = 0
      if (this.parentWidth) {
        for (let screenWidth in swiperBreakpoints) {
          if (screenWidth > this.parentWidth) {
            break
          }
          currentWidth = screenWidth
        }
      }

      return currentWidth ? swiperBreakpoints[currentWidth].elements : 0
    },
    // canShowCollection() {
    //   return (
    //     !this.component.isAgeRestricted ||
    //     (this.component.isAgeRestricted &&
    //       (!this.storeAgeVerificationRequired ||
    //         (this.storeAgeVerificationRequired && this.isUserAgeVerified)))
    //   )
    // },
  },
  mounted() {
    this.parentWidth = this.$refs.collectionDiv?.clientWidth || 0

    if (this.$refs.collectionDiv)
      new ResizeObserver(this.onParentResize).observe(this.$refs.collectionDiv)
  },
}
</script>

<style lang="scss">
.customize-product-scroll {
  // overflow-y: hidden;
  // overflow-x: auto;
  &::-webkit-scrollbar {
    height: 9px;
  }

  &::-webkit-scrollbar-track {
    background: rgb(229, 229, 229);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(183, 183, 183);
    border: 2px solid rgb(229, 229, 229);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #3699ff;
  }
}
.customize-product-cards {
  margin-left: -4px;
  margin-right: -4px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  .product-card {
    flex: 1 0 0;
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
    padding: 4px;
  }
  .product-card--inner {
    background: #fff;
    border: 1px solid #eee;
    padding: 8px;
    font-size: 16px;
    border-radius: 8px;

    .price {
      color: #000;
      font-weight: bold;
    }
  }
  .product-card--detail {
    padding: 8px 0;
  }
  .product-card--name {
    // min-height: 60px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  &.column-2 {
    .product-card {
      flex: 0 0 50%;
    }
  }
  &.column-3 {
    .product-card {
      flex: 0 0 33.33%;
    }
  }
  &.column-4 {
    .product-card {
      flex: 0 0 25%;
    }
  }
  &.column-5 {
    .product-card {
      flex: 0 0 20%;
    }
  }
  &.column-6 {
    .product-card {
      flex: 0 0 16.667%;
    }
  }
}
.template-collection {
  padding: 40px;
  // padding-bottom: 40px;
}
// .template-container__inner {
//   max-width: 1400px;
//   margin: 0 auto;
//   padding-left: 40px;
//   padding-right: 40px;
// }
</style>
