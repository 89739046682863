export function isProductMarkedAsWishlist(state) {
  return (productId, isWishlist) => {
    if (state.markedAsWishlist[productId]) {
      return state.markedAsWishlist[productId].isWish
    }

    return isWishlist
  }
}

export function isSubstitutionAllowed(state, getters) {
  let isSelected = false
}

export function categories(state) {
  return state.categories
}

export function wishlist(state) {
  return state.wishlist
}

export function defaultParams(state) {
  return state.defaultParams
}

export function wishlistItemsCount(state, getters) {
  let count = 0

  for (let key in state.wishlist) {
    let item = state.wishlist[key]

    if (getters.isProductMarkedAsWishlist(item.productID, item.isWished)) {
      count++
    }
  }

  return count
}

export function nextPageNumber(state, getters) {
  return (perPage) => {
    let count = getters.wishlistItemsCount

    return Math.floor(count / perPage) + 1
  }
}

export function totalCount(state) {
  return state.totalCount
}

export function loading(state) {
  return state.loading
}

export const sortByOptions = (state) => state.sortByOptions

export function loadingCategory(state) {
  return state.loadingCategory
}

export function allowAddToCart(state) {
  return state.allowAddToCart
}
export function selectedFulfillmentMethodID(state) {
  return state.selectedFulfillmentMethodID
}

export function allWishlistName(state) {
  return state.allWishlist
}
export function getWishlistNameByID(state) {
  return (wishlistID) => {
    for (let wishlistItem of state.allWishlist) {
      if (wishlistItem.wishListID == wishlistID)
        return wishlistItem.wishListName
    }
  }
}
