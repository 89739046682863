<template>
  <div
    ref="addToOrderBtn"
    :class="[
      'relative-position',
      'product-cart',
      showLoader ? 'product-cart-loading' : '',
    ]"
    v-if="isinstock"
  >
    <transition
      appear
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <span
        :class="['error text-center', 'top']"
        v-if="selectedFulFillment"
        v-show="
          cartQty >= selectedFulFillment.qtyLimit && (showMaxError || focus)
        "
      >
        Max {{ selectedFulFillment.qtyLimit }}
      </span>
    </transition>
    <div v-if="isItemInOrder" class="added-cart">
      <!-- Loader -->
      <q-inner-loading
        :showing="showLoader"
        style="background: rgb(0 0 0 / 32%); border-radius: 4px"
      >
        <q-spinner color="white" size="20px" :thickness="3" />
      </q-inner-loading>
      <!-- Loader -->
      <div class="icon hover" @click="isMobile && updateDialogState(true)">
        <SvgIcon size="1rem" class="delivery-mode" :icon="deliveryMode" />
      </div>
      <div class="btn">
        <q-btn
          @click="AddItemInOrder(-1)"
          round
          dense
          flat
          :icon="cartQty <= 1 ? 'delete' : 'remove'"
          size="0.6rem"
        />
        <q-input
          @blur="
            () => {
              this.focus = false
              if (this.cartQty != this.orderQty) this.AddItemInOrder(0)
            }
          "
          @focus="focus = true"
          class="text-center col-6 qtyGroup"
          rounded
          outlined
          v-mask="'####'"
          v-model="cartQty"
          dense
          @keyup.enter="$event.target.blur()"
        />
        <q-btn
          :class="[cartQty >= selectedFulFillment.qtyLimit ? 'disable' : '']"
          @click="AddItemInOrder(1)"
          round
          dense
          flat
          icon="add"
          size="0.6rem"
        />
      </div>
    </div>
    <div v-else :class="['add-cart ', 'addDelivery']">
      <!-- Loader -->
      <q-inner-loading
        :showing="showLoader"
        style="background: rgb(0 0 0 / 32%); border-radius: 4px"
      >
        <q-spinner color="white" size="20px" :thickness="3" />
      </q-inner-loading>
      <!-- Loader -->
      <!-- Need to add class when user added this item: addDelivery  -->
      <div
        class="icon"
        v-if="deliveryMode"
        @click="isItemHasSelectedFulFillmentOption && AddItemInOrder(1)"
      >
        <SvgIcon size="1rem" class="delivery-mode" :icon="deliveryMode" /> </div
      ><div class="btn" @click="AddItemInOrder(1)"> Add to Order </div>
    </div>
  </div>
  <div class="relative-position product-cart product-outstock" v-else>
    <span class="btn-outstock q-px-md">Out Of Stock</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AddToOrderBtn',
  props: {
    isinstock: {
      type: Boolean,
      default: true,
    },
    productFulFillments: {
      type: Array,
      default: () => {
        return new Array()
      },
    },
    productId: {
      type: String,
      default: () => {
        return ''
      },
    },
    applyCoupon: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      default: () => {
        return {}
      },
    },
    orderId: {
      type: String,
      default: () => {
        return ''
      },
    },
  },
  data() {
    return {
      quantity: null,
      showLoader: false,
    }
  },
  computed: {
    ...mapGetters('discounts', ['currentDiscount']),
    ...mapGetters('order', ['editOrderInfoById']),
    deliveryMode() {
      return this.selectedFulfillmentCodeName
    },
    selectedFulFillment() {
      for (let fulFillment of this.productFulFillments) {
        if (fulFillment.codeName == this.deliveryMode) {
          return fulFillment
        }
      }
      return false
    },
    orderInfo() {
      return this.editOrderInfoById(this.orderId)
    },
    orderItems() {
      let items = [...this.orderInfo.trackOrderLineItems]

      return items
    },
    isItemInOrder() {
      return [...this.orderItems].find((x) => x.productId === this.productId)
        ? true
        : false
    },
    orderQty() {
      let info = [...this.orderItems].find(
        (x) => x.productId === this.productId
      )
      return parseInt(info.qty || 0)
    },
    cartQty: {
      get() {
        if (this.quantity !== null) {
          return this.quantity
        }
        let items = [...this.orderItems]
        let info =
          items.find((x) => x.productId === this.productId) || this.product
        return parseInt(info.qty || 0)
      },
      set(val) {
        this.quantity = val || 0
      },
    },
  },
  methods: {
    async AddItemInOrder(incrementQty) {
      let cartQty = parseInt(this.cartQty),
        timeout = cartQty > 1 ? 300 : 0

      incrementQty = parseInt(incrementQty)

      this.cartQty = cartQty + incrementQty > 0 ? cartQty + incrementQty : 0

      this.checkMaxLimit()

      this.setLoading(true)
      let objRequest = {
        orderID: this.orderId,
        editOrderLineItems: [],
      }
      let item = this.product

      objRequest.editOrderLineItems.push({
        productID: item.productID,
        qty: this.cartQty,
        allowSubstitute: false,
        isDeleted: false,
      })

      let response = await this.$store.dispatch('order/updateOrder', objRequest)

      if (response && this.cartQty > 0) {
        if (this.applyCoupon && this.currentDiscount?.couponCode) {
          await this.applyDiscountCoupons(this.currentDiscount?.couponCode)
        }
      }
      this.setLoading(false)
    },
    async applyDiscountCoupons(discountCoupon) {
      if (discountCoupon) {
        try {
          let discountCouponCode = { discountCouponCode: discountCoupon }
          let res = await this.$store.dispatch('order/applyDiscountCoupons', {
            orderId: this.orderId,
            payload: discountCouponCode,
          })
          if (
            res.success &&
            res.data.errorMessage &&
            !res.data.errorMessage.length
          ) {
            this.ftxConfirmCoupon(
              '',
              'Yay! Coupon is applied',
              'Cancel',
              'Ok',
              true,
              false,
              '',
              '',
              false,
              true,
              false,
              false
            )
          }
        } catch (error) {}
      }
    },
    checkMaxLimit() {
      if (this.cartQty >= this.qtyLimit) {
        this.showMaxError = true

        if (this.showMaxErrorTimer) {
          clearTimeout(this.showMaxErrorTimer)
          this.showMaxErrorTimer = null
        }

        this.showMaxErrorTimer = setTimeout(() => {
          this.showMaxError = false
        }, 2000)

        this.cartQty = this.qtyLimit
      }
    },
    setLoading(loading = false) {
      this.showLoader = loading
    },
  },
}
</script>
<style lang="scss">
.h-search-2 {
  .q-list {
    .q-item {
      &:not(:nth-of-type(1)):not(:nth-of-type(2)):not(:nth-of-type(3)) {
        .product-cart .dropdown {
          bottom: calc(100% - 1px);
          top: auto;
        }
      }
    }
  }
  .q-scrollarea {
    min-height: 330px;
  }
  &.one-record {
    .q-scrollarea {
      max-height: 220px;
      min-height: 220px;
    }
  }
  &.two-record {
    .q-scrollarea {
      max-height: 270px;
      min-height: 270px;
    }
  }
}

.product-cart {
  position: relative;
  width: 100%;
  .q-btn {
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
  }
  .dropdown {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% - 1px);
    color: $tertiary;
    background: #fff;
    border: 1px solid $secondary;
    border: 1px solid var(--q-color-secondary) !important;
    border-radius: 0px 0px 3px 3px;
    z-index: 3;
    transition: all 0.3s ease-in-out;
    // &:nth-child(1),
    // &:nth-child(2),
    // &:nth-child(3),
    // &:nth-child(4) {
    //   top: calc(100% - 1px);
    //   bottom: auto;
    // }
    .q-item {
      min-height: 30px;
      padding: 2px 10px;
      flex-wrap: nowrap;
      background: #fff;
      i {
        color: $tertiary;
      }
      .q-item__section {
      }
    }
    .q-item__section--avatar {
      min-width: 24px;
      padding-right: 5px;
      justify-content: center;
    }
    .q-item__section--main {
      font-size: 12px !important;
      color: #314154;
    }
    .q-radio__inner {
      width: 0.4em;
      min-width: 0.4em;
      height: 0.4em;
    }
    .q-hoverable:hover .q-focus-helper {
      background: $secondary;
      background: var(--q-color-secondary) !important;
    }
  }
  .q-btn__wrapper {
    width: auto;
  }
  &:hover .q-btn--rectangle {
    border-radius: 3px 3px 0px 0px;
  }
  &:hover .q-btn--outline .q-btn__wrapper:before {
    border-color: $secondary;
    border-color: var(--q-color-secondaryOnBody) !important;
  }
  .hover:hover .dropdown {
    display: block;
  }
  .add-cart {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    background: $white;
    color: $tertiary;
    border: 1px solid $secondary;
    border: 1px solid var(--q-color-secondaryOnBody) !important;
    border-radius: 5px;
    cursor: pointer;
    .icon,
    .btn,
    .arrow {
      padding: 10px 15px;
      min-height: 17px;
      @media (max-width: 767px) {
        /*padding-left: 8px !important;
          padding-right: 8px !important;*/
        padding: 10px;
      }
      @media (max-width: 374px) {
        padding-left: 4px !important;
        padding-right: 4px !important;
      }
    }
    .arrow {
      @media (max-width: 374px) {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
    }
    .icon {
      padding: 10px;
      line-height: 16px;
      display: none;
      i {
        font-size: 14px;
      }
    }
    &.addDelivery .icon {
      display: flex;
    }
    .btn {
      flex: 10000 0 0%;
      font-size: 14px;
      padding: 10px 15px;
    }
    .arrow {
      border-left: 1px solid $secondary;
      border-left: 1px solid var(--q-color-secondary) !important;
      .ar {
        line-height: 13px;
      }
      i {
        font-size: 12px;
        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
      .dropdown {
        left: 0px;
        right: 0px;
      }
    }
    &.addDelivery {
      background: $secondary;
      background: var(--q-color-secondary) !important;
      color: $secondary;
      color: var(--q-color-secondaryText) !important;
      border: 1px solid $secondary;
      border: 1px solid var(--q-color-secondaryOnBody) !important;
      cursor: pointer;
      .arrow {
        border-left: 1px solid $secondary;
        border-left: 1px solid var(--q-color-secondary) !important;
      }
      .btn {
        padding: 10px 5px;
        @media (max-width: 767px) {
          padding: 10px 10px 10px 0px;
        }
        @media (max-width: 375px) {
          font-size: 12px;
        }
      }
    }
  }
  .added-cart {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    color: $tertiary;
    // border: 1px solid $secondary;
    border: 1px solid var(--q-color-secondaryOnBody) !important;
    // outline: 1px solid var(--q-color-secondaryOnBody);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    position: relative;
    .icon,
    .btn {
      // padding: 10px 8px;
      min-height: 17px;
      @media (max-width: 374px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .icon {
      padding: 9px 10px;
      background: $secondary;
      background: var(--q-color-secondary) !important;
      color: var(--q-color-secondaryText) !important;
      line-height: 16px;
      cursor: pointer;
      border-radius: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      i {
        font-size: 14px;
      }
    }
    .btn {
      flex: 10000 0 0%;
      font-size: 14px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      @media (max-width: 1024px) {
        width: 85px;
      }
      .q-btn {
        flex: 0 0 50px;
        @media (max-width: 1024px) {
          flex: 0 0 30px;
        }
        .q-focus-helper,
        .q-ripple {
          display: none;
        }
        @media (max-width: 374px) {
          flex: 0 0 36px;
        }
      }
      .q-field {
        flex: 10000 0 0%;
        @media (max-width: 991px) {
          font-size: 10px;
        }
      }
    }
    .q-field__control,
    .q-field__marginal {
      height: 35px;
      padding: 0;
    }
    .q-field__native {
      text-align: center;
    }
    .q-field--outlined .q-field__control:before,
    .q-field--outlined .q-field__control:after {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
  /* Bottom */
  &:hover .add-cart {
    border-radius: 5px 5px 0px 0px;
  }
  &:hover .addDelivery {
    border-radius: 5px 5px 5px 5px;
  }
  &.no-rounder:hover {
    .add-cart.addDelivery,
    .added-cart {
      border-radius: 5px 5px 0 0;
      .icon {
        border-top-left-radius: 0;
      }
    }
    .added-cart {
      .dropdown {
        left: -1px;
        right: -1px;
      }
    }
    .addDelivery {
      border-radius: 5px;
    }
  }
  /* Bottom */
  /* Top */
  &.top {
    .dropdown {
      border: 1px solid $secondary;
      border: 1px solid var(--q-color-secondaryOnBody) !important;
      top: auto;
      bottom: calc(100% - 1px);
      border-radius: 3px 3px 0px 0px;
    }
    &:hover .add-cart {
      border-radius: 0px 0px 5px 5px;
    }
    &:hover .addDelivery {
      border-radius: 5px 5px 5px 5px;
    }
    &.no-rounder:hover {
      .add-cart.addDelivery,
      .added-cart {
        border-radius: 0px 0px 5px 5px;
      }
    }
  }
  /* Top */
  /* Loader */
  &.product-cart-loading {
    .added-cart {
      .q-inner-loading {
        svg {
          position: absolute;
          left: 10px;
          //  @media (max-width: 767px) {
          //    left:20px;
          // }
        }
      }
    }
    .add-cart {
      .q-inner-loading {
        svg {
          position: absolute;
          right: 10px;
        }
      }
    }
    .delivery-mode,
    .ar {
      opacity: 0.3;
      transition: opacity 0.1s;
      -webkit-transition: opacity 0.1s;
    }
  }
  /* Loader */
  /* Out of Stock */
  .btn-outstock {
    min-height: 38px;
    font-size: 11px;
    text-transform: uppercase;
    line-height: 38px;
    text-align: center;
    background: rgba(255, 0, 0, 0.1);
    color: rgba(255, 0, 0, 1);
    display: block;
    border-radius: 5px;
  }
  /* Out of Stock */
}
.q-product-slider,
.discount-content {
  .product-cart {
    .dropdown {
      border: 1px solid $secondary;
      border: 1px solid var(--q-color-secondaryOnBody) !important;
      top: auto;
      bottom: calc(100% - 1px);
      border-radius: 3px 3px 0px 0px;
    }
    &:hover .add-cart {
      border-radius: 0px 0px 5px 5px;
    }
    &:hover .addDelivery {
      border-radius: 5px 5px 5px 5px;
    }
    &.no-rounder:hover {
      .add-cart.addDelivery,
      .added-cart {
        border-radius: 0px 0px 5px 5px;
      }
    }
  }
}
.error {
  color: red;
  width: 100%;
  font-size: 10px;
  position: absolute;
  font-weight: bold;
  top: 40px;
  &.top {
    top: -13px;
  }
}

.disable {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}
.notify-custom-mobile-postion {
  @media (max-width: 767px) {
    margin-top: -96px;
  }
}
</style>
