export const getDefaultState = () => {
  return {
    clientCred: null,
    selectedLocation: null,
    selectedFulfillment: null,
    locationOrShippingZip: null,
    // isProceedLocation: false,
    locationOrShippingZip: null,
    selectedZipCode: null,
    authRedirectUri: null,
    modes: {
      activeMode: 4,
      accessCodeValidated: false,
    },
    eaivSessionId: null,
    eaivVerificationSuccess: false,
    showEAIVPage: null,
    // Un used
    // isProceedLocation: false,
    // selectedLocationId: null, // id
    // fulfillmentId: null, // id
  }
}

export default getDefaultState()
