export async function getCustomerReviews({}, params) {
  try {
    let response = await this.$axiosInstance.get('/CustomerReview', { params })
    if (response?.data?.success) return response.data
    else return false
  } catch (error) {
    return error
  }
}

export async function postCustomerReviews({ commit }, body) {
  try {
    let response = await this.$axiosInstance.post('/CustomerReview', body)

    if (response?.data?.success) {
      commit('ADD_CUSTOMER_REVIEW', response.data.data)
      return response.data
    } else return false
  } catch (error) {
    return error
  }
}

export async function editCustomerReview({}, { reviewID, params }) {
  try {
    let { data } = await this.$axiosInstance.put(
      `/CustomerReview/${reviewID}`,
      params
    )

    return data
  } catch (error) {
    return false
  }
}

export async function deleteCustomerReview({}, reviewID) {
  if (reviewID) {
    try {
      await this.$axiosInstance.delete(`/CustomerReview/${reviewID}`)

      return true
    } catch (error) {
      return false
    }
  }
}

export async function customerFeedbackOnReview({}, body) {
  try {
    let response = await this.$axiosInstance.post(
      '/CustomerReview/Feedback',
      body
    )

    if (response?.data?.success) {
      return response.data
    } else {
      return false
    }
  } catch (error) {
    return error
  }
}

export async function customerReviewBetaFeedback({}, body) {
  try {
    let response = await this.$axiosInstance.post(
      '/CustomerReview/Beta/Feedback',
      body
    )

    if (response?.data?.success) {
      return response.data
    } else {
      return false
    }
  } catch (error) {
    return error
  }
}

export async function customerReviewSubmittedByOrder({}, params) {
  try {
    let response = await this.$axiosInstance.get(
      '/CustomerReview/IsCustomerReviewSubmittedByOrder',
      {
        params,
      }
    )

    if (response?.data?.success) return response.data.data
    else return false
  } catch (error) {
    return error
  }
}
