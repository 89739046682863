export function isProductMarkedAsFavorite(state) {
  return (productId, isFavorite) => {
    if (state.markedAsFavorites[productId]) {
      return state.markedAsFavorites[productId].isFavorite
    }

    return isFavorite
  }
}

export function sortOptions(state) {
  return state.sortOptions
}

export function filterOptions(state) {
  return state.filterOptions
}

export function categories(state) {
  return state.categories
}

export function favorites(state) {
  return state.favorites
}

export function defaultParams(state) {
  return state.defaultParams
}

export function favoriteItemsCount(state, getters) {
  let count = 0

  for (let key in state.favorites) {
    let item = state.favorites[key]

    if (getters.isProductMarkedAsFavorite(item.productID, item.isFavorite)) {
      count++
    }
  }

  return count
}

export function nextPageNumber(state, getters) {
  return (perPage) => {
    let count = getters.favoriteItemsCount

    return Math.floor(count / perPage) + 1
  }
}

export function totalCount(state) {
  return state.totalCount
}

export function loading(state) {
  return state.loading
}

export function loadingCategory(state) {
  return state.loadingCategory
}
