export const defaultState = () => {
  return {
    favorites: {},
    loading: true,
    loadingCategory: false,
    totalCount: 0,
    markedAsFavorites: {},
    sortOptions: [
      { label: 'Category', value: 0 },
      { label: 'Most Frequently Purchased', value: 1 },
      { label: 'Recently Purchased', value: 2 },
      { label: 'Price High to Low', value: 3 },
      { label: 'Price Low to High', value: 4 },
    ],
    defaultParams: { sortBy: 0, PageSize: 20, Page: 1, category: '' },
    categories: [
      {
        categoryID: '',
        categoryName: 'All',
      },
    ],
  }
}

export default defaultState()
