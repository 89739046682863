export const defaultState = () => {
  return {
    wishlist: {},
    allowAddToCart: false,
    selectedFulfillmentMethodID: '',
    wishlistCount: 0,
    selectedproductid: [],
    loading: true,
    loadingCategory: false,
    totalCount: 0,
    markedAsWishlist: {},
    sortByOptions: [
      { value: 'Price Low to High', label: 'Price Low to High' },
      { value: 'Price High to Low', label: 'Price High to Low' },
      { value: 'Best Sellers', label: 'Best Sellers' },
      { value: 'Customer Reviews', label: 'Customer Reviews (Ratings)' },
    ],
    defaultParams: { sortBy: 'Price Low to High', PageSize: 20, Page: 1 },
    categories: [
      {
        categoryID: '',
        categoryName: 'All',
      },
    ],
    allWishlist: [],
  }
}

export default defaultState()
