<template>
  <div :class="['template-image q-mb-md']">
    <AgeRestrictedBlur :isPreview="isPreview" :component="component" />
    <template v-if="showAgeVerificationBtn && component.isAgeRestricted">
      <img
        :src="`https://placehold.co/${component.imageWidth || 612}x${
          component.imageHeight || 408
        }/F5F5F5/black?text=Age+Restricted`"
        alt=""
        class="block full-width"
      />
    </template>
    <template v-else>
      <template v-if="redirectLink">
        <a v-if="component.openInNewTab" :href="redirectLink" target="_blank">
          <img
            :src="component.imageUrl || '/placeholder-image.png'"
            alt=""
            class="block full-width"
          />
        </a>
        <router-link v-else :to="redirectLink">
          <img
            :src="component.imageUrl || '/placeholder-image.png'"
            alt=""
            class="block full-width"
          />
        </router-link>
      </template>
      <img
        v-else
        :src="component.imageUrl || '/placeholder-image.png'"
        alt=""
        class="block full-width"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'SingleImage',
  props: {
    component: {
      type: Object,
      default: new Object(),
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    redirectLink() {
      if (this.component.linkType && this.component.seoName)
        return `/${this.component.linkType}/${this.component.seoName}`

      return null
    },
    // canShowImageComponent() {
    //   return (
    //     !this.component.isAgeRestricted ||
    //     (this.component.isAgeRestricted &&
    //       (!this.storeAgeVerificationRequired ||
    //         (this.storeAgeVerificationRequired && this.isUserAgeVerified)))
    //   )
    // },
  },
}
</script>
