import CustomConfirm from 'components/common/CustomConfirm'
import SignUpConfirmDialog from 'components/common/SignUpConfirm'
import AccountCloseConfirm from 'components/common/AccountCloseConfirm'
import { Dialog } from 'quasar'
import CustomConfirmCoupon from 'components/common/CustomConfirmCoupon'

export const signUpConfirm = function () {
  return Dialog.create({
    component: SignUpConfirmDialog,
    parent: this,
  })
}

export default ({ Vue, store }) => {
  const changeDialogState = ({
    dialog = '',
    val = false,
    parentDialog = '',
    properties = null,
    refresh = false,
  }) => {
    if (dialog) {
      store.commit('showcase/updateDialogState', {
        dialog,
        val,
        parentDialog,
      })

      if (properties)
        store.commit('showcase/SET_DIALOG_PROPERTIES', {
          dialog,
          properties,
          refresh,
        })
    }
  }

  const deleteDialogProperties = (dialog = '') => {
    if (dialog) store.commit('showcase/DELETE_DIALOG_PROPERTIES', dialog)
  }

  const ftxConfirm = (
    title,
    description,
    cancelBtnLabel,
    confirmBtnLabel,
    closeBtn = null,
    outsideClickCloseDisable = null
  ) => {
    let props = { text: title }
    if (description) props['description'] = description
    if (cancelBtnLabel) props['cancelBtnLabel'] = cancelBtnLabel
    if (confirmBtnLabel) props['confirmBtnLabel'] = confirmBtnLabel
    if (closeBtn !== null) props['closeBtn'] = closeBtn
    if (outsideClickCloseDisable !== null)
      props['outsideClickCloseDisable'] = outsideClickCloseDisable
    return Dialog.create({
      component: CustomConfirm,
      parent: this,
      ...props,
    })
  }

  const ftxConfirmCoupon = (
    title,
    description,
    cancelBtnLabel,
    confirmBtnLabel,
    closeBtn = null,
    outsideClickCloseDisable = null,
    iconName,
    iconSize,
    isSVG = null,
    isIMG = null,
    cancelbtn = null,
    okbtn = null
  ) => {
    let props = { text: title }
    if (description) props['description'] = description
    if (cancelBtnLabel) props['cancelBtnLabel'] = cancelBtnLabel
    if (confirmBtnLabel) props['confirmBtnLabel'] = confirmBtnLabel
    if (closeBtn !== null) props['closeBtn'] = closeBtn
    if (outsideClickCloseDisable !== null)
      props['outsideClickCloseDisable'] = outsideClickCloseDisable
    if (iconName) props['iconName'] = iconName
    if (iconSize) props['iconSize'] = iconSize
    if (isSVG !== null) props['isSVG'] = isSVG
    if (isIMG !== null) props['isIMG'] = isIMG
    if (cancelbtn !== null) props['cancelbtn'] = cancelbtn
    if (okbtn !== null) props['okbtn'] = okbtn

    return Dialog.create({
      component: CustomConfirmCoupon,
      parent: this,
      ...props,
    })
  }

  const accountClosedConfirm = () => {
    return Dialog.create({
      component: AccountCloseConfirm,
      parent: this,
    })
  }

  Vue.prototype['changeDialogState'] = changeDialogState
  Vue.prototype['deleteDialogProperties'] = deleteDialogProperties
  Vue.prototype['ftxConfirm'] = ftxConfirm
  Vue.prototype['ftxConfirmCoupon'] = ftxConfirmCoupon
  Vue.prototype['signUpConfirm'] = signUpConfirm
  Vue.prototype['accountClosedConfirm'] = accountClosedConfirm
  Vue.prototype['getDialogProperties'] =
    store.getters['showcase/getDialogProperties']
  Vue.prototype['getDialogState'] = store.getters['showcase/getDialogState']

  // Vue.mixin({
  //   computed: {
  //     ...mapGetters('showcase', ['getDialogProperties', 'getDialogState']),
  //   },
  //   methods: {
  //     changeDialogState,
  //     deleteDialogProperties,
  //     ftxConfirm,
  //     ftxConfirmCoupon,
  //     signUpConfirm,
  //     accountClosedConfirm,
  //   },
  // })
}
