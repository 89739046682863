import { setRouteName, resetObjRequests } from 'boot/axios'
import { signUpConfirm } from 'src/boot/dialog-mixin'
import { Platform } from 'quasar'
import { setAVSessionID } from 'src/boot/axios'
import { isSsr } from 'src/constants'
import { setUserToLogRocket } from 'src/boot/log-rocket'

export default ({ router, store, ssrContext }) => {
  const platform = process.env.SERVER ? Platform.parseSSR(ssrContext) : Platform
  // let isFirstPage = false
  let isEAIVLoaded = false
  router.afterEach((routeTo, routeFrom) => {
    const isMobile = platform?.is?.mobile

    if (process.env.CLIENT && router.app?.$root)
      router.app.$root.$emit('scroll-to-position')

    if (isMobile && routeTo.name != 'Home') {
      if (routeTo.meta.emptyTitle || routeTo.meta.headerTitle) {
        store.commit('common/SET_HEADER_TITLE', {
          title: routeTo.meta.headerTitle || '',
        })
      } else {
        store.commit('common/SET_HEADER', {
          categoryButton: true,
          logo: true,
          accountButton: true,
          searchButton: true,
          cartButton: true,
        })
      }
    } else if (isMobile || (!isMobile && routeFrom.name == 'Checkout')) {
      store.commit('common/RESET_HEADER')
    }

    if (store.state.showcase.dialog.location) {
      store.commit('showcase/updateDialogState', {
        dialog: 'location',
        val: false,
      })
    }

    if (routeFrom.name !== routeTo.name && routeFrom.name) {
      let backRoute = store.getters['common/backRoute']

      if (routeTo.meta.clearHistory) {
        store.commit('common/CLEAR_HISTORY')
      } else if (backRoute && backRoute.name === routeTo.name) {
        store.commit('common/POP_FROM_HISTORY')
      } else if (!routeFrom.meta.disableHistory) {
        store.commit('common/ADD_IN_HISTORY', routeFrom)
      }
    }
  })

  router.beforeEach(async (routeTo, routeFrom, next) => {
    let allowedToEnter = true,
      isLoggedIn = false,
      customerExists = false,
      isFirstPage = store.getters['common/isFirstPage'],
      isBetaAccessCodeValidated = false,
      isBetaModeActive = false,
      isTestModeActive = false,
      isOfflineModeActive = false,
      activeMode = null,
      isServer = process.env.SERVER

    resetObjRequests()
    if (
      routeTo.name != 'Callback' &&
      ((isSsr && process.env.SERVER) ||
        (!isSsr && !isFirstPage) ||
        routeFrom.name === 'Callback')
    ) {
      // isFirstPage = true
      store.commit('common/SET_FIRST_LOADED', true)

      // await store.dispatch('auth/signinSilent')
      await store.dispatch('auth/mapUser')
      await store.dispatch('persisted/getToken')

      let accessToken = store.getters['persisted/apiAccessToken']
      if (!accessToken) {
        store.commit('common/SET_APP_LOADED', true)
        return next()
      }

      setUserToLogRocket && setUserToLogRocket()
      await store.dispatch('common/getConfiguration')

      isBetaModeActive = store.getters['persisted/isBetaModeActive']
      isTestModeActive = store.getters['persisted/isTestModeActive']
      isOfflineModeActive = store.getters['persisted/isOfflineModeActive']
      isBetaAccessCodeValidated =
        store.getters['persisted/isBetaAccessCodeValidated']

      if (!isBetaAccessCodeValidated && isBetaModeActive)
        activeMode = 'BetaModeDialog'
      else if (!isBetaAccessCodeValidated && isTestModeActive)
        activeMode = 'TestModeDialog'
      else if (isOfflineModeActive) activeMode = 'OfflineModeDialog'
      if (activeMode)
        store.commit('showcase/updateDialogState', {
          dialog: activeMode,
          val: true,
        })

      if (!isServer) await store.dispatch('theme/getTheme')

      let tenantFound = store.getters['common/tenantFound'],
        storeIsActive = store.getters['common/storeIsActive']

      if (
        !(tenantFound && storeIsActive) &&
        isBetaModeActive &&
        isBetaAccessCodeValidated
      ) {
        store.commit('common/SET_APP_LOADED', true)
        return next()
      }

      isLoggedIn = store.getters['auth/isLoggedIn']

      if (isLoggedIn) {
        await store.dispatch('auth/getUserInfo')
        customerExists = await store.dispatch('auth/checkCustomerExits')
        if (customerExists) {
          await store.dispatch('customer/getCustomer')

          // check for age verification if already verified before login
          const customerInfo = store.getters['customer/customer']
          const eaivPersistedSessionId =
            store.getters['persisted/eaivPersistedSessionId']
          const businessConfigurations =
            store.getters['common/businessConfigurations']
          if (
            !customerInfo.isVerifiedOnline &&
            eaivPersistedSessionId &&
            businessConfigurations?.isStoreAgeRestricted &&
            businessConfigurations?.enableAgeRestrictedBrowsing
          ) {
            await store.dispatch(
              'customer/mapAgeVerification',
              eaivPersistedSessionId
            )
          }
        }
        // store.dispatch('wishlist/getAllWishlist')
        store.commit('guest/RESET_SLOTS')
      }

      const businessConfigurations =
          store.getters['common/businessConfigurations'],
        showEAIVPage = store.getters['persisted/showEAIVPage'],
        customerInfo = store.getters['customer/customer'],
        preferredLocationID = customerInfo?.preferredLocationID,
        preferredFulfillmentMethodID =
          customerInfo?.preferredFulfillmentMethodID,
        persistedLocation = store.getters['persisted/currentLocation'],
        persistedFulfillment = store.getters['persisted/selectedFulfillment'],
        persistedZipCode = store.getters['persisted/selectedZipCode']

      /* EAIV: Show age verification page */
      if (
        !isLoggedIn &&
        businessConfigurations?.isStoreAgeRestricted &&
        (businessConfigurations?.enableAgeRestrictedBrowsing ||
          businessConfigurations?.enableAgeRestrictedCheckout) &&
        showEAIVPage === null
      ) {
        store.commit('ageVerification/RESET_VERIFICATION_STATUS', false)
        store.commit('persisted/SET_SHOW_EAIV_PAGE', true)
      } else if (
        !businessConfigurations?.isStoreAgeRestricted &&
        showEAIVPage != null
      ) {
        store.commit('ageVerification/RESET_VERIFICATION_STATUS', false)
        store.commit('persisted/SET_SHOW_EAIV_PAGE', null)
      }

      /* EAIV: Show age verification page */

      const guestCartNumber = store.getters['guest/guestCartNumber'],
        verifiedGuestCustomerId = store.getters['guest/verifiedGuestCustomerId']
      if (
        isLoggedIn &&
        customerExists &&
        ((guestCartNumber && !verifiedGuestCustomerId) ||
          verifiedGuestCustomerId == customerInfo.customerID) &&
        persistedLocation?.locationId &&
        persistedFulfillment?.fulfillmentId
      ) {
        await store
          .dispatch('locationDetail/fetchLocation', {
            locationId: persistedLocation.locationId,
          })
          .then(async (data) => {
            if (data?.locationId && data.showSetPreffered) {
              let selectedFulfillment = data.fulFillments.find(
                (fulFillment) =>
                  fulFillment.fulfillmentMethodID ===
                  persistedFulfillment?.fulfillmentId
              )
              if (selectedFulfillment) {
                await store.dispatch('persisted/setServiceLocation', {
                  location: {
                    ...data,
                    customerZip:
                      selectedFulfillment.codeName === 'sd'
                        ? customerInfo.zipCode ||
                          store.getters['persisted/locationOrShippingZip'] ||
                          ''
                        : '',
                  },
                  fulfillment: selectedFulfillment,
                })
                store.commit('location/SET_STATE', {
                  key: 'currentLocationDetail',
                  value: data,
                })
              }
            }
          })
          .catch(() => {})
      } else if (
        isLoggedIn &&
        customerExists &&
        (persistedLocation?.locationId !== preferredLocationID ||
          persistedFulfillment?.fulfillmentId !==
            preferredFulfillmentMethodID ||
          !store.getters['persisted/locationOrShippingZip'])
      ) {
        await store
          .dispatch('locationDetail/fetchLocation', {
            locationId: preferredLocationID || persistedLocation?.locationId,
          })
          .then(async (data) => {
            if (data?.locationId && data.showSetPreffered) {
              let selectedFulfillment = data.fulFillments.find(
                (fulFillment) =>
                  fulFillment.fulfillmentMethodID ===
                  (preferredFulfillmentMethodID ||
                    persistedFulfillment?.fulfillmentId)
              )
              if (selectedFulfillment) {
                await store.dispatch('persisted/setServiceLocation', {
                  location: {
                    ...data,
                    customerZip:
                      selectedFulfillment.codeName === 'sd'
                        ? customerInfo.zipCode ||
                          store.getters['persisted/locationOrShippingZip'] ||
                          ''
                        : '',
                  },
                  fulfillment: selectedFulfillment,
                })
                store.commit('location/SET_STATE', {
                  key: 'currentLocationDetail',
                  value: data,
                })
              }

              if (
                persistedLocation &&
                persistedFulfillment &&
                preferredLocationID &&
                preferredFulfillmentMethodID &&
                (persistedLocation?.locationId !== preferredLocationID ||
                  persistedFulfillment?.fulfillmentId !==
                    preferredFulfillmentMethodID)
              ) {
                store.dispatch('location/setAsMyStore', {
                  location: persistedLocation,
                  fulfillment: persistedFulfillment,
                  selectedZipCode: persistedZipCode,
                  forceCompare: true,
                })
              }
            }
          })
          .catch(() => {})
      }
    } else if (
      !platform?.is?.cordova &&
      routeTo.name == 'Callback' &&
      !Object.keys(routeTo.query).length
    ) {
      allowedToEnter = false
      let authRedirectUri = store.getters['persisted/authRedirectUri'] || '/'
      next({
        path: authRedirectUri,
      })
    } else {
      // store.commit('common/COUNT_DOWN_CHANGE', 0)

      store.getters['common/showPWAInstallPopup'] &&
        store.commit('common/UPDATE_STATE', {
          key: 'showPWAInstallPopup',
          val: false,
        })

      setRouteName(routeTo.name)
    }

    /* EAIV: Set session Id in the header */
    if (!isEAIVLoaded) {
      const businessConfigurations =
          store.getters['common/businessConfigurations'],
        eaivPersistedSessionId =
          store.getters['persisted/eaivPersistedSessionId'],
        eaivPersistedVerificationSuccess =
          store.getters['persisted/eaivPersistedVerificationSuccess'],
        customerInfo = store.getters['customer/customer']
      if (
        businessConfigurations?.isStoreAgeRestricted &&
        businessConfigurations?.enableAgeRestrictedBrowsing &&
        ((!isLoggedIn && eaivPersistedVerificationSuccess) ||
          (isLoggedIn && customerInfo.isVerifiedOnline)) &&
        eaivPersistedSessionId
      )
        setAVSessionID(eaivPersistedSessionId)
      isEAIVLoaded = true
    }
    /* EAIV: Set session Id in the header */

    routeTo.matched.some((record) => {
      // check if there is meta data
      let currentLocationId = store.getters['persisted/currentLocationId']
      isLoggedIn = store.getters['auth/isLoggedIn']
      customerExists = store.getters['auth/customerExists']

      if (
        routeTo?.path === '/loyalty-program' &&
        (!isLoggedIn || !store.getters['common/storeHasLnsSubscription'])
      ) {
        next({
          name: 'Home',
        })
      } else if ('meta' in record) {
        // ------------------------------------------------------------
        // check if user needs to be logged in to access this page
        if (
          'requiresAuth' in record.meta &&
          record.meta.requiresAuth &&
          !isLoggedIn
        ) {
          // this route requires auth, check if user is logged in
          // if not, redirect to Home page.
          // User is not logged in, redirect to signin page
          allowedToEnter = false
          next({
            name: 'Home',
          })

          store.commit('showcase/updateDialogState', {
            dialog: 'accountDrawer',
            val: true,
          })
          // store.dispatch('auth/signIn')
        } else if (
          'requiresCustomer' in record.meta &&
          record.meta.requiresCustomer &&
          !customerExists &&
          isLoggedIn
        ) {
          allowedToEnter = false
          if (
            routeFrom.name &&
            'requiresCustomer' in routeTo.meta &&
            routeTo.meta.requiresCustomer
          ) {
            signUpConfirm.call(router.app)
          } else
            next({
              name: 'Home',
            })
        } else if (
          'requiresLocation' in record.meta &&
          record.meta.requiresLocation &&
          !currentLocationId
        ) {
          allowedToEnter = false
          if (routeFrom.name != 'FindStore')
            next({
              name: 'FindStore',
              query: { redirectFrom: routeTo.fullPath },
            })
        }
      }
    })

    !store.getters['common/appLoaded'] &&
      store.commit('common/SET_APP_LOADED', true)

    // go to the requested page
    if (allowedToEnter) next()
  })
}
