import { Platform } from 'quasar'
import { mapGetters } from 'vuex'

function getShouldExitFn() {
  const exitPaths = ['#/']

  return exitPaths.includes(window.location.hash)
}

export default {
  computed: {
    ...mapGetters('lnsProgram', ['showMobileLoyaltyProgram', 'lnsHistory']),
    ...mapGetters('showcase', ['dialogHistory']),
  },
  methods: {
    getLastOpenedDialog() {},
  },
  beforeMount() {
    if (typeof window === 'undefined') {
      return
    }
    const { cordova, capacitor } = Platform.is

    if (cordova !== true && capacitor !== true) {
      return
    }

    const backHandler = () => {
      const shouldExit = getShouldExitFn()

      if (this.showMobileLoyaltyProgram) {
        this.$root.$emit('CORDOVA_NAVIGATE_BACK')
      } else if (this.dialogHistory?.length) {
        this.changeDialogState({
          dialog: this.dialogHistory[this.dialogHistory.length - 1],
          val: false,
        })
      } else if (shouldExit) {
        navigator.app.exitApp()
      } else window.history.back()
    }

    if (cordova === true) {
      document.addEventListener('deviceready', () => {
        document.addEventListener('backbutton', backHandler, false)
      })
    } else {
      window.Capacitor.Plugins.App.addListener('backButton', backHandler)
    }
  },
  // beforeDestroy() {
  //   // this.showing === true && this.__removeHistory()
  // },
}
