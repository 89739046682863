export const appLoaded = (state) => !!state.appLoaded

export const isFirstPage = (state) => !!state.isFirstPage

export const trendingProducts = (state) => state.trendingProducts

export const frequentSearches = (state) => state.frequentSearches

export function forceUserToEnterZipCode(
  state,
  getters,
  rootState,
  rootGetters
) {
  // if (rootGetters['persisted/isProceedLocation']) return false
  return (
    state.businessConfigurations &&
    state.businessConfigurations.forceUserToEnterZipCode
  )
}

export function header(state) {
  return Object.keys(state.header).length
    ? state.header
    : state.defaultHeaderParams
}

export const alertData = (state) => state.alertData

export const backRoute = (state) =>
  state.backHistories.length
    ? state.backHistories[state.backHistories.length - 1]
    : null

export const networkStatus = (state) => state.NetworkConnectionStatus

export const footerData = (state) => state.footerData

export const socialPlatforms = (state) => state.socialPlatforms

export const storeConfigurationLoaded = (state) =>
  state.storeConfigurationLoaded

export const clientStoreConfigurationLoaded = (state) =>
  state.clientStoreConfigurationLoaded

export const tenantFound = (state) => state.tenantFound

export const storeIsActive = (state) => !!state.storeIsActive

export const businessConfigurations = (state) =>
  state.businessConfigurations || {}

// export const shippingConfigurations = (state) =>
//   state.shippingConfigurations || {}

export const fulFillments = (state) => state.fulFillments

export const selectedFulFillments = (state, getters) => {
  let selectedDeliveryModes = []

  for (let key in getters.fulFillments) {
    let fulFillment = state.fulFillments[key]
    if (fulFillment.checked) selectedDeliveryModes.push(fulFillment.codeName)
  }

  return selectedDeliveryModes
}

export const currentDeliveryModes = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  let deliveryModes = {
    isStorePickup: false,
    isStandardDelivery: false,
    isScheduledDelivery: false,
  }

  let selectedFulfillmentCodeName =
    rootGetters['persisted/selectedFulfillmentCodeName'] || ''

  switch (selectedFulfillmentCodeName) {
    case 'sp':
      deliveryModes.isStorePickup = true
      break
    case 'sd':
      deliveryModes.isStandardDelivery = true
      break
    case 'scd':
      deliveryModes.isScheduledDelivery = true
      break
  }

  return deliveryModes
}

export const checkSlotBookingRequired = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  return (fulfillmentCode) => {
    let selectedFulfillmentCodeName =
      rootGetters['persisted/selectedFulfillmentCodeName'] || ''

    if (fulfillmentCode == selectedFulfillmentCodeName) {
      let targetFulfillment =
        state.fulFillmentLocations[selectedFulfillmentCodeName] || {}

      return (
        targetFulfillment?.available &&
        !targetFulfillment?.configuration?.isComingSoon &&
        targetFulfillment?.configuration?.isSlotBookingRequired
      )
    }
    return false
  }
}

export const isSlotBookingRequired = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  let selectedFulfillmentCodeName =
    rootGetters['persisted/selectedFulfillmentCodeName'] || ''

  let targetFulfillment =
    state.fulFillmentLocations[selectedFulfillmentCodeName] || {}

  return (
    targetFulfillment?.available &&
    !targetFulfillment?.configuration?.isComingSoon &&
    targetFulfillment?.configuration?.isSlotBookingRequired
  )
}

export const mobileAppData = (state) => state.mobileAppData

// export const isBetaModeActive = (state) => state.isBetaModeActive

// export const isBetaAccessCodeValidated = (state) =>
//   state.isBetaAccessCodeValidated

export const enableOfferTag = (state) =>
  state.businessConfigurations?.enableOfferTag || false

export const showTrendingCategory = (state) =>
  state.businessConfigurations?.showTrendingCategory || false

export const businessFulfillments = (state) => state.businessFulfillments
export const allBusinessFulfillments = (state) => state.allFulfillments
export const isSelectedFulfillmentInactive = (state) =>
  state.isFulfillmentInactive

export const askFullAddressForDelivery = (state) =>
  !!state.askFullAddressForDelivery

// export const homePageSwiperWidth = (state) => state.homePageSwiperWidth

export const currentPaymentGateway = (state) =>
  state.businessConfigurations.currentPaymentGateway || 'DATACAP'

export const storeHasLnsSubscription = (state) =>
  state.businessConfigurations?.lnsSubscriptionType === 2

export const showPWAInstallPopup = (state) => !!state.showPWAInstallPopup

export const showPWAInstallPopupSidebar = (state) =>
  !!state.showPWAInstallPopupSidebar

export const paymentServiceLogoLoaded = (state) =>
  !!state.paymentServiceLogoLoaded

export const paymentServiceLogos = (state) => state.paymentServiceLogos

export const commonStateByKey = (state) => (field) => state[field]
