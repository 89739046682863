import VuexPersistence from 'vuex-persist'

export default ({ store }) => {
  let modules = ['persistedLocal', 'geolocation', 'ageVerification', 'guest']

  new VuexPersistence({
    key: 'eFTXPersistedLocal',
    storage: window.localStorage,
    modules,
    filter: (mutation) =>
      mutation?.type?.includes('persistedLocal/') ||
      mutation?.type?.includes('geolocation/') ||
      mutation?.type?.includes('ageVerification/') ||
      mutation?.type?.includes('guest/'),
  }).plugin(store)
}
