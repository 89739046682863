<template>
  <div class="wishlist-container">
    <q-btn
      v-if="label"
      unelevated
      outline
      no-caps
      @click.stop="toggleWishlist"
      class="wishlist-btn"
      :class="className"
    >
      <SvgIcon icon="wishlist" size="18px" />
      <span class="q-ml-sm"> {{ label }} </span>
    </q-btn>
    <q-btn
      v-else
      dense
      unelevated
      no-caps
      @click.stop="toggleWishlist"
      class="wishlist-btn"
    >
      <q-tooltip max-width="180px" :offset="[0, 0]" content-class="bg-dark">
        Add to wishlist
      </q-tooltip>
      <SvgIcon icon="wishlist" size="18px" />
    </q-btn>
  </div>
</template>

<script>
import { convertArrayToObject } from 'src/utils'
import { mapGetters } from 'vuex'

export default {
  name: 'AddToWishlist',
  props: {
    products: {
      type: Object,
      default: () => {
        return new Object({})
      },
    },
    label: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: 'wishlist-btn',
    },
    selectedProducts: {
      type: Array,
      default: () => {
        return []
      },
    },
  },

  computed: {
    WishlistProducts() {
      if (this.products.hasOwnProperty('productID')) {
        return convertArrayToObject([this.products], 'productID')
      } else if (Array.isArray(this.products)) {
        return convertArrayToObject([this.products], 'productID')
      }
      return this.products
    },

    requestObject() {
      let objRequest = []
      for (let key in this.WishlistProducts) {
        let product = this.WishlistProducts[key]
        if (
          !this.selectedProducts.length ||
          this.selectedProducts.includes(key)
        ) {
          objRequest.push(
            product.productID

            // markAsWishProduct: !this.isWishlist,
          )
        }
      }
      return objRequest
    },
    ...mapGetters('wishlist', ['isProductMarkedAsWishlist']),
  },
  methods: {
    toggleWishlist() {
      if (this.products?.isDummyProd) return false

      let toggleWishlistCore = () => {
        this.changeDialogState({
          dialog: 'WishlistDialog',
          val: true,
          parentDialog: 'QuickProductDetailDialog',
          properties: {
            productID: this.requestObject,
            // productID: this.products.productID,
          },
        })
      }
      if (!this.isLoggedIn)
        return this.changeDialogState({
          dialog: 'accountDrawer',
          val: !this.getDialogState('accountDrawer'),
        })
      if (!this.customerExists)
        return this.signUpConfirm().onOk(() => {
          toggleWishlistCore()
        })
      toggleWishlistCore()
    },
  },
}
</script>
<style lang="scss"></style>
