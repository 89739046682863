export const SET_VERIFICATION_STATUS = (state, isSuccess) => {
  if (isSuccess) state.isVerificationSuccess = true
  else state.isVerificationFail = true
}

export const RESET_VERIFICATION_STATUS = (state) => {
  state.isVerificationSuccess = false
  state.isVerificationFail = false
}

export const SET_SHOW_VERIFICATION_POPUP = (state, val) => {
  state.showVeirificationPopup = val
}

export const SET_LOADING = (state, loading) => {
  state.loading = loading
}

export function SET_CURRENT_CART_NUMBER(state, cartNumber) {
  state.cartNumber = cartNumber
}

export function SET_FIELD(state, { field, value }) {
  state[field] = value
}
