<template>
  <ftx-dialog position="standard" persistent :dialog="dialog">
    <template v-slot:DialogContent>
      <q-card class="q-py-md accesscode-dialog">
        <q-card-section horizontal class="row q-col-gutter-lg items-center">
          <q-card-section class="col-12 text-center">
            <q-img
              src="images/offline-mode.gif"
              style="width: 210px; height: 210px"
            />
            <div class="col-12 q-pl-md">
              <div class="text-h6 text-black text-center q-pt-md">
                System is under maintenence
              </div>
              <!-- <div class="text-caption text-center">
                We're preparing to serve you better
              </div> -->
              <div class="text-caption text-center">
                {{ modeDescription }}
              </div>
            </div>
          </q-card-section>
        </q-card-section>
      </q-card>
    </template>
  </ftx-dialog>
</template>

<script>
export default {
  name: 'OfflineModeDialog',
  data() {
    return {
      dialog: 'OfflineModeDialog',
    }
  },
  computed: {
    modeDescription() {
      return (
        (this.businessConfigurations &&
          this.businessConfigurations?.modeDescription) ||
        ''
      )
    },
    shortDescription() {
      return this.modeDescription.slice(0, 125) + '...'
    },
  },
}
</script>
