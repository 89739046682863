import { Platform } from 'quasar'

// export async function getCollections({ commit, rootGetters }, page = 1) {
//   const params = {
//     page,
//     pageSize: 4,
//     codeName: 'home',
//     collectionProductsCount: 15,
//     locationId: rootGetters['persisted/currentLocationId'],
//     ...rootGetters['common/currentDeliveryModes'],
//   }

//   try {
//     if (page == 1) commit('SET_LOADING', true)

//     let { data } = await this.$axiosInstance.get('/collection', { params })
//     if (page == 1) await commit('SET_COLLECTIONS', data.data.items)
//     else await commit('ADD_COLLECTIONS', data.data.items)

//     commit('SET_LOADING', false)

//     return data.data.hasNext
//   } catch (error) {
//     return error
//   }
// }

export async function getCollection({ commit, rootGetters }, seoName) {
  try {
    commit('SET_LOADING', true)
    let { data } = await this.$axiosInstance.get(
      'v1/Collection/GetCollection',
      {
        params: {
          CollectionSeoName: seoName,
          fulfillmentCodeName:
            rootGetters['persisted/selectedFulfillmentCodeName'],
          zipCode: rootGetters['persisted/locationOrShippingZip'],
        },
      }
    )

    commit('SET_COLLECTION', data.data)

    if (Platform?.is?.mobile) {
      commit(
        'common/SET_HEADER_TITLE',
        { title: data.data.title },
        { root: true }
      )
    }

    commit('SET_LOADING', false)

    return data.data
  } catch (error) {
    commit('SET_LOADING', false)
  }
}
