<template>
  <router-link :to="to" v-if="to"> <slot /> </router-link>
  <a v-else href="javascript:void(0);"> <slot /></a>
</template>
<script>
export default {
  name: 'RouterLinkNull',
  props: {
    to: {
      type: [Object, String],
      default: null,
    },
  },
}
</script>
