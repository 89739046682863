export async function sendPersonalInfo({ commit }, body) {
  try {
    let { data } = await this.$axiosInstance.post('/AgeVerification', body)

    if (data.success && data.data?.sessionId) {
      commit('persisted/SET_EAIV_SESSION_ID', data.data.sessionId, {
        root: true,
      })
    }
    return data
  } catch (error) {
    return error
  }
}

export async function checkStatus({ commit }, sessionId) {
  try {
    let { data } = await this.$axiosInstance.get(
      `/AgeVerification/Session/Status/${sessionId}`
    )

    return data
  } catch (error) {
    return error
  }
}

export async function verificationComplete({ commit }, { sessionId, params }) {
  try {
    let { data } = await this.$axiosInstance.post(
      `/AgeVerification/Complete/${sessionId}`,
      {},
      { params }
    )

    return data
  } catch (error) {
    return error
  }
}
