import { hideShowFullSpinner } from 'src/utils'
import { Platform } from 'quasar'

export async function viewOrderReturnDetail({ commit }, orderId) {
  try {
    hideShowFullSpinner(true)
    let response = await this.$axiosInstance.get(`/Returns/${orderId}`)

    if (response?.data?.success) {
      commit('SET_RETURN_ORDER_INFO', {
        [orderId]: response.data.data,
      })
      return response.data.data
    } else {
      return false
    }
  } catch (error) {
    return false
  } finally {
    hideShowFullSpinner(false)
  }
}

export async function returnReasons({ commit, getters }) {
  try {
    if (!getters['returnReason']) {
      let response = await this.$axiosInstance.get('/Returns/Reasons')

      if (response?.data?.success) {
        commit('SET_RETURN_REASONS_INFO', response.data.data)
        return response.data.data
      } else {
        return false
      }
    }
  } catch (error) {
    return false
  }
}

export async function postReturn({ commit }, body) {
  try {
    hideShowFullSpinner(true)
    let response = await this.$axiosInstance.post('/Returns', body)

    if (response?.data?.success) {
      if (Platform?.is?.mobile) {
        NotifyMethods.showSuccess(
          '<strong>Success!</strong> We have initiated process to return your order.'
        )
      } else {
        commit(
          'order/SET_ORDER_ALERT',
          {
            alertKey: 'orderDetailAlert',
            alertData: {
              variant: 'success',
              message:
                '<strong>Success!</strong> We have initiated process to return your order.',
            },
          },
          { root: true }
        )
      }
      this.$router
        .push({
          name: 'OrderDetail',
          query: response.data.data.orderLineItemID,
        })
        .catch(() => {})
      return true
    } else if (response.errors && response.errors.length > 0) {
      if (Platform?.is?.mobile) {
        NotifyMethods.showError(
          `<strong>Error!</strong>${response.errors[0].descreption}.`
        )
      } else {
        commit(
          'order/SET_ORDER_ALERT',
          {
            alertKey: 'startReturnAlert',
            alertData: {
              variant: 'danger',
              message: `<strong>Error!</strong>${response.errors[0].descreption}.`,
            },
          },
          { root: true }
        )
      }
    }
  } catch (error) {
    commit(
      'order/SET_ORDER_ALERT',
      {
        alertKey: 'startReturnAlert',
        alertData: {
          variant: 'danger',
          message:
            '<strong>Error!</strong> something went wrong please try again later.',
        },
      },
      { root: true }
    )
    return false
  } finally {
    hideShowFullSpinner(false)
  }
}
