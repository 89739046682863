export const discounts = (state) => state.discounts || {}

export const loading = (state) => state.loading || false

export const loadingMore = (state) => state.loadingMore || false

export const hasMoreDiscount = (state) => state.hasMoreDiscount || false

export const page = (state) => state.page || 1

export const pageSize = (state) => state.pageSize || 10

export const currentDiscount = (state) => state.currentDiscount

export const loadingCurrentDiscount = (state) => state.loadingCurrentDiscount
