export function UPDATE_CHANGE_LOCATION_FIELD(state, { field, val }) {
  state[field] = val
}

export function SET_TO_LOCATION_N_FULFILLMENT(state, toLocationNFulfillment) {
  state.toLocationNFulfillment = toLocationNFulfillment
}

export function SET_CHANGE_LOCATION_CART_DETAIL(
  state,
  changeLocationCartDetail
) {
  state.changeLocationCartDetail = changeLocationCartDetail
}

export function SET_CHANGE_LOCATION_ADD_TO_CART(state, payload) {
  state.changeLocationAndAddToCart = payload
}

export function SET_STATE(state, { key, value }) {
  if (key) state[key] = value
}
