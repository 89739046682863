export const guestCartNumber = (state) => state.guestCartNumber

export const verifiedGuestCustomerId = (state) => state.guestCustomerId

export const guestUser = (state) => state.guestUser

export const slotBookingId = (state) => {
  return (codeName) => {
    if (codeName == 'sp') return state.storePickupSlotId
    else if (codeName == 'scd') return state.scheduledDeliverySlotId
    else return null
  }
}
