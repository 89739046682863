import { render, staticRenderFns } from "./UserAvatar.vue?vue&type=template&id=08f0e902"
import script from "./UserAvatar.vue?vue&type=script&lang=js"
export * from "./UserAvatar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QImg from 'quasar/src/components/img/QImg.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QAvatar,QSpinner,QImg});
