<template>
  <q-btn
    unelevated
    dense
    no-caps
    outline
    color="dark"
    class="full-width q-mt-md"
    @click="toggleFavorite"
    v-if="buttonType == 'text'"
  >
    <!-- <span class="icon q-mr-sm"></span> -->
    {{ isFavorite ? 'Remove all from favorites' : 'Save all to favorites' }}
  </q-btn>
  <q-btn
    flat
    unelevated
    dense
    no-caps
    @click="toggleFavorite"
    :class="[
      !loading && isFavorite ? 'selected' : '',
      'btn-wishlist',
      'no-underline btn-wishlist',
    ]"
    v-else-if="buttonType == 'link'"
    :label="isFavorite ? 'Remove from favorite' : 'Add to favorite'"
  >
    <span :class="[loading ? 'icon-l' : '', 'icon q-mr-sm']">
      <q-spinner v-show="loading" size="0.75em" />
    </span>
  </q-btn>
  <q-btn
    flat
    unelevated
    dense
    no-caps
    @click.stop="toggleFavorite"
    :class="[!loading && isFavorite ? 'selected' : '', ' btn-wishlist']"
    v-else
    :loading="loading"
  >
    <q-tooltip max-width="180px" :offset="[0, 0]" content-class="bg-dark">
      {{ isFavorite ? 'Remove from ' : 'Add to ' }}favorite
    </q-tooltip>
    <template v-slot:loading>
      <q-spinner size="0.75em" />
    </template>
    <!-- <span class="icon"></span> -->
    <SvgIcon icon="favourite" :use-svg="false" />
  </q-btn>
</template>

<script>
import { convertArrayToObject } from 'src/utils'
import { mapGetters } from 'vuex'

export default {
  name: 'AddToFavorite',
  props: {
    products: {
      type: Object,
      default: () => {
        return new Object({})
      },
    },
    buttonType: {
      type: String,
      default: 'icon',
    },
    selectedProducts: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    FavoriteProducts() {
      if (this.products.hasOwnProperty('productID')) {
        return convertArrayToObject([this.products], 'productID')
      } else if (Array.isArray(this.products)) {
        return convertArrayToObject([this.products], 'productID')
      }
      return this.products
    },
    isFavorite() {
      for (let key in this.FavoriteProducts) {
        let product = this.FavoriteProducts[key]

        if (
          (!this.selectedProducts.length ||
            this.selectedProducts.includes(key)) &&
          !this.isProductMarkedAsFavorite(product.productID, product.isFavorite)
        ) {
          return false
        }
      }

      return true
    },
    requestObject() {
      let objRequest = []

      for (let key in this.FavoriteProducts) {
        let product = this.FavoriteProducts[key]

        if (
          !this.selectedProducts.length ||
          this.selectedProducts.includes(key)
        ) {
          objRequest.push({
            productID: product.productID,
            markAsFavorite: !this.isFavorite,
          })
        }
      }

      return objRequest
    },
    ...mapGetters('favorites', ['isProductMarkedAsFavorite']),
  },
  methods: {
    toggleFavorite() {
      if (this.products?.isDummyProd) return false

      let toggleFavoriteCore = () => {
        this.loading = true
        this.$store
          .dispatch('favorites/toggleProductIsFavorite', this.requestObject)
          .finally(() => {
            this.loading = false
          })
      }

      if (!this.isLoggedIn)
        return this.changeDialogState({
          dialog: 'accountDrawer',
          val: !this.getDialogState('accountDrawer'),
        })

      if (!this.customerExists)
        return this.signUpConfirm().onOk(() => {
          toggleFavoriteCore()
        })

      toggleFavoriteCore()
    },
  },
}
</script>
