import { formatBookSlotTime } from 'src/utils'
import formatDate from 'src/filters/formatDate'

export function customerSPSlot(state) {
  return state.storePickupSlot
}

export function customerSPSlotId(state, getters) {
  return getters.customerSPSlot ? getters.customerSPSlot.slotId : null
}

export function customerSPSlotBookingID(state, getters) {
  return getters.customerSPSlot ? getters.customerSPSlot.slotBookingID : null
}

export function customerSCDSlot(state) {
  return state.scheduledDeliverySlot
}

export function customerSCDSlotId(state, getters) {
  return getters.customerSCDSlot ? getters.customerSCDSlot.slotId : null
}

export function customerSCDSlotBookingID(state, getters) {
  return getters.customerSCDSlot ? getters.customerSCDSlot.slotBookingID : null
}

export function formatBookedSlot(state, getters, rootState, rootGetters) {
  return (slotType) => {
    let targetSlot = getters[`customer${slotType.toUpperCase()}Slot`]
    if (targetSlot && rootGetters['common/checkSlotBookingRequired'](slotType))
      return `${formatDate(
        targetSlot.bookingDate,
        'ddd, MMM D'
      )} - ${formatBookSlotTime(targetSlot.slotTime)}`

    return null
  }
}
