import { render, staticRenderFns } from "./ProductCard.vue?vue&type=template&id=1ea420cf"
import script from "./ProductCard.vue?vue&type=script&lang=js"
export * from "./ProductCard.vue?vue&type=script&lang=js"
import style0 from "./ProductCard.vue?vue&type=style&index=0&id=1ea420cf&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QColor from 'quasar/src/components/color/QColor.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCheckbox,QBtn,QImg,QSpinner,QCardSection,QTooltip,QSelect,QCardActions,QColor,QField});
