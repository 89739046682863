export default function () {
  return {
    addresses: {},
    billingAddresses: {},
    selectedAddress: {},
    loading: true,
    addressMapping: {},
    alertData: {},
  }
}
