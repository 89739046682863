export const getDefaultState = () => {
  return {
    currentLocationDetail: null,
    latitude: null,
    longitude: null,
    milesOptions: [
      {
        label: 'All',
        val: '0',
      },
      {
        label: '5 Miles',
        val: '5',
      },
      {
        label: '10 Miles',
        val: '10',
      },
      {
        label: '15 Miles',
        val: '15',
      },
      {
        label: '20 Miles',
        val: '20',
      },
      {
        label: '25 Miles',
        val: '25',
      },
      {
        label: '25+ Miles',
        val: '-1',
      },
    ],
    toLocationNFulfillment: null,
    changeLocationCartDetail: null,
    changeLocationAndAddToCart: null,
  }
}

export default getDefaultState()
