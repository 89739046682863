<template>
  <SplitSection v-if="component.type == 'section'" :section="component" />
  <component
    v-else
    v-bind:is="componentMapper[component.type]"
    :component="component"
    :isPreview="isPreview"
  />
</template>

<script>
export default {
  name: 'ColumnComponent',
  props: {
    component: {
      type: Object,
      default: new Object(),
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    componentMapper() {
      return {
        imageSlider: 'ImageSlider',
        collection:
          this.isPreview ||
          (this.showAgeVerificationBtn && this.component.isAgeRestricted)
            ? 'CollectionPreview'
            : 'Collection',
        image: 'SingleImage',
      }
    },
  },
}
</script>
