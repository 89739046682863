export function SET_LOADING(state, loading) {
  state.loading = loading
}

export function SET_COLLECTION(state, collection) {
  state.collection = collection
}

export function ADD_COLLECTIONS(state, collections) {
  state.collections = state.collections.concat(collections)
}

// export function RESET_COLLECTIONS(state) {
//   state.collections = []
// }

// export function SET_COLLECTIONS(state, collections) {
//   state.collections = collections
// }
