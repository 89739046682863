export default (address = {}) => {
  let formatString = ''

  if (typeof address === 'object' && address !== null) {
    if (address.line1) formatString = formatString.concat(address.line1)
    if (address.line2)
      formatString = formatString.concat('<br />', address.line2)

    // if (address.city) formatString = formatString.concat('<br />', address.city)
    if (address.city)
      formatString = formatString
        ? formatString.concat('<br />', address.city)
        : address.city
    if (address.state) formatString = formatString.concat(', ', address.state)

    if (address.zip) formatString = formatString.concat('<br />', address.zip)
  } else return '-'

  return formatString
}
