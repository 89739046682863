<template>
  <div class="col-12">
    <!-- <MobileSwiper v-if="isMobile && false" :products="products" /> -->
    <WebSwiper
      :products="products"
      :productsInRow="productsInRow"
      :title="title"
      :seoName="seoName"
    />
  </div>
</template>

<script>
import WebSwiper from './WebSwiper'
// import MobileSwiper from './MobileSwiper'
export default {
  name: 'Swiper',
  props: {
    products: {
      type: [Array, Boolean],
      default: () => {
        return []
      },
    },
    productsInRow: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    seoName: {
      type: String,
      default: '',
    },
  },
  components: {
    WebSwiper,
    // MobileSwiper
  },
}
</script>
