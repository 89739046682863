import { ClientCredTokenAgent } from 'boot/axios'
import { validateJWTToken } from 'src/utils'

export async function getToken({ commit, getters, rootGetters }) {
  if (!rootGetters['auth/isLoggedIn']) {
    let token = getters['clientAccessToken']

    // if token is expired
    if (!validateJWTToken(token)) {
      try {
        let { data } = await ClientCredTokenAgent.getToken()

        // await setAxiosHeaders(data.access_token, data.token_type)
        await commit('SET_CLIENT_CRED', {
          access_token: data.access_token,
          // token_type: data.token_type,
        })
      } catch (error) {
        commit('common/SET_STORE_CONFIGURATION_LOADED', true, { root: true })
      }
    }
    // if the token is not expired set old one
    else {
      // await setAxiosHeaders(token)
    }
  }
  // if the user is login
  else {
    await commit('SET_CLIENT_CRED', null)
    // await setAxiosHeaders(rootGetters['auth/accessToken'])
  }
}

export async function setServiceLocation(
  { commit, dispatch, rootGetters },
  data = {}
) {
  const { location, fulfillment, selectedZipCode, deliveryAddress } = data

  let fulfillmentId =
    fulfillment?.fulfillmentID ||
    fulfillment?.fulfillmentId ||
    fulfillment?.fulfillmentMethodID ||
    ''

  if (
    rootGetters['auth/customerExists'] &&
    location?.locationId &&
    fulfillmentId
  ) {
    try {
      await this.$axiosInstance.put('/Customer/Location/SetPreferred', {
        locationId: location.locationId,
        fulfillmentMethodId: fulfillmentId,
      })
    } catch (error) {}
  }

  commit('SELECT_LOCATION_AND_FULFILLMENTS', {
    location,
    fulfillment,
    selectedZipCode,
  })

  deliveryAddress &&
    commit(
      'persistedLocal/SET_STATE',
      {
        field: 'deliveryAddress',
        value: deliveryAddress,
      },
      { root: true }
    )

  return true
}

// export async function fetchLocation({ commit, rootGetters, getters }, params) {
//   let isNewLocation = true

//   try {
//     let { data } = await this.$axiosInstance.get('/location', { params })

//     if (data && data.data && data.data.items[0]) {
//       let location = data.data.items[0]
//       if (
//         location.locationId == getters['currentLocationId'] &&
//         location.showSetPreffered
//       ) {
//         isNewLocation = false
//       }

//       // if (location.showSetPreffered) {
//       //   await commit('SELECT_LOCATION_AND_FULFILLMENTS', {
//       //     location,
//       //   })
//       // } else await commit('SELECT_LOCATION_AND_FULFILLMENTS')
//     } else await commit('SELECT_LOCATION_AND_FULFILLMENTS')

//     return isNewLocation
//   } catch (error) {
//     return false
//   }
// }
