export function SET_GUEST_CART_NUMBER(state, cartNumber) {
  state.guestCartNumber = cartNumber
}

export function SET_GUEST_CUSTOMER_ID(state, customerId) {
  state.guestCustomerId = customerId
}

export const SET_GUEST_USER = (state, user) => (state.guestUser = user)

export function SET_SELECTED_SLOT_ID(state, { key, val }) {
  if (val) state[key] = val
  else state[key] = null
}

export function RESET_SLOTS(state) {
  state.storePickupSlotId = null
  state.scheduledDeliverySlotId = null
}
