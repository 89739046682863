var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['template-slider q-mb-md']},[(Object.keys(_vm.visibleChild).length)?_c('swiper',{attrs:{"options":{
      slidesPerView: 1,
      loop: !!_vm.component.infinite || false,
      autoHeight: true,
      navigation: {
        nextEl: `#swiper-button-next-${_vm.component.id}`,
        prevEl: `#swiper-button-prev-${_vm.component.id}`,
      },
      pagination: {
        el: `#swiper-pagination-${_vm.component.id}`,
        clickable: true,
      },
      autoplay: _vm.component.autoplay
        ? {
            delay: _vm.component.autoplayDuration * 1000,
            disableOnInteraction: false,
          }
        : false,
    }},nativeOn:{"click":function($event){return _vm.slideClicked.apply(null, arguments)}}},[_vm._l((_vm.visibleChild),function(child,key){return _c('swiper-slide',{key:key,class:[
        'ftx-grid-layer ',
        _vm.showAgeVerificationBtn && child.isAgeRestricted
          ? 'age-restricted-slide'
          : '',
      ],attrs:{"img-src":child.imageUrl || child.defaultImage,"name":child.id,"id":`child-${child.id}`}},[_c('div',{staticClass:"row fit justify-start items-center no-wrap template-slider__image"},[_c('SlideLink',{staticClass:"full-width full-height block",attrs:{"slide":child}},[(
              !(_vm.showAgeVerificationBtn && child.isAgeRestricted) &&
              (child.contentSubTitle ||
                child.contentTitle ||
                child.buttonLabel)
            )?_c('div',{staticClass:"template-slider__overlay",style:({
              opacity: child.opacity / 100,
            })}):_vm._e(),(_vm.showAgeVerificationBtn && child.isAgeRestricted)?_c('img',{staticClass:"full-width full-height block",attrs:{"src":`https://placehold.co/${child.imageWidth || 1246}x${
              child.imageHeight || 415
            }/F5F5F5/black?text=Age+Restricted`,"alt":""}}):_c('img',{staticClass:"full-width full-height block",attrs:{"src":child.imageUrl || child.defaultImage,"contain":""}})])],1),(
          !(_vm.showAgeVerificationBtn && child.isAgeRestricted) &&
          (child.contentSubTitle || child.contentTitle || child.buttonLabel)
        )?_c('div',{class:[child.contentPosition, 'q-pa-xl template-slider__text']},[_c('div',{staticClass:"template-slider__subtitle",domProps:{"innerHTML":_vm._s(child.contentSubTitle)}}),_c('div',{staticClass:"template-slider__title",domProps:{"innerHTML":_vm._s(child.contentTitle)}}),_c('div',[(_vm.isCordova || !child.openInNewTab)?_c('q-btn',{directives:[{name:"show",rawName:"v-show",value:(child.buttonLabel),expression:"child.buttonLabel"}],staticClass:"q-mt-sm",attrs:{"unelevated":"","no-caps":"","outline":"","label":child.buttonLabel,"to":_vm.getButtonLink(child)}}):_c('q-btn',{directives:[{name:"show",rawName:"v-show",value:(child.buttonLabel),expression:"child.buttonLabel"}],staticClass:"q-mt-sm",attrs:{"unelevated":"","no-caps":"","outline":"","label":child.buttonLabel},on:{"click":function($event){return _vm.handleNavigation(child)}}})],1)]):_vm._e(),_c('AgeRestrictedBlur',{attrs:{"isPreview":_vm.isPreview,"component":child,"blur":false,"addAgeClickEvent":false}})],1)}),(_vm.component.arrows)?_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev","id":`swiper-button-prev-${_vm.component.id}`},slot:"button-prev"}):_vm._e(),(_vm.component.arrows)?_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next","id":`swiper-button-next-${_vm.component.id}`},slot:"button-next"}):_vm._e(),(_vm.component.navigation)?_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination","id":`swiper-pagination-${_vm.component.id}`},slot:"pagination"}):_vm._e()],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }