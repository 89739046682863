import Vue from 'vue'

export function PUSH_CART_DETAIL(state, { cartId, cartDetail }) {
  if (!Object.keys(cartDetail).length) {
    Vue.delete(state.cartDetail, cartId)
  } else {
    let localCartDetail = {
      ...cartDetail,
      fulfillments: {},
    }

    if (cartDetail?.fulfillments) {
      cartDetail.fulfillments.forEach((fulfillment) => {
        let breakIndex

        for (let i = fulfillment.shoppingCartItems.length - 1; i >= 0; i--) {
          if (!fulfillment.shoppingCartItems[i].isLinkedProduct) break
          else {
            fulfillment.shoppingCartItems.every((shoppingCartItem) => {
              if (
                shoppingCartItem.orderLineItemID ==
                fulfillment.shoppingCartItems[i].parentLinkedLineItemId
              ) {
                if (!shoppingCartItem.linkedProducts)
                  shoppingCartItem.linkedProducts = []

                shoppingCartItem.linkedProducts.push(
                  fulfillment.shoppingCartItems[i]
                )

                return false
              }
              return true
            })

            breakIndex = i
          }
        }

        let shoppingCartItems = {}

        // if (
        //   state.cartDetail[cartId]?.fulfillments?.[
        //     fulfillment.fulfillmentMethodID
        //   ]?.shoppingCartItems &&
        //   !refresh
        // ) {
        //   shoppingCartItems = {
        //     ...state.cartDetail[cartId]?.fulfillments?.[
        //       fulfillment.fulfillmentMethodID
        //     ]?.shoppingCartItems,
        //   }
        // }

        for (let s = 0; s < fulfillment.shoppingCartItems.length; s++) {
          const item = fulfillment.shoppingCartItems[s]

          if (item?.substituteProductDetails?.length > 0) {
            item.substituteProductDetails.push({
              image: '',
              price: 0,
              productID: '00000000-0000-0000-0000-000000000000',
              productName: 'No Preference',
              qtyOnHand: 0,
              seoName: '',
              substituteProductID: '00000000-0000-0000-0000-000000000000',
            })
          }

          shoppingCartItems[item.orderLineItemID] = item

          if (breakIndex == s + 1) break
        }

        localCartDetail.fulfillments = {
          ...localCartDetail.fulfillments,
          [fulfillment.fulfillmentMethodID]: {
            ...fulfillment,
            shoppingCartItems,
          },
        }
      })
    }

    localCartDetail = calculateCartTotal(localCartDetail)
    state.cartDetail = Object.assign({}, state.cartDetail, {
      [cartId]: localCartDetail,
    })
  }
}

export function ADD_UPDATE_CART_DETAIL(state, { cartId, cartDetail }) {
  if (state.cartDetail[cartId]) {
    let localCartDetail = {
      ...state.cartDetail[cartId],
    }

    if (cartDetail?.fulfillments) {
      cartDetail.fulfillments.forEach((fulfillment) => {
        let breakIndex

        for (let i = fulfillment.shoppingCartItems.length - 1; i >= 0; i--) {
          if (!fulfillment.shoppingCartItems[i].isLinkedProduct) break
          else {
            fulfillment.shoppingCartItems.every((shoppingCartItem) => {
              if (
                shoppingCartItem.orderLineItemID ==
                fulfillment.shoppingCartItems[i].parentLinkedLineItemId
              ) {
                if (!shoppingCartItem.linkedProducts)
                  shoppingCartItem.linkedProducts = []

                shoppingCartItem.linkedProducts.push(
                  fulfillment.shoppingCartItems[i]
                )

                return false
              }
              return true
            })

            breakIndex = i
          }
        }

        for (let s = 0; s < fulfillment.shoppingCartItems.length; s++) {
          const item = fulfillment.shoppingCartItems[s]

          if (item?.substituteProductDetails?.length > 0) {
            item.substituteProductDetails.push({
              image: '',
              price: 0,
              productID: '00000000-0000-0000-0000-000000000000',
              productName: 'No Preference',
              qtyOnHand: 0,
              seoName: '',
              substituteProductID: '00000000-0000-0000-0000-000000000000',
            })
          }

          if (
            localCartDetail.fulfillments?.[fulfillment.fulfillmentMethodID]
              ?.shoppingCartItems
          ) {
            localCartDetail.fulfillments[
              fulfillment.fulfillmentMethodID
            ].shoppingCartItems[item.orderLineItemID] = item
          }

          if (breakIndex == s + 1) break
        }
      })
    }

    localCartDetail = calculateCartTotal(localCartDetail)

    state.cartDetail = Object.assign({}, state.cartDetail, {
      [cartId]: localCartDetail,
    })
  }
}

export function DELETE_CART_ITEM(
  state,
  { orderItemId, cartNumber, fulfillmentId }
) {
  if (state.cartDetail[cartNumber]) {
    let localCartDetail = {
      ...state.cartDetail[cartNumber],
    }

    delete localCartDetail.fulfillments[fulfillmentId].shoppingCartItems[
      orderItemId
    ]

    localCartDetail = calculateCartTotal(localCartDetail)

    state.cartDetail = Object.assign({}, state.cartDetail, {
      [cartNumber]: localCartDetail,
    })
  }
}

export function SET_CURRENT_CART_NUMBER(state, cartNumber) {
  state.cartNumber = cartNumber
}

export function SET_SUBSTITUTION(state, substitute) {
  state.substituteDetail = Object.assign({}, state.substituteDetail, substitute)
}

export function SET_RECENT_SUBSTITUTE(state, substitute) {
  state.recentSubstituteDetail = Object.assign(
    {},
    state.recentSubstituteDetail,
    substitute
  )
}

export function UPDATE_RECENT_SUBSTITUTE_ON_EDITORDER(state, order) {
  if (
    Object.keys(state.recentSubstituteDetail) &&
    Object.keys(state.recentSubstituteDetail).length > 0 &&
    order &&
    order[Object.keys(order)[0]]?.trackOrderLineItems
  ) {
    order[Object.keys(order)[0]].trackOrderLineItems.forEach((lineItem) => {
      if (
        (state.recentSubstituteDetail[lineItem.originalLineItemID] ||
          state.recentSubstituteDetail[lineItem.orderLineItemID]) &&
        lineItem.originalLineItemID != '00000000-0000-0000-0000-000000000000'
      ) {
        let substitute = state.recentSubstituteDetail[
          lineItem.originalLineItemID
        ]
          ? state.recentSubstituteDetail[lineItem.originalLineItemID]
          : state.recentSubstituteDetail[lineItem.orderLineItemID]

        if (substitute) {
          REMOVE_RECENT_SUBSTITUTE(
            state,
            state.recentSubstituteDetail[lineItem.originalLineItemID]
              ? lineItem.originalLineItemID
              : lineItem.orderLineItemID
          )
          SET_RECENT_SUBSTITUTE(state, {
            [lineItem.orderLineItemID]: {
              previousOrderLineItemID: lineItem.originalLineItemID,
              allowSubstitute: substitute.allowSubstitute,
              orderLineItemID: lineItem.orderLineItemID,
              selectedSubstituteProduct: substitute.selectedSubstituteProduct,
              substituteProducts: substitute.substituteProducts,
            },
          })
        }
      } else if (
        Object.values(state.recentSubstituteDetail).find(
          (x) =>
            x.previousOrderLineItemID == lineItem.originalLineItemID ||
            x.previousOrderLineItemID == lineItem.orderLineItemID
        ) &&
        lineItem.originalLineItemID
      ) {
        let substituteElse = Object.values(state.recentSubstituteDetail).find(
          (x) =>
            x.previousOrderLineItemID == lineItem.originalLineItemID ||
            x.previousOrderLineItemID == lineItem.orderLineItemID
        )
        if (
          substituteElse.previousOrderLineItemID !=
          '00000000-0000-0000-0000-000000000000'
        ) {
          REMOVE_RECENT_SUBSTITUTE(state, substituteElse.orderLineItemID)

          SET_RECENT_SUBSTITUTE(state, {
            [lineItem.orderLineItemID]: {
              previousOrderLineItemID: lineItem.originalLineItemID,
              allowSubstitute: substituteElse.allowSubstitute,
              orderLineItemID: lineItem.orderLineItemID,
              selectedSubstituteProduct:
                substituteElse.selectedSubstituteProduct,
              substituteProducts: substituteElse.substituteProducts,
            },
          })
        }
      }
    })
  } else if (
    !Object.keys(state.recentSubstituteDetail) ||
    (Object.keys(state.recentSubstituteDetail).length <= 0 &&
      order &&
      order[Object.keys(order)[0]]?.trackOrderLineItems)
  ) {
    order[Object.keys(order)[0]].trackOrderLineItems.forEach((lineItem) => {
      if (
        lineItem.preferredSubstituteProductID &&
        lineItem.preferredSubstituteProductID !=
          '00000000-0000-0000-0000-000000000000'
      ) {
        lineItem.substituteProductListData.forEach((substitutes) => {
          let selectedSub = substitutes.substituteProductDetails.find(
            (x) =>
              x.substituteProductID == lineItem.preferredSubstituteProductID
          )
          if (selectedSub) {
            SET_RECENT_SUBSTITUTE(state, {
              [lineItem.orderLineItemID]: {
                allowSubstitute: true,
                orderLineItemID: lineItem.orderLineItemID,
                selectedSubstituteProduct: selectedSub.substituteProductID,
                substituteProducts: [],
              },
            })
          }
        })
      }
    })
  }
}

export function REMOVE_RECENT_SUBSTITUTE(state, orderItemId) {
  Vue.delete(state.recentSubstituteDetail, orderItemId)
}

export function RESET_RECENT_SUBSTITUTE(state) {
  state.recentSubstituteDetail = Object.assign({}, {})
}

export function SET_PICKUP_INFO(state, pickupInfo) {
  let detail = state.currentOrderParameters
  for (let key in detail.orderFulfillments) {
    let fulfillment = detail.orderFulfillments[key]
    if (pickupInfo.fulfillmentMethodID == fulfillment.fulfillmentMethodID) {
      detail.orderFulfillments[key].pickupPerson = pickupInfo.pickupPerson
    }
  }
  state.currentOrderParameters = Object.assign({}, detail)
}

export function SET_ORDER_ADDRESS(state, addressInfo) {
  let detail = state.currentOrderParameters
  for (let key in detail.orderFulfillments) {
    let fulfillment = detail.orderFulfillments[key]
    if (addressInfo.fulfillmentMethodID == fulfillment.fulfillmentMethodID) {
      detail.orderFulfillments[key].addressID = addressInfo.addressID
    }
  }
  state.currentOrderParameters = Object.assign({}, detail)
}

export function SET_CART_PAYMENT_METHOD(state, paymentMethodId) {
  Vue.set(state.currentOrderParameters, 'paymentMethodID', paymentMethodId)
}

export function SET_CURRENT_ORDER_DETAIL(state, orderDetail) {
  state.currentOrderParameters = Object.assign({}, orderDetail)
}

export function SET_CHECKOUT_IN_REVIEW_MODE(state, checkoutInReviewMode) {
  state.checkoutInReviewMode = checkoutInReviewMode
}

export function SET_LOADING(state, loading) {
  state.loading = loading
}

export function SHOW_ALERT_ERROR(state, errorObject) {
  state.alertDetail = Object.assign({}, errorObject)
}

export function COUNT_DOWN_CHANGE(state, dismissCountDown) {
  state.alertDetail = Object.assign({}, state.alertDetail, {
    showCountDown: dismissCountDown,
  })
}

export function SET_OUT_OF_STOCK_PRODUCTS(state, { cartId, products }) {
  state.outOfStockProducts = Object.assign({}, state.outOfStockProducts, {
    [cartId]: products,
  })
}

export function REMOVE_OUT_OF_STOCK_PRODUCT(state, { cartId, orderItemId }) {
  let products = state.outOfStockProducts[cartId].filter(
    (outOfStockProduct) => outOfStockProduct.orderLineItemID != orderItemId
  )
  state.outOfStockProducts = Object.assign({}, state.outOfStockProducts, {
    [cartId]: products,
  })
}

export function RESET_OUT_OF_STOCK_PRODUCTS(state, { cartId }) {
  state.outOfStockProducts = Object.assign({}, state.outOfStockProducts, {
    [cartId]: [],
  })
}

export function SET_OUT_OF_STOCK_PRODUCT_QTY(state, { cartId, product }) {
  let products = [...state.outOfStockProducts[cartId]]
  let productIndex = products.findIndex(
    (outOfStockProduct) =>
      outOfStockProduct.orderLineItemID == product.orderLineItemID
  )
  if (productIndex != -1)
    state.outOfStockProducts = Object.assign({}, state.outOfStockProducts, {
      [cartId]: Object.assign([], products, { [productIndex]: product }),
    })
}

export function SET_PAY_AT_STORE(state, payAtStore) {
  state.isPayAtStoreAvailable = payAtStore
}

export function SET_SHIPPING_RATES(state, rates) {
  state.shippingRates = rates
}

export function SET_ALERT(state, { alertKey, alertData }) {
  if (typeof alertData === 'object' && alertData !== null)
    Vue.set(state.alertData, alertKey, alertData)
  else Vue.set(state.alertData, alertKey, null)
}

export function SET_ALLOW_PLACE_ORDER(state, val) {
  state.allowPlaceOrder = val
}
export function SET_TIP_CONFIRM(state, val) {
  state.isTipConfirmed = val
}

function calculateCartTotal(cartDetail) {
  if (cartDetail) {
    let subTotalDisplay = 0,
      totalItems = 0

    for (const fId in cartDetail.fulfillments) {
      const fulfillment = cartDetail.fulfillments[fId]
      let totalQTY = 0

      for (let olId in fulfillment.shoppingCartItems) {
        const item = fulfillment.shoppingCartItems[olId]

        totalQTY += item.qty
        totalItems += item.qty
        subTotalDisplay += item.totalPrice

        if (item?.linkedProducts?.length) {
          item.linkedProducts.forEach((linkedProduct) => {
            totalQTY += linkedProduct.qty
            totalItems += linkedProduct.qty
            subTotalDisplay += linkedProduct.totalPrice
          })
        }
      }
      fulfillment.totalQTY = totalQTY
    }

    cartDetail.subTotalDisplay = subTotalDisplay
    cartDetail.totalItems = totalItems
  }

  return cartDetail
}

export function SET_CHECKOUT_SELECTION(state, data = null) {
  if (data) {
    state.checkoutSelection = {
      ...(state.checkoutSelection || {}),
      ...data,
    }
  } else if (state.checkoutSelection) state.checkoutSelection = null
}
