<template>
  <div class="empty">
    <div class="empty-inner text-center">
      <p><img :width="imgWidth" :src="image" alt=""/></p>
      <slot />
      <p v-if="btnText">
        <q-btn
          unelevated
          no-caps
          rounded
          outline
          :to="to"
          padding="sm"
          color="tertiary"
          @click="btnClick"
          :label="btnText"
          :class="btnClass"
        />
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    image: {
      type: String,
      default: 'images/empty-product.png',
    },
    btnText: {
      type: String,
      default: null,
    },
    btnClick: {
      type: Function,
      default: () => {},
    },
    btnClass: {
      type: String,
      default: 'q-px-lg',
    },
    to: {
      type: String,
      default: '',
    },
    imgWidth: {
      type: Number,
      default: 100,
    },
  },
}
</script>

<style lang="scss">
.empty {
  margin-top: 25px;
  p {
    font-size: 13px;
  }
   @media (min-width: 1921px) {
    p {
        font-size: 18px;
    }
  }
}
</style>
