<template>
  <q-dialog
    ref="dialog"
    :position="isMobile ? 'bottom' : 'standard'"
    @hide="onDialogHide"
    :maximized="isMobile"
    :transition-show="isMobile ? 'slide-up' : 'jump-up'"
    :transition-hide="isMobile ? 'slide-down' : 'jump-down'"
  >
    <q-card class="mobileCardDialogue removeAccount">
      <q-card-section class="row">
        <q-space />
        <q-btn
          unelevated
          no-caps
          round
          icon="close"
          v-close-popup
          class="no-hover"
        />
      </q-card-section>

      <q-card-section class="inner q-pt-none">
        <h5 class="text-center">Account closure is a permanent action</h5>
        <!-- <p class="text-center">
          Please select the main reason for closing your FTx Commerce account
          (Optional)
        </p> -->
        <!-- <div class="form-field">
          <q-select
            outlined
            v-model="model"
            :options="options"
            :options-dense="true"
            label="Choose your reason for close account"
          />
        </div> -->
        <div class="form-field">
          <q-checkbox
            v-model="confirm"
            label="Yes, I want to permanently close my
Account and delete my data."
          />
        </div>
        <div class="form-action q-mb-none text-center">
          <q-btn
            unelevated
            no-caps
            rounded
            size="16px"
            padding="12px 30px"
            color="primaryOnBody"
            label="Close My Account"
            :disable="!confirm"
            @click="onOKClick"
          />
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SignUpConfirm',
  data() {
    return {
      confirm: false,
    }
  },
  methods: {
    // following method is REQUIRED
    // (don't change its name --> "show")
    show() {
      this.$refs.dialog.show()
    },

    // following method is REQUIRED
    // (don't change its name --> "hide")
    hide() {
      this.$refs.dialog.hide()
    },

    onDialogHide() {
      // required to be emitted
      // when QDialog emits "hide" event
      this.$emit('hide')
    },

    async onOKClick() {
      // on OK, it is REQUIRED to
      // emit "ok" event (with optional payload)
      // before hiding the QDialog

      this.$emit('ok')
      // or with payload: this.$emit('ok', { ... })

      // then hiding dialog
      this.hide()
    },

    onCancelClick() {
      // we just need to hide dialog
      this.hide()
    },
  },
}
</script>
