import { mapGetters } from 'vuex'

import {
  setAVSessionID,
  removeAVSessionID,
  tempCacheStorage,
} from 'src/boot/axios'
import {
  ageRestrictedBrowsingPages,
  ageRestrictedNonBrowsingPages,
} from 'src/constants'
import { isDateEqual } from 'src/utils'

var FTXIdentityLite = null

// var headerHeight = 0

export default {
  data() {
    return {
      interval: null,
    }
  },
  computed: {
    ...mapGetters('persisted', ['showEAIVPage']),
    getHeaderHeight() {
      return this.headerHeight
    },
    instanceLoaded() {
      return !!FTXIdentityLite
    },
    ageRestrictedPages() {
      return [...ageRestrictedBrowsingPages, ...ageRestrictedNonBrowsingPages]
    },
    getEAIVExternalURL() {
      return process.env.EAIV_ExternalURL
    },
  },
  methods: {
    setHeaderHeight() {
      let locationHeight = 0
      if (document.querySelector('.q-header')) {
        if (this.isMobile)
          locationHeight = document.querySelector(
            '.q-header .h-location'
          ).clientHeight
        this.headerHeight = Math.round(
          document.querySelector('.q-header').clientHeight - locationHeight
        )
      }
    },
    initEAIVPopup(params) {
      const ftxIdentityLiteInstance = new FTXIdentityLite(params)
      ftxIdentityLiteInstance.start()
    },
    setIdentityInstance(val) {
      FTXIdentityLite = val
    },
    identitySuccessHandler() {
      let eaivSessionId = this.eaivPersistedSessionId,
        routeName = this.$route.name,
        mismatchedFieldName = []
      this.$store.commit('ageVerification/SET_LOADING', true)

      this.$store
        .dispatch('ageVerification/checkStatus', eaivSessionId)
        .then((response) => {
          this.$store.commit('ageVerification/SET_LOADING', false)
          if (response.success && response.data?.isVerified === true) {
            let personalInfo = response.data?.personalInfo,
              preFillInformation = response.data?.preFillInformation

            if (
              (this.isLoggedIn ||
                (!this.isLoggedIn && routeName == 'Checkout')) &&
              personalInfo &&
              preFillInformation &&
              (personalInfo?.firstName != preFillInformation?.firstName ||
                personalInfo?.lastName != preFillInformation?.lastName ||
                isDateEqual(personalInfo?.dob, preFillInformation?.dob) ==
                  false)
            ) {
              if (personalInfo?.firstName != preFillInformation?.firstName)
                mismatchedFieldName.push('firstName')
              if (personalInfo?.lastName != preFillInformation?.lastName)
                mismatchedFieldName.push('lastName')
              if (
                isDateEqual(personalInfo?.dob, preFillInformation?.dob) == false
              )
                mismatchedFieldName.push('dob')

              this.changeDialogState({
                dialog: 'MismatchPersonalInfo',
                val: true,
                properties: {
                  personalInfo,
                  preFillInformation,
                  mismatchedFieldName,
                  onContinue: this.verificationComplete,
                  onCancel: this.onCancelVerification,
                },
              })
            } else {
              this.verificationComplete(eaivSessionId, routeName, true)
            }
          }
        })
        .catch(() => {
          this.$store.commit('ageVerification/SET_LOADING', false)
        })
    },
    identityErrorHandler(msg) {
      let eaivSessionId = this.eaivPersistedSessionId,
        routeName = this.$route.name
      this.$store.commit('ageVerification/SET_LOADING', true)

      this.$store
        .dispatch('ageVerification/verificationComplete', {
          sessionId: eaivSessionId,
          params: { isInfoMismatched: false },
        })
        .then((response) => {
          this.$store.commit('ageVerification/SET_VERIFICATION_STATUS', false)
          this.$store.commit('persisted/SET_EAIV_SESSION_ID', null)
          removeAVSessionID()

          if (this.ageRestrictedPages.includes(routeName) === true) {
            this.$root.$emit('eaivVerificationFailed', {
              eaivSessionId: eaivSessionId,
              routeName: this.$route.name,
              isPopup: true,
            })
          } else {
            tempCacheStorage.clear()
            this.$store.commit('ageVerification/SET_LOADING', false)
            if (!this.showEAIVPage)
              this.changeDialogState({
                dialog: 'VerificationFailDialog',
                val: true,
                properties: {},
              })
          }
        })
        .catch(() => {
          this.$store.commit('ageVerification/SET_LOADING', false)
        })
    },
    verificationComplete(eaivSessionId, routeName, isSuccess = true) {
      tempCacheStorage.clear()
      this.$store.commit('ageVerification/SET_LOADING', true)
      this.$store
        .dispatch('ageVerification/verificationComplete', {
          sessionId: eaivSessionId,
          params: { isInfoMismatched: false },
        })
        .then((response) => {
          if (this.ageRestrictedPages.includes(routeName) === true) {
            setAVSessionID(eaivSessionId)
            this.$root.$emit('eaivVerificationSuccess', {
              eaivSessionId,
              routeName,
              isPopup: true,
            })
            this.$store.commit('persisted/SET_EAIV_VERIFICATION_STATUS', true)
            this.$store.commit('ageVerification/SET_VERIFICATION_STATUS', true)

            if (this.isLoggedIn) {
              this.$store.dispatch('auth/getUserInfo')
              this.$store.commit('customer/SET_CUSTOMER', {
                ...this.customer,
                isVerifiedOnline: true,
              })
            }

            this.changeDialogState({
              dialog: 'MismatchPersonalInfo',
              val: false,
              properties: {},
            })
          } else {
            this.$store.commit('persisted/SET_EAIV_VERIFICATION_STATUS', true)
            this.$store.commit('ageVerification/SET_VERIFICATION_STATUS', true)
            setAVSessionID(eaivSessionId)
            this.showSuccess('Age Verified Successfully.')
            this.$store.commit('ageVerification/SET_LOADING', false)

            if (this.isLoggedIn) {
              this.$store.dispatch('auth/getUserInfo')
              this.$store.commit('customer/SET_CUSTOMER', {
                ...this.customer,
                isVerifiedOnline: true,
              })
            }

            this.changeDialogState({
              dialog: 'MismatchPersonalInfo',
              val: false,
              properties: {},
            })
          }

          if (
            !this.isLoggedIn &&
            routeName != 'Checkout' &&
            this.businessConfigurations?.isStoreAgeRestricted &&
            this.businessConfigurations?.enableAgeRestrictedBrowsing
          )
            this.changeDialogState({
              dialog: 'AskForSignup',
              val: true,
              properties: {},
            })
        })
        .catch((err) => {
          this.$store.commit('ageVerification/SET_LOADING', false)
        })

      // Remove skip from persisted
      this.$store.commit('ageVerification/SET_FIELD', {
        field: 'skipBrowsingAgeRestrictedProd',
        value: false,
      })
    },
    onCancelVerification(eaivSessionId) {
      this.$store
        .dispatch('ageVerification/verificationComplete', {
          sessionId: eaivSessionId,
          params: { isInfoMismatched: true },
        })
        .then((response) => {
          this.$store.commit('persisted/SET_EAIV_SESSION_ID', null)
          this.$store.commit('ageVerification/RESET_VERIFICATION_STATUS')
          this.changeDialogState({
            dialog: 'MismatchPersonalInfo',
            val: false,
            properties: {},
          })
        })
    },
    loadIdentityInstance() {
      const _this = this
      let maxIntervalLimit = 20
      const stopInterval = () => {
        if (_this.interval) {
          clearInterval(_this.interval)
          _this.interval = null
        }
        maxIntervalLimit = 0
      }

      if (_this.interval) {
        clearInterval(_this.interval)
        _this.interval = null
      }

      _this.interval = setInterval(() => {
        maxIntervalLimit -= 1
        if ('FTXIdentityLite' in window) {
          _this.setIdentityInstance(window.FTXIdentityLite.default)
          stopInterval()
        } else if (maxIntervalLimit <= 0) stopInterval()
      }, 1000)
    },
  },
}
