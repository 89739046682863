export async function getAllNotification({ commit }) {
  try {
    let { data } = await this.$axiosInstance.get('Notification', {
      params: {
        global: true,
      },
    })

    commit('SET_NOTIFICATION', data.data)
  } catch (error) {}
}

export async function removeNotification({ commit }, notificationID) {
  try {
    let { data } = await this.$axiosInstance.delete('/Notification/', {
      data: notificationID,
    })

    if (data.success && data.data) commit('REMOVE_NOTIFICATION', notificationID)
  } catch (error) {}
}

export async function removeAllNotification({ commit, state }) {
  let notificationIDs = []
  if (state.notifications.length) {
    state.notifications.forEach((element) => {
      notificationIDs.push(element.notificationID)
    })
    try {
      commit('SET_LOADING', true)
      let { data } = await this.$axiosInstance.delete('/Notification/', {
        data: notificationIDs,
      })

      if (data.success && data.data) commit('REMOVE_ALL_NOTIFICATION')
    } catch (error) {
    } finally {
      commit('SET_LOADING', false)
    }
  }
}
