export function notificationcount(state) {
  return state.notificationcount
}
export function notifications(state) {
  return state.notifications
}

export function loading(state) {
  return state.loading
}
