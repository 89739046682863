export default function () {
  return {
    user: null,
    loading: false,
    loadingUser: false,
    currentUser: null,
    exists: false,
    passwordPolicy: null,
  }
}
