export function resetGuestUserInfo({ commit }) {
  try {
    commit('SET_GUEST_USER', null)
    commit('SET_GUEST_CUSTOMER_ID', null)
    commit('SET_GUEST_CART_NUMBER', null)
  } catch (error) {
    return error
  }
}

export function setGuestUser(
  { commit },
  { guestUser = null, setCustomerID = false }
) {
  try {
    commit('SET_GUEST_USER', guestUser)
    if (setCustomerID && guestUser.customerID)
      commit('SET_GUEST_CUSTOMER_ID', guestUser.customerID)
  } catch (error) {
    return error
  }
}
