<template>
  <div :class="['template-collections q-mb-md']" ref="collectionDiv">
    <template
      v-if="
        component.collection?.seoName &&
        (products === false || products?.length > 0)
      "
    >
      <AgeRestrictedBlur :isPreview="isPreview" :component="component" />

      <div
        :class="[
          'row items-start q-product-slider',
          productsInRow == 0 ? 'no-products' : '',
        ]"
      >
        <!--<div class="col-12">
        <h2 class="text-h5 q-mt-auto">
          {{ component.collectionTitle || component.collection?.name || '' }}
          <router-link
            class="link-primaryOnBody text-underline"
            :to="{
              name: 'Collection',
              params: { seoName: component.collection.seoName },
            }"
            v-if="products?.length > productsInRow"
          >
            See all
          </router-link>
        </h2>
      </div>-->
        <!-- v-if="productsInRow > 0" -->
        <Swiper
          :products="products"
          :productsInRow="productsInRow"
          :title="component.collectionTitle || component.collection?.name || ''"
          :seoName="component.collection.seoName"
        />

        <div v-if="!products?.length" class="col-12">
          <div class="row q-col-gutter-md q-mb-sm">
            <div class="col" v-for="n in productsInRow" :key="n">
              <q-card flat bordered class="q-width q-pa-sm">
                <q-skeleton width="100%" height="180px" class="q-mb-md" />
                <q-card-section
                  class="row no-wrap q-pt-sm q-pl-none q-pr-none q-pb-none"
                >
                  <div class="col">
                    <q-skeleton width="60%" height="15px" />
                  </div>
                  <div class="col-1">
                    <q-skeleton width="15px" height="15px" />
                  </div>
                </q-card-section>
                <q-card-section class="q-pt-sm q-pl-none q-pr-none q-pb-none">
                  <q-skeleton
                    type="text"
                    width="100%"
                    class="text-caption q-mt-sm"
                  />
                  <q-skeleton type="text" width="30%" class="text-caption" />
                </q-card-section>

                <q-card-section class="q-pt-none q-pl-none q-pr-none q-pb-none">
                  <q-skeleton width="40%" height="15px" class="q-mt-md" />
                </q-card-section>

                <q-card-actions class="q-pt-md q-pl-none q-pr-none q-pb-none">
                  <q-skeleton width="100%" type="QBtn" />
                </q-card-actions>
              </q-card>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Swiper from 'components/Swiper'
import { swiperBreakpoints } from 'src/constants'

export default {
  name: 'Collection',
  components: {
    Swiper,
  },
  props: {
    component: {
      type: Object,
      default: new Object(),
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      parentWidth: 0,
    }
  },
  computed: {
    ...mapGetters('homepage', ['getProductsByCollectionId']),
    products() {
      if (this.component?.collection?.id)
        return this.getProductsByCollectionId(this.component.collection.id)
      return false
    },
    productsInRow() {
      if (this.isMobile && this.parentWidth >= 290) return 2

      let currentWidth = 0
      if (this.parentWidth) {
        for (let screenWidth in swiperBreakpoints) {
          if (screenWidth > this.parentWidth) {
            break
          }
          currentWidth = screenWidth
        }
      }

      return currentWidth ? swiperBreakpoints[currentWidth].elements : 0
    },
    // canShowCollection() {
    //   return (
    //     !this.component.isAgeRestricted ||
    //     (this.component.isAgeRestricted &&
    //       (!this.storeAgeVerificationRequired ||
    //         (this.storeAgeVerificationRequired && this.isUserAgeVerified)))
    //   )
    // },
  },
  methods: {
    onParentResize() {
      this.parentWidth = this.$refs.collectionDiv?.clientWidth
        ? this.$refs.collectionDiv.clientWidth + 10
        : 0
    },
  },
  mounted() {
    if (this.component?.collection)
      this.$store
        .dispatch('homepage/getProductsByCollection', this.component.collection)
        .then((data) => {
          if (!data?.items || data?.items?.length == 0) {
            this.$store.commit('homepage/UPDATE_HIDE_COMPONENT', {
              compId: this.component.id,
              hide: true,
            })
          }
        })

    this.parentWidth = this.$refs.collectionDiv?.clientWidth
      ? this.$refs.collectionDiv.clientWidth + 10
      : 0

    if (this.$refs.collectionDiv)
      new ResizeObserver(this.onParentResize).observe(this.$refs.collectionDiv)
  },
}
</script>
