import Vue from 'vue'
import { defaultState } from './state'

export function SET_FAVORITES(state, favorites) {
  state.favorites = Object.assign({}, favorites)
}

export function SET_TOTAL(state, intTotal) {
  state.totalCount = intTotal
}

export function ADD_FAVORITES(state, favorites) {
  state.favorites = Object.assign({}, state.favorites, favorites)
}

export function SET_CATEGORIES(state, categories) {
  categories.map((category) => {
    state.categories.push(category)
  })
}

export function MARK_AS_FAVORITES(state, favorites) {
  state.markedAsFavorites = Object.assign({}, state.markedAsFavorites, {
    ...favorites,
  })
}

export function REMOVE_ITEM_FROM_FAVORITES(state, productId) {
  Vue.delete(state.favorites, productId)
}

export function RESET_STATE(state) {
  Object.assign(state, defaultState())
}

export function SET_LOADING(state, loading) {
  state.loading = loading
}
export function SET_LOADING_CATEGORY(state, loading) {
  state.loadingCategory = loading
}
