import { cloneAsObject } from 'src/utils'
import { Platform } from 'quasar'

export function samplePosition({ commit }) {
  return new Promise((resolve, reject) => {
    const geolocation = navigator && navigator.geolocation
    if (!geolocation) {
      reject('Geolocation object not available')
    }
    geolocation.getCurrentPosition(
      (position) => {
        const positionObj = cloneAsObject(position)
        resolve(positionObj)
      },
      (error) => {
        if (Platform?.is?.mobile) {
          commit('SET_PERMISSION', 'denied')
          // https://stackoverflow.com/questions/52784495/is-there-any-alternative-to-navigator-permissions-query-permissions-api
          // switch (positionError.code) {
          //   // PERMISSION_DENIED
          //   case 1:
          //     console.log('Permission denied')
          //     break
          //   // POSITION_UNAVAILABLE
          //   case 2:
          //     console.log('Permission allowed, location disabled')
          //     break
          //   // TIMEOUT
          //   case 3:
          //     console.log('Permission allowed, timeout reached')
          //     break
          //   }
        }
        reject(error)
      }
    )
  })
}

export function queryPermission({ commit }) {
  return new Promise((resolve, reject) => {
    if (navigator && navigator.permissions)
      navigator.permissions
        .query({ name: 'geolocation' })
        .then((result) => {
          // one of (granted, prompt, denied)
          commit('SET_PERMISSION', result.state)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    else resolve()
  })
}
