import { VueMaskDirective } from 'v-mask'

import BoxImage from 'components/common/BoxImage'
import SvgIcon from 'components/SvgIcon'
// import SwipeToCloseDialog from 'components/common/SwipeToCloseDialog'
import Alert from 'components/common/Alert'
import InnerLoadingSpinner from 'components/InnerLoadingSpinner'
import LoadMoreSpinner from 'components/LoadMoreSpinner'
import EmptyComponent from 'components/EmptyComponent'
import FtxDialog from 'components/common/dialog/ftx-dialog'
import ProfileLink from 'components/common/profile-link'
import UserAvatar from 'components/UserAvatar'
import QCurrencyInput from 'components/common/QCurrencyInput'
// import PasswordInput from 'components/common/PasswordInput'
import RouterLinkNull from 'components/common/router-link-null'

export default async ({ Vue }) => {
  Vue.component('BoxImage', BoxImage)
  Vue.component('SvgIcon', SvgIcon)
  // Vue.component('SwipeToCloseDialog', SwipeToCloseDialog)
  Vue.component('Alert', Alert)
  Vue.component('InnerLoadingSpinner', InnerLoadingSpinner)
  Vue.component('LoadMoreSpinner', LoadMoreSpinner)
  Vue.component('EmptyComponent', EmptyComponent)
  Vue.component('FtxDialog', FtxDialog)
  Vue.component('ProfileLink', ProfileLink)
  Vue.component('UserAvatar', UserAvatar)
  Vue.directive('mask', VueMaskDirective)
  Vue.component('QCurrencyInput', QCurrencyInput)
  // Vue.component('PasswordInput', PasswordInput)
  Vue.component('RouterLinkNull', RouterLinkNull)
}
