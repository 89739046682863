export function SET_NOTIFICATION(state, notifications) {
  state.notifications = notifications
  state.notificationcount = notifications.length
}

export function REMOVE_NOTIFICATION(state, notificationID) {
  state.notifications = state.notifications.filter(
    (obj) => obj.notificationID !== notificationID[0]
  )
  state.notificationcount = state.notifications.length
}

export function REMOVE_ALL_NOTIFICATION(state) {
  state.notifications = {}
  state.notificationcount = 0
}
export function SET_LOADING(state, loading) {
  state.loading = loading
}
