let tempStorage = {}

export default class TempCacheStorage {
  getItem(key) {
    const now = new Date()
    const item = tempStorage[key]

    if (!item) return null

    if (now.getTime() > item.expiry) {
      delete tempStorage[key]
      return null
    }

    return item.value
  }

  setItem(key, value, ttl = 0) {
    if (!key || !value) return false

    let now = new Date()

    tempStorage = {
      ...tempStorage,
      [key]: {
        value,
        expiry: now.getTime() + ttl * 60000,
      },
    }

    return true
  }

  removeItem(key) {
    delete tempStorage[key]
  }

  clear() {
    tempStorage = {}
  }
}
